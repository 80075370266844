import { BaseSyntheticEvent, useEffect, useState } from "react";
import { useOutletContext, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { archiveProject, deleteProject } from "../../../core/services/gutcheck-api.service";
import { DeleteProjectButton, ProjectSidebar, ProjectsContainer, RevisionContainer, OverviewContainer, SuggestionsContainer, InsightsContainer, AiRevisionContainer } from "../components/project_overview_components";
import { ProjectHeader } from "../components/base_project_component";
import { FormControl, FormControlLabel, Switch } from "@mui/material";
import { DocumentTitle } from "../../../components/DocumentTitle";
import useAnalytics from "../../../hooks/useAnalytics";


type TProjectOverview = {
    highlightedPhrases: string[],
    highlightedTitle: string,
    highlightedIndex: string,
    tab: string,
    revisionTab: string
};

const initState: TProjectOverview = {
  highlightedPhrases: [],
  highlightedTitle: "",
  highlightedIndex: "",
  tab: "overview",
  revisionTab: "Predicted Performance"
};

type TProjectRespondant = {
  id: string,
  isOpen: boolean
};

const initRespondentState: TProjectRespondant = {
  id: "",
  isOpen: false
};

const allowedGuestTabs = ["viewpoints", "analysis"];

export const ProjectOverview = () => {

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { trackPage } = useAnalytics();

  const {project, organization, aiResults, viewerRole}  = useOutletContext<{project: any, organization: any, viewerRole: any, aiResults: any}>();
  const [currentRole, setCurrentRole] = useState(viewerRole);

  const defaultTab = currentRole === "guest" ? "viewpoints" : "overview";

  const [state, setState] = useState(initState);

  const [respondent, setRespondent] = useState(initRespondentState);

  const [summaryPagination, setSummaryPagination] = useState({
    numPages: null,
    currentPage: 1
  });

  const [assetPagination, setAssetPagination] = useState({
    numPages: null,
    currentPage: 1
  });


  useEffect(() => {
    const tab = searchParams.get('tab') && (searchParams.get('tab') !== "") ? searchParams.get('tab') : defaultTab;
    trackPage(`deal page - ${tab}`, { organization: organization.tag, project: project.tag, role: currentRole })
  }, []);

  useEffect(() => {
    let tab = searchParams.get('tab') && (searchParams.get('tab') !== "") ? searchParams.get('tab') : defaultTab;
    if (tab && currentRole === "guest") {
      tab = allowedGuestTabs.includes(tab) ? tab : "viewpoints";
    }
    setState({
      ...state,
      tab: tab ?? defaultTab});
  }, [searchParams, currentRole]);


  const participant = searchParams.get('pid') ?? "";
  if (participant !== respondent.id) {
    setRespondent({
      id: participant,
      isOpen: participant !== "" ? true : false
    });
    if (participant) {
      trackPage(`deal page - viewpoint`, { organization: organization.tag, project: project.tag, role: currentRole, viewpoint: participant });
    }
  }

  const handleCreateIteration = () => { 
    const newIterationURL = `/project/new/${organization.tag}/${project.tag}`;
    return navigate(newIterationURL, { state: { organization: organization, project: project } }); 
  };

  const handleDelete = async (event: any) => {
      event.preventDefault();
      const accessToken = await getAccessTokenSilently();

      const { error } = await deleteProject(project.id, accessToken);

      if (error) throw error;

      return navigate(`/o/${organization.tag}/`);
  };

  const handleArchive = async (event: any) => {
      event.preventDefault();
      const accessToken = await getAccessTokenSilently();

      const { error } = await archiveProject(project.id, accessToken);
      if (error) throw error;

      return navigate(`/o/${organization.tag}/`);
  };
  
  const handleRevisionMouse = (event: any, action: any) => {
    if (action === "over") {
      const revisionTitle = event.currentTarget.getElementsByClassName("revision-title")[0].innerText;
      const revisionIndex = event.currentTarget.id;
      const revision = /(```json|```)/.test(aiResults?.revisions[aiResults.revisions.length - 1]) ? 
        JSON.parse(aiResults.revisions[aiResults.revisions.length - 1]?.replace(/(```json|```)/g, "")).revisions.find( (revision: any) => revision.title === revisionTitle)
        : JSON.parse(project.iterations.at(-1).revision_result_json).revisions.find( (revision: any) => revision.title === revisionTitle)


      setState({
        ...state,
        highlightedPhrases: revision.phrases,
        highlightedTitle: revisionTitle,
        highlightedIndex: revisionIndex
      });
    } else if (state.highlightedPhrases.length !== 0) {
      setState({
        ...state,
        highlightedPhrases: [],
        highlightedTitle: "",
        highlightedIndex: ""
      });
    };
  };  

  const handleSwitchTab = (event: any) => {
    if (!event.currentTarget.getAttribute("data-name")) return;
    const tab = event.currentTarget.getAttribute("data-name").toLowerCase() === "overview" ? "" : event.currentTarget.getAttribute("data-name").toLowerCase();
    setSearchParams({
      ...searchParams,
      tab: tab
    });

    trackPage(`deal page - ${tab}`, { organization: organization.tag, project: project.tag, role: currentRole} );
  };

  const handleSwitchRevisionTab = (event: any) => {
    setState({
      ...state,
      revisionTab: event.target.textContent
    });
  };

  const handleRespondant = (event: BaseSyntheticEvent) => {
    if (!event.target.id) {
      setSearchParams({
        ...searchParams,
        tab: "viewpoints",
        pid: ""
      });
    } else {
      setSearchParams({
        ...searchParams,
        tab: "viewpoints",
        pid: event.target.id
      });
    }
  };

  const handleSummaryLoad = ( {numPages}: any ) => {
    setSummaryPagination({
      ...summaryPagination,
      numPages: numPages,
      currentPage: 1
    });
  };
  
  const handleSummaryChange = (event: any, offset: any) => {
    setSummaryPagination({
      ...summaryPagination,
      currentPage: summaryPagination.currentPage + offset
    });
  };

  const handleAssetLoad = ( {numPages}: any ) => {
    setAssetPagination({
      ...assetPagination,
      numPages: numPages,
      currentPage: 1
    });
  };
  
  const handleAssetChange = (event: any, offset: any) => {
    setAssetPagination({
      ...assetPagination,
      currentPage: assetPagination.currentPage + offset
    });
  };

  const handleRoleSwitch = () => {
    if (currentRole === "guest") {
      setCurrentRole("contributor");
    } else {
      setCurrentRole("guest");
    }
    searchParams.delete("tab");
    setSearchParams(searchParams);
  }

  const handleAdminRoleSwitch = (event: any) => {
    setCurrentRole(event.target.value);
  }

  return (
    <>
      <DocumentTitle pageTitle={project.name} />
      <aside className="page-sidebar">
      <div className="sidebar-tab-container">
        <ProjectSidebar project={project} viewerRole={currentRole} tab={state.tab} respondent={respondent} handleRespondant={handleRespondant} handleSwitchTab={handleSwitchTab} summaryPagination={summaryPagination} handleSummaryLoad={handleSummaryLoad} handleSummaryChange={handleSummaryChange} aiResults={aiResults} />
      </div>
      { viewerRole !== "guest" &&
        <FormControl className="guest-toggle">
          <FormControlLabel className="guest-toggle-label" control={<Switch checked={currentRole === "guest"} onChange={handleRoleSwitch} />} label="View as Guest" />
        </FormControl>
      }
      { false && viewerRole === "admin" &&
        <FormControl className="role-toggle">
          <select name="admin-role-select" value={currentRole} onChange={handleAdminRoleSwitch}>
            <option value="admin">Admin</option>
            <option value="contributor">Team</option>
            <option value="guest">Guest</option>
            </select>
        </FormControl>}
      </aside>
      <main className="page-main sidebar">
        <div className={`gc_view tab-${state.tab}`}>
          <ProjectHeader project={project} currentRole={currentRole} viewerRole={viewerRole} />
          <div className="project-content">
            { state.tab === "revision" && aiResults?.revisions && /(```json|```)/.test(aiResults.revisions[aiResults.revisions?.length - 1]) ?
                <AiRevisionContainer project={project} aiResults={aiResults} tab={state.revisionTab} highlights={state.highlightedPhrases} highlightedTitle={state.highlightedTitle} highlightedIndex={state.highlightedIndex} handleRevisionMouse={handleRevisionMouse} handleCreateIteration={handleCreateIteration} handleSwitchTab={handleSwitchRevisionTab} assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange}/>
                : state.tab === "revision" ?
                <RevisionContainer project={project} tab={state.revisionTab} highlights={state.highlightedPhrases} highlightedTitle={state.highlightedTitle} highlightedIndex={state.highlightedIndex} handleRevisionMouse={handleRevisionMouse} handleCreateIteration={handleCreateIteration} handleSwitchTab={handleSwitchRevisionTab} />
                : state.tab === "insights" ?
                <InsightsContainer project={project} assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange} aiResults={aiResults}/>
                : state.tab === "suggestions" ?
                <SuggestionsContainer project={project} assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange} aiResults={aiResults}/>
                :
                <OverviewContainer project={project} viewerRole={currentRole} tab={state.tab} respondent={respondent} handleRespondant={handleRespondant} handleSwitchTab={handleSwitchTab} summaryPagination={summaryPagination} handleSummaryLoad={handleSummaryLoad} handleSummaryChange={handleSummaryChange} assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange}/>
            }
            {/*<DeleteProjectButton status={project.status} handleArchive={handleArchive} handleDelete={handleDelete} />*/}
          </div>
        </div>
      </main>
    </>
  );
};
