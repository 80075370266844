import { Route, Routes } from "react-router-dom";
import { CreateProject } from "./CreateProject";
import { Project } from "./Project";
import { ProjectOverview } from "./ProjectOverview";
import { EditProject } from "./EditProject";
import { CreateProjectIteration } from "./CreateProjectIteration";

export const ProjectsRoutes = () => {
    return (
        <Routes>
            <Route path=":projectTag" element={<Project />}>
                <Route index element={<ProjectOverview />} />
                <Route path="edit" element={<EditProject />} />
                <Route path="new" element={<CreateProjectIteration />} />
            </Route>
            <Route path="new" element={<CreateProject />} />
        </Routes>
    );
}