import { useEffect } from "react";

export const DocumentTitle = ({pageTitle}: any) => {

    useEffect(() => {
        document.title = `${pageTitle} - BrightLoop`;
    }, [pageTitle]);

    return (
        <></>
    );
}