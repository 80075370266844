import { styled, Tooltip } from "@mui/material";

const ProfileImageBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#f1f1f1',
    color: '#333',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    overflow: 'hidden',
    cursor: 'default',
    "&.small": {
        width: '30px',
        height: '30px',
        fontSize: '1rem',
    },
    "&.large": {
        width: '100px',
        height: '100px'
    },
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    }
});


export const UserProfileImage = ({ user, size="medium" }: any) => {


    if (!user || !user.name || !(user.email_address || user.emailAddress)) {
        return (
            <ProfileImageBox className={size}>
                <span></span>
            </ProfileImageBox>
        );
    }

    const imageUrl = user.image_url ?? user.imageUrl ?? null;
    const emailAddress = user.email_address ?? user.emailAddress ?? null;

    if (imageUrl === null || /cdn\.auth0\.com/.test(imageUrl)) {

        const initials = user.name ? user.name.split(" ").map((n: string) => n[0]).join("") : emailAddress[0] + emailAddress[1];

        const placeholderImage = `https://cdn.auth0.com/avatars/${initials.toLowerCase()}.png`;
        return (
            <Tooltip title={user.name ?? emailAddress} placement="top">
            <ProfileImageBox className={size}>
                <img src={placeholderImage} alt={user.name} />
            </ProfileImageBox>
            </Tooltip>
        );
    }

    return (
        <Tooltip title={user.name ?? emailAddress} placement="top">
        <ProfileImageBox className={size}>
            <img src={imageUrl} alt={user.name} />
        </ProfileImageBox>
        </Tooltip>
    );
}