import { useState, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { updateProject, uploadAsset } from "../../../core/services/gutcheck-api.service";
import ServiceProvider from "../../../modules/ServiceProvider";
import { CreateForm, CreateProjectHeader, CreateSideForm } from "../components/project_form_components";
import { CreateInvestorForm, CreateButtonForm, CreateInvestorProjectHero, CreateInvestorProjectHeader, CreateInvestorSideForm } from "../components/project_investor_form_components";
import ProjectFormData from "../types/ProjectFormData";
import useCreateProjectFormData from "../../../hooks/useCreateProjectFormData";
import { v4 } from "uuid";
import LoadingOverlay from 'react-loading-overlay-ts';
import useAnalytics from "../../../hooks/useAnalytics";

const fixForm = (form: ProjectFormData) => {
  if (!form.goals) return form;

  return {
    ...form,
    goals: form.goals.map(val => {
      return {
        value: val,
        label: val
      }
    })
  };
};

export const EditProject = () => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();

  const location = useLocation();

  const { project, organization, viewerRole } = useOutletContext<{project: any, organization: any, viewerRole: any, aiResults: any}>();

  const initQueuedFiles: File[] = [];

  const initInput = (project.iterations.at(-1)?.assetText !== "") ? "asset_text" : (project.iterations.at(-1)?.assetUrl !== "") ? "asset_url" : "asset_file_id";

  const [form, setForm] = useCreateProjectFormData(project.tag, true, project.iteration_number);
  const [queuedFiles, setQueuedFiles] = useState(initQueuedFiles);
  const [activeInput, setActiveInput] = useState(initInput);
  const [isUploading, setIsUploading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const handleChangeInput = (event: any, input: string) => {
    setActiveInput(input);
  }

  const handleSave = async (event: any) => {
    if (form.name === "") {
      setForm({
        ...form,
        error_message: "A project name is required."
      });
      return;
    }

    if (form.goals && form.goals.length > 3) {
      setForm({
        ...form,
        error_message: "Select up to three metrics."
      });
      return;
    }

    try {
      event.preventDefault();
      setIsUploading(true);
      const projectForm = form;

      projectForm.asset_text = activeInput === "asset_text" ? projectForm.asset_text : "";
      projectForm.asset_url = activeInput === "asset_url" ? projectForm.asset_url : "";
      projectForm.asset_file_id = activeInput === "asset_file_id" ? projectForm.asset_file_id : "";

      if (activeInput === "asset_file_id" && queuedFiles.length > 0) {
        const fileId = v4() + queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, "");
        projectForm.asset_file_id = fileId;
      } else {
        projectForm.asset_file_id = projectForm.asset_file_id.replace(/\?.*$/, "");
      }

      const accessToken = await getAccessTokenSilently();
      const { error } = await updateProject(project.id, fixForm(form), accessToken);

      if (activeInput === "asset_file_id" && queuedFiles.length > 0) {
        const { data, error } = await uploadAsset(queuedFiles[0], organization.tag, project?.tag, projectForm.asset_file_id, accessToken);
      }

      trackEvent("saved project", {organization: organization.tag, project: project.tag});

      if (error) throw error;

      return navigate(`/o/${organization.tag}/`);
    } catch (error) {
      setIsUploading(false);
      console.error(error);
    };
  };

  const handleStart = async (event: any) => {
    if (form.name === "" || form.type === "" || (form.asset_text === "" && form.asset_url === "" && form.asset_file_id === "" && queuedFiles.length == 0) || (form.goals && form.goals.length === 0) || form.target_audience === "") {
      setForm({
        ...form,
        error_message: "All fields are required to start your project."
      });
      return;
    }

    if (form.goals && form.goals.length > 3) {
      setForm({
        ...form,
        error_message: "Select up to three metrics."
      });
      return;
    }

    try {
      event.preventDefault();

      setIsUploading(true);
      const projectForm = form;

      projectForm.asset_text = activeInput === "asset_text" ? projectForm.asset_text : "";
      projectForm.asset_url = activeInput === "asset_url" ? projectForm.asset_url : "";
      projectForm.asset_file_id = activeInput === "asset_file_id" ? projectForm.asset_file_id : "";

      if (activeInput === "asset_file_id" && queuedFiles.length > 0) {
        const fileId = v4() + queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, "");
        projectForm.asset_file_id = fileId;
      } else {
        projectForm.asset_file_id = projectForm.asset_file_id.replace(/\?.*$/, "");
      }

      const accessToken = await getAccessTokenSilently();
      const { data, error } = await updateProject(project.id, fixForm(projectForm), accessToken);

      if (activeInput === "asset_file_id" && queuedFiles.length > 0) {
        const { data, error } = await uploadAsset(queuedFiles[0], organization.tag, project?.tag, projectForm.asset_file_id, accessToken);
      }

      if (error) throw error;

      await ServiceProvider
        .provideProjectService()
        .start(data.project.id, accessToken);

      trackEvent("started project", {organization: organization.tag, project: project.tag});

      /*
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await updateProject(project.id, fixForm(form), accessToken);

      if (error) throw error;

      await ServiceProvider
        .provideProjectService()
        .start(data.project.id, accessToken);
*/
      return navigate(`/o/${organization.tag}/project/${data.project.tag ? data.project.tag : "no-name"}`, { state: { project: data.project } });
    } catch (error) {
      console.error(error);
    };
  };

  return (
    <main className="page-main">
      <div className="gc_view">
        <LoadingOverlay
          active={isUploading}
          spinner
          text='Uploading...'
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.75)'
            }),
            spinner: (base) => ({
              ...base,
              width: '300px',
              '& svg circle': {
                stroke: '#feb70f'
              }
            }),
            content: (base) => ({
              ...base,
              color: '#141414',
              position: 'relative',
              top: '-300px'
            })
          }}
        >
          <div className="splash-page">
            <CreateProjectHeader form={form} setForm={setForm} />

            <div className={`splash-container ${form.name === "" && "hidden"}`}>
              <div className="splash-view investor-form">
                <CreateInvestorForm userType={project.userType} form={form} setForm={setForm} queuedFiles={queuedFiles} setQueuedFiles={setQueuedFiles} activeInput={activeInput} setActiveInput={setActiveInput} handleChangeInput={handleChangeInput} />
                <CreateButtonForm form={form} handleSave={handleSave} handleStart={handleStart} />
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    </main>
  )
};
