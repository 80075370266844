import NavBar from "./NavBar";
import Footer from "./Footer";
import { ThemeProvider } from "@mui/material";
import { theme } from "../theme/theme";

type PageProps = {
  children: any
}

export const BasicLayout = ({ children }: PageProps) => {
  return (
    <ThemeProvider theme={theme}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar hideUser={true}/>
        <div className="page-wrapper">
            {children}
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
};
