import { Fragment } from "react";
import { Document, Page } from "react-pdf";
import { Link, NavLink } from "react-router-dom";
import Select from 'react-select';
import { v4 } from "uuid";
import TextViewer from "../../../../components/TextViewer";
import TextEditor from "../../../../components/TextEditor";

const status = [
    { value: "draft", label: "Setup & Launch" },
    { value: "evaluation", label: "Collecting Viewpoints" },
    { value: "analysis", label: "Analysis & Results" },
    { value: "firm_investment", label: "Firm Investment" },
    { value: "spv", label: "BrightLoop Expert SPV" },
    { value: "completed", label: "Completed" }
];

const goalTypes = [
    { value: "Demand", label: "Maximize Demand" },
    { value: "Differentiation", label: "Improve Differentiation" },
    { value: "Perceived Value", label: "Increase Perceived Value" },
    { value: "Comprehension", label: "Increase Comprehension" },
    { value: "Objections", label: "Minimize Objections" },
    { value: "Positive Sentiment", label: "Increase Positive Sentiment" },
    { value: "Negative Sentiment", label: "Decrease Negative Sentiment" },
    { value: "Potential Virality", label: "Increase Potential Virality" },
    { value: "Action", label: "Improve Call to Action"}
];

const userTypes = [
    { value: "marketer", label: "Marketer"},
    { value: "investor", label: "Investor"},
    { value: "entrepreneur", label: "Entrepreneur"}
];

type TProject = {
    project: any
};

type TForm = {
    form: any
};

type TState = {
    state: any
};

type TLabel = {
    labelText: string
};

type TSection = {
    section?: string
};

type TProjectForm = {
    projectForm: any
};

type TProjectHeader = TProjectForm & {
    handleStatusChange: any
};

type TFormatText = {
    text: string,
    highlights?: string[]
    highlightedIndex?: string
};

type TProjectDetails = TState & TProjectForm & {
    setProjectForm: any,
    handleChange: any, 
    handleGoalChange: any
};

type TProjectContainer = TProjectDetails & {
    signedAssetFileId: any,
    assetPagination: any,
    handleAssetLoad: any,
    handleAssetChange: any 
}

type TResultTabs = {
    tab?: string,
    handleSwitchTab: any
};

type TResultContent = TState & TForm & TProjectForm & {
    handleChange: any,
    handleFormChange: any
};

type TAdminResults = TResultContent & TResultTabs;

type TResults = TState & TProjectForm;

type TResultsResponses = TResults & {
    handleChange: any
};

type TResultsOverview = TResults & TForm & {
    handleFormChange: any
};

type TResultBoxes = TState & TProjectForm & TSection;

type TResultBox = TSection & {
    attr: any, 
    metrics: any, 
    modifier: string
}

type TRevisionDetails = TProjectForm &{
    handleRevisionMouse: any
};

type TRevisionPDF = TState & TProjectForm & {
    signedRevisionUrl: string,
    handleChange: any
};

type TRevisionResults = TForm & TProjectForm & TState & TRevisionPDF & {
    handleFormChange: any
};

type TRevisionContent = TRevisionDetails & TRevisionResults;

type TActionButtons = TState & {
    handleToggleIsEdit: any, 
    handleSubmit: any
};

type TConceptRevisionInput = TForm & {
    handleChange: any
};

type TConceptRevisionButton = {
    handleSubmit: any
};

type TConceptRevision = TConceptRevisionInput & TConceptRevisionButton;

const ProjectStatusBar = ({projectForm, handleStatusChange}: TProjectHeader) => {
    let statusClassName = "complete";
    const html = status.map((status) => {
        const isBold = status.value === projectForm.status;
        if (isBold) {
            statusClassName = "current";
        } else if (statusClassName === "current") {
            statusClassName = "future";
        }

        return (
            <li className={statusClassName} onClick={handleStatusChange} id={status.value}>{status.label}</li>
        );
    });
    return (
        <ul className="project-status-steps step-menu">
            {html}
        </ul>
    );
};

export const CreateProjectHeaderStatusBar = ({projectForm, handleStatusChange}: TProjectHeader) => (
    <div className="project-header overview-header">
        <ProjectStatusBar projectForm={projectForm} handleStatusChange={handleStatusChange} />
    </div>
);

const GetFormattedText = ({ text, highlights = [], highlightedIndex = '' }: TFormatText) => {
    // Messy test to see if the text is HTML or plain text
    if (/<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(text ?? "")) {
        return (
            <TextViewer content={text ?? ""} />
        );
    } else {
        const separators = highlights;
        separators.push("\\n");
        const regex = "(" + separators.join("|") + ")";
        const parts = text?.split(new RegExp(`${regex}`, 'g'));
        return <>{parts?.map(part => highlights.includes(part) ? <span key={v4()} className={"highlight " + highlightedIndex}>{part}</span> : part === "\n" ? <br /> : <span key={v4()}>{part}</span>)}</>;
    }
};

const DetailsContainer = ({state, projectForm, setProjectForm, handleChange, handleGoalChange}: TProjectDetails) => {
    const getField = (field: string) => {
        switch (field) {
            case "completionDate":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            id="completion_date"
                            type="date"
                            value={projectForm.completion_date ? (new Date(projectForm.completion_date)).toISOString().split('T')[0] : ""}
                            onChange={handleChange}
                        />
                    </div>
                );
            case "projectType":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            id="type"
                            type="text"
                            value={projectForm.type}
                            onChange={handleChange}
                        />
                    </div>
                );
            case "graphUrl":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            id="graph_url"
                            type="text"
                            value={projectForm.graph_url}
                            onChange={handleChange}
                        />
                    </div>
                );
            case "userType":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            id="user_type"
                            type="text"
                            value={projectForm.user_type}
                            onChange={handleChange}
                        />
                    </div>
                )
            case "demandOverride":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            id="demand_override"
                            type="text"
                            value={projectForm.demand_override}
                            onChange={handleChange}
                        />
                    </div>
                )
            case "distinction":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            id="distinction"
                            type="text"
                            value={projectForm.distinction}
                            onChange={handleChange}
                        />
                    </div>
                )
            case "marketSize":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            id="market_size"
                            type="text"
                            value={projectForm.market_size}
                            onChange={handleChange}
                        />
                    </div>
                )
            case "publicTitle":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            id="public_title"
                            type="text"
                            value={projectForm.public_title}
                            onChange={handleChange}
                        />
                    </div>
                )
            case "goalValues":
                const goalValue = projectForm.goals.map((e: any) => {
                    return { value: e.value, label: goalTypes.find(g => g.value === e.value)?.label }
                });
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <Select
                            isMulti
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="goals"
                            inputId="goals"
                            options={goalTypes}
                            value={goalValue}
                            onChange={handleGoalChange}
                        />
                    </div>
                );
            case "target_audience":
                return (
                    <div className="edit-page-form-field textfield-wrapper admin-edit-mode">
                        <TextEditor content={projectForm.target_audience} id="target_audience" form={projectForm} setForm={setProjectForm} />
                    </div>
                );
            case "key_questions":
                return (
                    <div className="edit-page-form-field textfield-wrapper admin-edit-mode">
                        <TextEditor content={projectForm.key_questions} id="key_questions" form={projectForm} setForm={setProjectForm} />
                    </div>
                );
            case "is_closed":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            id="is_closed"
                            type="checkbox"
                            checked={projectForm.is_closed}
                            onChange={handleChange}
                        />
                        </div>
                );
            case "auto_publish":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            id="auto_publish"
                            type="checkbox"
                            checked={projectForm.auto_publish}
                            onChange={handleChange}
                        />
                        </div>
                );
            case "distinctionOptions":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <textarea
                            id="distinction_options"
                            value={projectForm.distinction_options}
                            onChange={handleChange}
                        />
                    </div>
                )
            case "distinctionQuestion":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            type="text"
                            id="distinction_question"
                            value={projectForm.distinction_question}
                            onChange={handleChange}
                        />
                    </div>
                )
            default:
                break;
        };
    };

    return (
        <div className="project-field-side-container">
            <div className="details-container">
            <div className="detail-box">
                <h5>Active/Closed?</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.is_closed ? "Closed" : "Active"}</p> : getField("is_closed")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Auto Publish Viewpoints?</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.auto_publish ? "Yes" : "No"}</p> : getField("auto_publish")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Completion Date</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.completion_date}</p> : getField("completionDate")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Graph URL</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.graph_url}</p> : getField("graphUrl")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Distinction Question</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.distinction_question}</p> : getField("distinctionQuestion")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Top Distinction Options</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.distinction_options}</p> : getField("distinctionOptions")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Project Type</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.type}</p> : getField("projectType")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Public Description</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.public_title}</p> : getField("publicTitle")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Project Type</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.type}</p> : getField("projectType")}
                </div>
            </div>
            <div className="detail-box">
                <h5>User Type</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.user_type}</p> : getField("userType")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Goals</h5>
                <div className="detail-box-info">
                <p>
                    { !state.isEdit ? projectForm.goals.map((g: any) => {
                        return ( <Fragment key={v4()}>{g.value}<br/></Fragment>)
                    }) : getField("goalValues")}
                </p>
                </div>
            </div>
            <div className="detail-box">
                <h5>Demand</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.demand_override}</p> : getField("demandOverride")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Distinction</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.distinction}</p> : getField("distinction")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Market Size</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <p>{projectForm.market_size}</p> : getField("marketSize")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Target Audience</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <GetFormattedText text={projectForm.target_audience}/> : getField("target_audience")} 
                </div>
            </div>
            <div className="detail-box">
                <h5>Key Questions</h5>
                <div className="detail-box-info">
                    { !state.isEdit ? <GetFormattedText text={projectForm.key_questions}/> : getField("key_questions")} 
                </div>
            </div>
            </div>
        </div>
    );
}

export const ProjectsContainer = ({state, projectForm, setProjectForm, signedAssetFileId, handleChange, handleGoalChange, assetPagination, handleAssetLoad, handleAssetChange }: TProjectContainer) => {
    const fileId = projectForm.asset_file_id ?? "";
    const assetUrl = projectForm.asset_url ?? "";
    const isPdf = (/\.pdf/.test(fileId));
    const allBlank = (projectForm.asset_text === "") && (projectForm.asset_file_id === "") && (projectForm.asset_url === "");

    return (
        <div className="projects-container">
            <div className="project-container">
                <h3 className="bold">Original Content</h3>
                <div className="project-asset-container">
                    {!state.isEdit ?
                        <>
                            {(projectForm.asset_text !== "") &&
                                <GetFormattedText text={projectForm.asset_text} />
                            }
                            {(fileId !== "") && (isPdf) &&
                                <>
                                    <Document file={signedAssetFileId} onLoadSuccess={handleAssetLoad}>
                                        <Page pageNumber={assetPagination.currentPage} renderTextLayer={false} renderAnnotationLayer={false} scale={1.6} />
                                    </Document>
                                    <div className="analysis-pagination">
                                        <button
                                            type="button"
                                            disabled={assetPagination.currentPage <= 1}
                                            onClick={e => handleAssetChange(e, -1)}
                                        >
                                            Previous
                                        </button>
                                        <span>
                                            {assetPagination.currentPage || (assetPagination.numPages ? 1 : '--')} of {assetPagination.numPages || '--'}
                                        </span>

                                        <button
                                            type="button"
                                            disabled={assetPagination.currentPage >= assetPagination.numPages}
                                            onClick={e => handleAssetChange(e, 1)}
                                        >
                                            Next
                                        </button>
                                    </div>

                                </>
                            }
                            {(fileId !== "") && !(isPdf) &&
                                <video className="asset-url-container" src={signedAssetFileId} controls></video>
                            }
                            {(assetUrl !== "") &&
                                <iframe className="asset-url-container"
                                    src={projectForm.asset_url}>
                                </iframe>
                            }
                        </>
                        :
                        <div className="admin-edit-mode">
                            <label htmlFor="asset_text">Text asset:</label>
                            <TextEditor content={projectForm.asset_text} id="asset_text" form={projectForm} setForm={setProjectForm} />
                            <>
                                <label htmlFor="asset_file_id">Link to S3 asset:</label>
                                <input type="text" id="asset_file_id" name="asset_file_id" value={fileId} onChange={handleChange}/>
                            </>
                            <>
                                <label htmlFor="asset_url">Public URL for embed:</label>
                                <input type="text" id="asset_url" name="asset_url" value={assetUrl} onChange={handleChange}/>
                            </>
                        </div>
                    }
                </div>

            </div>
            <DetailsContainer state={state} projectForm={projectForm} setProjectForm={setProjectForm} handleChange={handleChange} handleGoalChange={handleGoalChange} />
        </div>
    );
};

const ResultsBoxLabel = ({labelText}: TLabel) => {
    if (labelText !== "") {
        return (
            <p className="results-box-label">{labelText}</p>
        );
    }
};

const ResultBox = ({attr, metrics, modifier, section}: TResultBox) => {
    let scoreText = "--.-%";
    let subText = "";
  
    if ( metrics && Object.keys(metrics).length > 0) {
      attr.symbol === "$"
        ? scoreText = `$${metrics?.goals && metrics?.goals[attr.value] ? metrics?.goals[attr.value]?.score : "--.-"}` 
        : scoreText = `${metrics?.goals && metrics?.goals[attr.value] ? metrics?.goals[attr.value]?.score : "--.-"}%`;
  
      subText = metrics.goals[attr.value]?.subtext ? metrics?.goals[attr.value]?.subtext : ''
    };
  return (
    <div key={v4()} className="results-box flex-column">
      <p className="result-title bold">{modifier} {attr.title}</p>
      <p className="result-score bold">{scoreText}</p>
      <p className="result-subtext bold">{subText}</p>
      <ResultsBoxLabel labelText={section === "revision" ? "AI Prediction" : ""}/>
    </div>
  );
};

const CreateResultBoxes = ({state, projectForm, section}: TResultBoxes) => {
    if (!state.isEdit) {
        try { 
            const goalMetrics = (section === "revision") ? projectForm.revision_result_json : projectForm.result_json;
            const goalModifier = (section === "revision") ? "Predicted" : "";
            return projectForm.goals.map((attr: any) => {
                if (attr.value === "Demand") {
                    return (
                    <>
                        <div key={v4()} className="results-box flex-column">
                            <p className="result-title bold">{goalModifier} Demand - Definitely</p>
                            <p className="result-score bold">{goalMetrics && goalMetrics.goals && goalMetrics.goals.Demand ? goalMetrics.goals.Demand.score : "--.-"}%</p>
                            <p className="result-subtext bold">{goalMetrics.goals[attr.value].subtext ? goalMetrics.goals[attr.value].subtext : 'Extremely likely to purchase'}</p>
                        </div>
                        <div key={v4()} className="results-box flex-column">
                            <p className="result-title bold">{goalModifier} Demand - Definitely + Probably</p>
                            <p className="result-score bold">{goalMetrics && goalMetrics.goals && goalMetrics.goals.DemandProbably ? goalMetrics.goals.DemandProbably.score : "--.-"}%</p>
                            <p className="result-subtext bold">{goalMetrics.goals[attr.value].subtext ? goalMetrics.goals[attr.value].subtext : 'Extremely or very likely to purchase'}</p>
                        </div>
                    </>
                    );
                } else if (attr) {
                    return <ResultBox attr={attr} metrics={goalMetrics} modifier={goalModifier} section={section} />;
                };
            });
        } catch (e) { console.error(e) };
    }
};

const ResultsContent = ({state, projectForm, form, handleChange, handleFormChange}: TResultContent) => {
    switch (state.tab) {
        case "Overview":
            return <ResultsOverviewContent state={state} projectForm={projectForm} form={form} handleFormChange={handleFormChange}/>;
        case "Summary":
            return <ResultsSummaryContent state={state} projectForm={projectForm} handleChange={handleChange}/>;
        case "Analysis":
            return <ResultsAnalysisContent state={state} projectForm={projectForm} handleChange={handleChange}/>;
        case "Participants":
            return <ResultsParticipantsContent project={projectForm} />
        default:
            return <></>;
    };
};

const ResultsOverviewContent = ({state, projectForm, form, handleFormChange}: TResultsOverview) => {
    const getField = (
        <div className="edit-page-form-field textfield-wrapper">
            <label>Result JSON: </label>
            <textarea
                id="result_json"
                value={form.result_json}
                onChange={handleFormChange}
            />
        </div>
    );

    return (
        <>
            { state.isEdit ? getField : ""}
            <div className="flex-row">
                <CreateResultBoxes state={state} projectForm={projectForm} />
            </div>
            <ResultsContentHtml state={state} projectForm={projectForm} />
        </>
    );
};

const ResultsSummaryContent = ({state, projectForm, handleChange}: TResultsResponses) => {
    const getField = (
        <div className="edit-page-form-field textfield-wrapper">
            <input
                id="summary_url"
                type="text"
                value={projectForm.summary_url}
                onChange={handleChange}
            />
        </div>
    );

    return !state.isEdit ? (
        <iframe
            src={projectForm.summary_url}
            style={{
                width: "1px",
                minWidth: "100%",
                minHeight: "900px"
            }}
            frameBorder="0"
        >
        </iframe>
    ) : getField
};

const ResultsContentHtml = ({state, projectForm}: TResults) => {
    if (!state.isEdit && projectForm.result_json) {
        try {
            return (
                <div className="result-highlights-html flex-row">
                <div className="result-highlights-block flex-column">
                    <h4>Top Strengths</h4>
                    <ul>
                        {projectForm.result_json?.insights?.strengths?.map((strength: string) => {
                            return (<li key={v4()}>{strength}</li>)
                        })}
                    </ul>
                </div>
                <div className="result-highlights-block flex-column">
                    <h4>Top Concerns</h4>
                    <ul>
                        {projectForm.result_json?.insights?.concerns?.map((concern: string) => {
                            return (<li key={v4()}>{concern}</li>);
                        })}
                    </ul>
                </div>
                </div>
            );
        } catch (e) { console.error(e) }
    }
};

const ResultsAnalysisContent = ({state, projectForm, handleChange}: TResultsResponses) => {
    const getField = (
        <div className="edit-page-form-field textfield-wrapper">
            <input
                id="analysis_url"
                type="text"
                value={projectForm.analysis_url}
                onChange={handleChange}
            />
        </div>
    );

    return !state.isEdit ? (
        <iframe
            src={projectForm.analysis_url}
            style={{
                width: "1px",
                minWidth: "100%",
                minHeight: "900px"
            }}
            frameBorder="0"
        >
        </iframe>
    ) : getField
};

const ResultsParticipantsContent = ({project}: TProject) => (
  <>resultsParticipantsContent - {project.status}</>
);

const AdminResultsTabs = ({tab, handleSwitchTab}: TResultTabs) => {
  const states = ["Overview", "Summary", "Analysis", "Participants"];
  const html = states.map(state => {
    return <p key={v4()} className={tab === state ? "bold text-lg" : "text-lg"} onClick={handleSwitchTab}>{state}</p>
  });

  return (
    <div className="results-tab-container flex-row">
      {html}
    </div>
  )
};

export const AdminResultsContainer = ({state, projectForm, form, handleSwitchTab, handleChange, handleFormChange}: TAdminResults) => (
    <div className="project-field-container">
       <div className="title-container">
          <h3 className="bold">Results</h3>
          <div className="separator-inline"></div>
        </div>
        <AdminResultsTabs tab={state.tab} handleSwitchTab={handleSwitchTab}/>
        <div className="results-container flex-column">
            <ResultsContent state={state} projectForm={projectForm} form={form} handleChange={handleChange} handleFormChange={handleFormChange}/>
        </div>
    </div>
);

const RevisionDetailsContainer = ({projectForm, handleRevisionMouse}: TRevisionDetails) => {
    return (
        <div className="project-field-side-container">
            <div className="details-container">
            <h5>Key Additions:</h5>
            <p className="subtitle">(hover over to see changes)</p>
            <ul>
                { projectForm.revision_result_json?.revisions?.map((revision: any) => (
                    <li key={v4()} className="revision-detail" onMouseOver={(e) => handleRevisionMouse(e, "over")} onMouseOut={(e) => handleRevisionMouse(e, "out")}>{revision.title}</li>
                )) }
            </ul>
            </div>
        </div>
    );    
};

const RevisionResultsContent = ({state, projectForm, form, signedRevisionUrl, handleChange, handleFormChange}: TRevisionResults) => {
    const getField = (
        <div className="edit-page-form-field textfield-wrapper">
            <label>Revision JSON: </label>
            <textarea
                id="revision_result_json"
                value={form.revision_result_json}
                onChange={handleFormChange}
            />
        </div>
    );

    return (
        <>  
            { state.isEdit ? getField : '' }
            <div className="flex-row">
                <CreateResultBoxes state={state} projectForm={projectForm} section={"revision"}/>
            </div>
            <div className="result-highlights">
                <RevisionResultsContentHtml />
                <RevisionResultsContentPdf state={state} projectForm={projectForm} signedRevisionUrl={signedRevisionUrl} handleChange={handleChange} />
            </div>
        </>
    );
};

const RevisionResultsContentPdf = ({state, projectForm, signedRevisionUrl, handleChange}: TRevisionPDF) => {
    const getField = (
        <div className="edit-page-form-field textfield-wrapper">
            <label>Revision URL: </label>
            <input
                id="revision_url"
                type="text"
                value={projectForm.revision_url}
                onChange={handleChange}
            />
        </div>
    );

    return !state.isEdit ? ( signedRevisionUrl ? (
        <Document file={signedRevisionUrl} >
            <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} scale={1} />
        </Document>
    ) : '' ) : getField;
};

const RevisionResultsContentHtml = () => (<></>);

const RevisionResultsContainer = ({state, projectForm, form, signedRevisionUrl, handleChange, handleFormChange}: TRevisionResults) => (
    <div className="project-field-container">
        <h5 className="bold"> Simulated Results</h5>
<div className="separator"></div>
        <div className="results-container flex-column">
        <RevisionResultsContent state={state} projectForm={projectForm} form={form} signedRevisionUrl={signedRevisionUrl} handleChange={handleChange} handleFormChange={handleFormChange} />
        </div>
    </div>
);

const RevisionContent = ({state, projectForm, form, signedRevisionUrl, handleChange, handleFormChange, handleRevisionMouse}: TRevisionContent) => {
    const getField = (
        <div className="edit-page-form-field textfield-wrapper">
            <textarea
                id="revision_text"
                value={projectForm.revision_text}
                onChange={handleChange}
            />
        </div>
    );

    return (<>
        <div className="projects-container">
            <div className="project-container">
                <h5 className="bold">Revised Content</h5>
                <div className="project-asset-container">
                    { !state.isEdit ? <GetFormattedText text={projectForm.revision_text} highlights={state.highlightedPhrases} /> : getField }
                </div>
            </div>
            { !state.isEdit ? <RevisionDetailsContainer projectForm={projectForm} handleRevisionMouse={handleRevisionMouse} /> : '' }
        </div>
        <RevisionResultsContainer state={state} projectForm={projectForm} form={form} signedRevisionUrl={signedRevisionUrl} handleChange={handleChange} handleFormChange={handleFormChange} />
    </>);
};

export const RevisionContainer = ({state, projectForm, form, signedRevisionUrl, handleChange, handleFormChange, handleRevisionMouse}: TRevisionContent) => {
    return (
        <div className="project-field-container revision-container">
            <h3 className="bold">Suggested Revision</h3>
            <RevisionContent state={state} projectForm={projectForm} form={form} signedRevisionUrl={signedRevisionUrl} handleChange={handleChange} handleFormChange={handleFormChange} handleRevisionMouse={handleRevisionMouse} />
        </div>
    );
};


export const CreateAiChainLink = ({project}: TProject) => {
    return (
        <div className="aichain-link">
            <Link to={`/admin/project/${project.tag}/ai`} state={{project: project}}>AI Chain</Link>
        </div>
    )
}
export const CreateEditSaveButtons = ({state, handleToggleIsEdit, handleSubmit}: TActionButtons) => {
    return (
        <div className="edit-save-controls flex-row">
            <p className="link bold" onClick={handleToggleIsEdit} style={{marginRight: 10, fontSize: 20}}>{ !state.isEdit ? "Edit" : "Confirm" }</p>
            { state.canSave ? (<p className="link bold" onClick={handleSubmit} style={{fontSize: 20}}>Save</p>) : '' }
        </div>
    );
};

const AdminConceptRevisionInput = ({ form, handleChange }: TConceptRevisionInput) => {
    const getField = (field: string) => {
        switch (field) {
            case "feedback":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <textarea
                            id="feedback"
                            // type="text"
                            value={form.feedback}
                            onChange={handleChange}
                        />
                    </div>
                );
            case "guidance":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <textarea
                            id="guidance"
                            // type="text"
                            value={form.guidance}
                            onChange={handleChange}
                        />
                    </div>
                );
            case "temperature":
                return (
                    <div className="edit-page-form-field textfield-wrapper">
                        <input
                            id="temperature"
                            type="text"
                            value={form.temperature}
                            onChange={handleChange}
                        />
                    </div>
                );
            default:
                break;
        };
    };

    return (
        <div className="project-field-container">
            <div className="detail-box">
                <h5>Feedback</h5>
                <div className="detail-box-info">
                    {getField("feedback")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Guidance</h5>
                <div className="detail-box-info">
                    {getField("guidance")}
                </div>
            </div>
            <div className="detail-box">
                <h5>Temperature</h5>
                <div className="detail-box-info">
                    {getField("temperature")} 
                </div>
            </div>
        </div>
    );
};

const AdminConceptRevisionButton = ({ handleSubmit }: TConceptRevisionButton) => {
    return (
        <div className="edit-save-controls flex-row mt-3">
            <p className="link bold" onClick={handleSubmit} style={{fontSize: 20}}>Create Concept Revision</p>
        </div>
    );
};

const AdminConceptRevision = ({form}: TForm) => {
    return (
        <div className="project-field-container">
            <h4>GTP Response</h4>
            <GetFormattedText text={form.gtp_response} />
            <h4 className="mt-5">LLM Cohere Response</h4>
            <GetFormattedText text={form.llm_response} />
        </div>
    );
};

export const AdminConceptRevisionContainer = ({ form, handleChange, handleSubmit }: TConceptRevision) => {
    return (
        <div className="concept-revision-container mt-3 mb-5">
            <h3 className="bold mb-3">Concept Revision</h3>
            <AdminConceptRevisionInput form={form} handleChange={handleChange} />
            <AdminConceptRevisionButton handleSubmit={handleSubmit} />
            <AdminConceptRevision form={form} />
        </div>
    );
};