import { Box, BoxProps, styled } from "@mui/material"

export const ModalBox = styled(Box)<BoxProps>(({ theme }) => ({
    backgroundColor: "#f8f9fa",
    border: "1px solid #ffffff",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    left: "50%",
    maxWidth: "80%",
    padding: "30px",
    position: "absolute",
    top: "50px",
    transform: "translate(-50%, 0%)",
    width: "500px",
    "& h5": {
        borderBottom: "1px solid #c9c9c9",
        padding: "0 0 1rem",

    },

}));

