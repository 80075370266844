import ProjectHttpClient from "../infrastructure/clients/ProjectHttpClient";

class ProjectService {
    projectClient; 

    constructor(projectClient: ProjectHttpClient) {
        this.projectClient = projectClient;
    }

    async start(projectId: number, accessToken: any) {
        const survey = await this.projectClient.start(projectId, accessToken);
        console.log("SURVEY IS BACK");
        console.log(survey);
    }
}

export default ProjectService;