import { Box, styled, Table, TableHead, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DealAssetForm } from "./DealAssetForm";
import { useGetProjectAssets } from "../../../../hooks/useGetProjectAssets";
import ProjectAsset from "../../../../features/projects/types/ProjectAsset";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

const AssetButtons = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '1rem',
    marginBottom: '1rem',
    '& button': {
        backgroundColor: '#f8f9fa',
        border: '1px solid #c9c9c9',
        borderRadius: '10px',
        padding: '1rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e9ecef'
        }
    },
});

const AssetButton = styled(Box)({
    backgroundColor: '#ffffff',
    border: '1px solid #c9c9c9',
    borderRadius: '10px',
    padding: '1rem',
    cursor: 'pointer',
    "&.disabled": {
        backgroundColor: '#f8f9fa',
        cursor: 'not-allowed',
    },
    '&.enabled:hover': {
        backgroundColor: '#e9ecef'
    },
    "& p.title": {
        fontWeight: 600,
        margin: 0,
    },
});

const AssetTable = styled(Table)({
    width: "100%",
    marginBottom: "20px",
    "& td:last-child, th:last-child": {
        textAlign: "center"
    },

    "& th": {
        fontWeight: 700
    },
    "& tbody": {
        backgroundColor: "#ffffff",
        "& tr": {

            borderLeft: "1px solid #c9c9c9",
            borderRight: "1px solid #c9c9c9",
        }
    },
});

type TProjectAssetForm = {
    projectAsset: ProjectAsset,
    assetType: string,
    editMode: boolean
}

const fileTypeIcons: Record<string, FontAwesomeIconProps["icon"]> = {
    "text": "file-lines",
    "file": "file-arrow-up",
    "link": "link",
}

export const DealSetupAssets = ({ project, user }: any) => {

    const { fetched, projectAssets, error, reloadProjectAssets } = useGetProjectAssets(project.id ?? "");
    const [projectAssetForm, setProjectAssetForm] = useState<any>({
        projectAsset: null,
        assetType: "",
        editMode: false
    });

    if (!fetched) return null;


    const handleAddAsset = (type: any) => {
        if (type === "Product Description" || projectAssets.length > 0) {
            setProjectAssetForm({
                projectAsset: null,
                assetType: type,
                editMode: true
            });
        }
    }

    const handleEditAsset = (asset: any) => {
        setProjectAssetForm({
            ...projectAssetForm,
            projectAsset: asset,
            assetType: asset.type,
            editMode: true
        });
    }

    const setEditMode = (mode: boolean) => {
        setProjectAssetForm({
            ...projectAssetForm,
            editMode: mode
        });
    }

    return (
        <Box>
            {projectAssets.length > 0 &&
                <AssetTable>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectAssets.map((asset: any) => (
                            <TableRow key={asset.id}>
                                <TableCell><Typography sx={{ fontSize: "1.2rem" }} ><FontAwesomeIcon icon={fileTypeIcons[asset.format]} /></Typography></TableCell>
                                <TableCell>{asset.title}</TableCell>
                                <TableCell>{asset.type}</TableCell>
                                <TableCell>
                                    <span className="link" onClick={() => { handleEditAsset(asset) }}>Edit</span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </AssetTable>
            }
            {projectAssets.length === 0 ? <p>Start by creating a product description for buyers to review. You'll be able to add additional assets afterwards.</p> : <p>Upload one or more sales and marketing assets that you'd like the buyers to review and react to.</p>}
            <AssetButtons>
                <AssetButton onClick={() => handleAddAsset("Product Description")} className="enabled">
                    <p className="title">Product Description</p>
                    <p>Text, Word, Google Docs, etc.</p>
                </AssetButton>
                <AssetButton onClick={() => handleAddAsset("Sales Deck")} className={projectAssets.length === 0 ? "disabled" : "enabled"}>
                    <p className="title">Sales Deck</p>
                    <p>PDF, PPT, Google Slides, etc.</p>
                </AssetButton>
                <AssetButton onClick={() => handleAddAsset("Product Demo")} className={projectAssets.length === 0 ? "disabled" : "enabled"}>
                    <p className="title">Product Demo</p>
                    <p>YouTube, Vimeo, .mov, .mp4, etc.</p>
                </AssetButton>

            </AssetButtons>
            {projectAssetForm.editMode &&
                <DealAssetForm project={project} dealAsset={projectAssetForm.projectAsset} dealAssets={projectAssets} reloadDealAssets={reloadProjectAssets} editMode={projectAssetForm.editMode} setEditMode={setEditMode} dealAssetType={projectAssetForm.assetType} />
            }
        </Box>
    )
}

