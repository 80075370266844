import Loading from "../../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";

export const Profile = () => {
  const { user } = useAuth0();

  return user ? (
    <main className="page-main">
      <div className="gc_view">
        <Outlet context={{ user: user }} />
      </div>
    </main>

  ) : '';
};
