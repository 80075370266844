import { useAuth0 } from "@auth0/auth0-react";
import { createDealUpdate, updateDealUpdate } from "../../services/gutcheck-admin-api.service";
import { useState } from "react";
import { Modal, Box, TextField, Stack, FormControlLabel, Switch } from "@mui/material";
import type { DialogProps } from "@mui/material";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '50%'
};

export const DealUpdateForm = ({ project, dealUpdate, reloadDealUpdates, editMode, setEditMode }: any) => {

    const { getAccessTokenSilently } = useAuth0();

    const initialDealUpdate = {
        title: dealUpdate?.title ?? "",
        body: dealUpdate?.body ?? "",
        subject: dealUpdate?.subject ?? "",
        published_at: dealUpdate?.published_at ? dayjs.utc(dealUpdate.published_at).local().format('YYYY-MM-DDTHH:mm') : "",
        is_published: dealUpdate?.is_published ?? false,
        type: dealUpdate?.type ?? "INVESTMENT_UPDATE"
    }

    const [dealUpdateForm, setDealUpdateForm] = useState(initialDealUpdate);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        const newDealUpdate = dealUpdateForm;
        newDealUpdate.published_at = new Date(dealUpdateForm.published_at).toISOString();

        if (dealUpdate?.id) {
            await updateDealUpdate(project.id, dealUpdate.id, newDealUpdate, accessToken);
        } else {
            await createDealUpdate(project.id, newDealUpdate, accessToken);
        }
        setDealUpdateForm(initialDealUpdate);
        await reloadDealUpdates();
        setEditMode(false);
    }

    const handleChange = (event: any) => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        setDealUpdateForm({ ...dealUpdateForm, [event.target.name]: value });
    }

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setEditMode(false);
    }

    const handleCancel = (event: any) => {
        event.preventDefault();
        setEditMode(false);
    }

    return (
        <Modal
            open={editMode} onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="admin-modal-intro">
                    <h5>{dealUpdate?.id ? "Edit Deal Update" : "Add Deal Update"}</h5>
                    {dealUpdate.type === "INVESTMENT_UPDATE" || !dealUpdate?.id ?
                        <p>This update won't show up on expert pages until it is published and won't generate emails until you trigger it.</p>
                        :
                        <p><strong>Note:</strong>This is a system generated update. Changing it here will only effect what shows up on the Expert Invest page and will not impact the system emails.</p>
                    }
                </div>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <TextField name="subject" label="Subject (for Email Notifications)" onChange={handleChange} value={dealUpdateForm?.subject} />
                        <TextField name="title" label="Title" onChange={handleChange} value={dealUpdateForm?.title} />
                        <TextField multiline rows={4} name="body" label="Body" onChange={handleChange} value={dealUpdateForm?.body}></TextField>
                        <TextField type="datetime-local" name="published_at" InputLabelProps={{
                            shrink: true,
                        }}
                            label="Post Date" onChange={handleChange} value={dealUpdateForm?.published_at}></TextField>
                        <div>
                            <FormControlLabel name="is_published" control={<Switch checked={dealUpdateForm?.is_published} onChange={handleChange} />} label="Is Published" />
                        </div>
                        <button type="submit">Submit</button>
                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>

                    </Stack>
                </form>
            </Box>
        </Modal>
    );

}