import { Outlet, NavLink } from "react-router-dom";

export const Legal = () => {
  return (
    <main className="page-main">

      <div className="gc_view">
        <div className="project-header">
          <h2>Legal</h2>

          <NavLink end to={`/legal`}>Terms</NavLink>
          <NavLink to={`/legal/privacy`}>Privacy</NavLink>
        </div>
        <div className="project-content">
          <Outlet />
        </div>
      </div>
    </main>
  );
};
