import { Box, Dialog, styled, Table, TableBody, TableCell, TableHead, TableProps, TableRow } from "@mui/material";
import { DealExecutive } from "./DealExecutive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DealCompany } from "./DealCompany";
import { useState } from "react";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const AngelTable = styled(Table)<TableProps>(({ theme }) => ({
    borderCollapse: "separate",
    borderSpacing: "0 0.5rem",
    marginBottom: "60px",
    "& th": {
        fontWeight: 700,
        fontSize: "1rem",
        borderBottom: "1px solid #000000",
        "&.td-sm": {
            width: "10%"
        },
        "&.td-md": {
            width: "20%"
        },
        "&.td-lg": {
            width: "30%"
        }
    },
    "& td": {
        fontSize: "1rem",
        "&.action": {
            fontSize: "1.5rem",
            cursor: "pointer",
        }
    },
    "& .td-center": {
        textAlign: "center",
    }
}));

const AngelHeading = styled("h5")(({ theme }) => ({
    fontSize: "1.5rem",
    margin: "1rem 0",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "space-between",
    "& span": {
    }
}));

const AngelStaging = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#f8f9fa",
    borderTop: "1px solid #000000",
    padding: "40px",
    marginBottom: "60px",
    "& p": {
        padding: "30px",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: "1px solid #c9c9c9"
    }
}));

export const DealAngelsList = ({project, viewpoints, user, angelStatus}: any) => {

    const [angelDialogOpen, setAngelDialogOpen] = useState(false);

    const angelsList = viewpoints.filter((viewpoint: any) => {
        switch (angelStatus) {
            case "Funded":
                return viewpoint?.survey_response?.funded;
            case "Reserved":
                return viewpoint?.survey_response?.reserved_spot && !viewpoint?.survey_response?.funded;
            case "Soft-Circled":
                return viewpoint?.interest_investor && !viewpoint?.survey_response?.funded && !viewpoint?.survey_response?.reserved_spot;
            default:
                return false;
        }
    });

    const totalAmount = angelsList.reduce((acc: number, viewpoint: any) => {
        switch (angelStatus) {
            case "Funded":
                return acc + viewpoint?.survey_response?.funded_amount ?? 0;
            case "Reserved":
                return acc + viewpoint?.survey_response?.reserved_amount ?? 0;
            case "Soft-Circled":
                return acc + ((viewpoint?.investment_amount_high ?? 0) + (viewpoint?.investment_amount_low ?? 0))/2;
            default:
                return acc;
        }
    }, 0);

    if (!angelsList?.length) {
        return (
            <Box>
                <AngelHeading>{angelStatus} <span>${totalAmount.toLocaleString()}</span></AngelHeading>
                <AngelStaging>
                    <p>Invite key executives who you would like to participate in your round.</p>
                </AngelStaging>
            </Box>
        );
    }


    return (
        <Box>
            <AngelHeading>{angelStatus} <span>${Math.round(totalAmount).toLocaleString()}</span></AngelHeading>
            <AngelTable>
                <TableHead>
                    <TableRow>
                        <TableCell className="td-md">Executive</TableCell>
                        <TableCell className="td-md">Title</TableCell>
                        <TableCell className="td-md td-center">Company</TableCell>
                        <TableCell className="td-md td-center">Amount</TableCell>
                        <TableCell className="td-sm td-center">Invite</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {angelsList.map((viewpoint: any) => (
                        <TableRow key={viewpoint.id}>
                            <TableCell className="td-md">
                                <DealExecutive project={project} viewpoint={viewpoint} style="angel-list" user={user} />
                            </TableCell>
                            <TableCell className="">{viewpoint.title}</TableCell>
                            <TableCell className=""><DealCompany viewpoint={viewpoint} /></TableCell>
                            <TableCell className="td-center">
                                {
                                    angelStatus === "Funded" ? `$${viewpoint.survey_response?.funded_amount.toLocaleString()}` :
                                    angelStatus === "Reserved" ? `$${viewpoint.survey_response?.reserved_amount.toLocaleString()}` :
                                    `$${viewpoint.investment_amount_low.toLocaleString()} - $${viewpoint?.investment_amount_high.toLocaleString()}`
                                }
                            </TableCell>
                            <TableCell className="td-center action" onClick={() => setAngelDialogOpen(true)}><FontAwesomeIcon icon={faEnvelope}/></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </AngelTable>
            <Dialog open={angelDialogOpen} onClose={() => setAngelDialogOpen(false)}>
                <Box>
                    <h5>Invite to Invest</h5>
                    <p>Please contact <a href="mailto:hello@brightloop.ai">hello@brightloop.ai</a> to enable this feature.</p>
                </Box>
            </Dialog>
        </Box>
    );





    }