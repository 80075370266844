import { getOrganization } from "../../../core/services/gutcheck-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import Organization from "../types/Organization";

export const useWorkspace = (initialOrganizationTag: string) => {
    const [ organization, setOrganization ] = useState<Organization | null>(null);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ error, setError ] = useState(null);

    const { getAccessTokenSilently } = useAuth0();

    const fetchOrganization = async (organizationTag: string) => {
        const accessToken = await getAccessTokenSilently();
        setIsLoading(true);
        try {
            // Fetch data
            const response = await getOrganization(organizationTag, accessToken)
            if (response.data) {
                setOrganization(response.data?.organization);
            } else {
            }
            
        } catch (error) {
            
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchOrganization(initialOrganizationTag);
    }, []);

    return { organization, fetchOrganization, isLoading, error };
}