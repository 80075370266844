import { useOutletContext } from "react-router-dom";
import { useSurveyResponses } from "../hooks/useSurveyResponses";
import { useEffect, useState } from "react";
import { Checkbox, FormControl, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { SurveyResponseForm } from "./components/SurveyResponseForm";

export const AdminSurveyResponses = () => {

    const { project } = useOutletContext<any>();
    const { fetched, surveyResponses, error, reloadSurveyResponses } = useSurveyResponses(project.id ?? "");
    const [surveyResponse, setSurveyResponse] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [filteredSurveyResponses, setFilteredSurveyResponses] = useState([]);

    const [hideNoEmail, setHideNoEmail] = useState(true);
    const [searchFilter, setSearchFilter] = useState("");

    const handleToggleHideNoEmail = () => {
        setHideNoEmail(!hideNoEmail);
    };

    const handleSearchFilter = (event: any) => {
        setSearchFilter(event.target.value);
    }

    useEffect(() => {
        if (fetched) {
            setFilteredSurveyResponses(surveyResponses.filter((surveyResponse: any) => {
                const name = surveyResponse.viewpoint?.name ?? surveyResponse.name ?? "";
                const email = surveyResponse.viewpoint?.email && surveyResponse.viewpoint.email !== "" ? surveyResponse.viewpoint.email : surveyResponse.email_address ?? "";
                if (hideNoEmail && email === "") {
                    return false;
                }
                if (searchFilter === "") {
                    return true;
                }
                if (name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
                    return true;
                }
                if (email.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
                    return true;
                }
                return false;
            }));
        }
    }, [fetched, surveyResponses, hideNoEmail, searchFilter]);

    const handleEdit = (surveyResponse: any) => {
        setSurveyResponse(surveyResponse);
        setEditMode(true);
    }

    if (!fetched || !project) { return <></> }


    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <h5>Survey Responses</h5>
                <div className="btn btn-primary" onClick={handleEdit}>Add Survey Response</div>
            </div>

            <div>
                <p className="bold">Filters</p>
                <TextField type="text" label="Search" value={searchFilter} onChange={handleSearchFilter} size="small" sx={{ marginRight: '20px'}} />
                <FormControlLabel control={<Checkbox checked={hideNoEmail} onChange={handleToggleHideNoEmail} />} label="Hide Anonymous" />
            </div>

            <Table className="survey-responses-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell className="center">Status</TableCell>
                        <TableCell>Reserved</TableCell>
                        <TableCell>Funded</TableCell>
                        <TableCell>Completed At</TableCell>
                        <TableCell className="admin-action">Edit</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {filteredSurveyResponses.map((surveyResponse: any) => {
                        const name = surveyResponse.viewpoint?.name ?? surveyResponse.name;
                        const email = surveyResponse.viewpoint?.email && surveyResponse.viewpoint.email !== "" ? surveyResponse.viewpoint.email : surveyResponse.email_address;
                        const url = `${process.env.REACT_APP_AUTH0_CALLBACK_URL}/e/${surveyResponse.survey_link?.public_id}/expert/${surveyResponse.public_id}`;
                        return (
                            <TableRow key={surveyResponse.uuid}>
                                <TableCell>{surveyResponse.id}</TableCell>
                                <TableCell>{(new Date(surveyResponse.created_at).toDateString())}</TableCell>
                                <TableCell>{name}</TableCell>
                                <TableCell>{email}</TableCell>
                                <TableCell>{surveyResponse.status}</TableCell>
                                <TableCell className="center">{surveyResponse.reserved_spot ? "$" + surveyResponse.reserved_amount.toLocaleString() : "--"}</TableCell>
                                <TableCell className="center">{surveyResponse.funded ? "$" + surveyResponse.funded_amount.toLocaleString() : "--"}</TableCell>
                                <TableCell>{surveyResponse.completed_at && surveyResponse.completed_at !== "" ? (new Date(surveyResponse.completed_at).toDateString()) : "--"}</TableCell>
                                <TableCell className="admin-action active" onClick={() => handleEdit(surveyResponse)}><FontAwesomeIcon icon={faEdit} /></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            {editMode && <SurveyResponseForm project={project} surveyResponse={surveyResponse} reloadSurveyResponses={reloadSurveyResponses} editMode={editMode} setEditMode={setEditMode} />}
        </div>
    )
}
