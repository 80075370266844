import { useOutletContext, useParams } from "react-router-dom";
import { DealTitle } from "../components/DealTitle";
import { DealViewpointsList } from "../components/DealViewpointsList";

export const DealViewpoints = () => {
    const { project, organization, viewerRole, user } = useOutletContext<{ project: any, organization: any, viewerRole: any, user: any }>();

    return (
        <div>
            <DealTitle project={project} section="Viewpoints" />
            <DealViewpointsList project={project} viewpoints={project?.viewpoints} user={user} />
        </div>
    );
}   