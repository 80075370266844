import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProject } from "../services/gutcheck-admin-api.service";
import Organization from "../../workspaces/types/Organization";
import Project from "../../projects/types/Project";

type ProjectData = {
  fetched: boolean;
  organization: Organization | null;
  project: Project | null;
  error: {message: any} | null;
}

const initState: ProjectData = {
  fetched: false,
  organization: null,
  project: null,
  error: null
}

const useAdminGetProjectData = (projectId: number) => {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const [state, setState] = useState(initState);

    const reloadProjectData = async () => {
      setState({
        ...state,
        fetched: false
      });

      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getProject(projectId, accessToken);

      if (data) {
        data.project.result_json = data.project.result_json ? JSON.parse(data.project.result_json) : null;
        data.project.revision_result_json = data.project.revision_result_json ? JSON.parse(data.project.revision_result_json) : null;
        
        const fetchedProject: ProjectData = {
          fetched: true,
          organization: data.project.organization,
          project: data.project,
          error: error
        };
        
        setState(fetchedProject);

      } else {
        console.error(error);
      }
    }

    useEffect(() => {
        let isMounted = true;
    
        const fetchProjectData = async () => {
          if (!isMounted) return;    
          reloadProjectData();
        };
    
        fetchProjectData(); 
    
        return () => { isMounted = false };
    }, []);

    return { ...state, reloadProjectData};
};

export default useAdminGetProjectData;