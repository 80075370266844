import { EditorProvider, FloatingMenu, BubbleMenu, useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';

type TTextViewer = {
    content: string
};

const extensions = [
    StarterKit,
    Link
];


const TextViewer = ({ content }: TTextViewer) => {

    const editor = useEditor({
        editable: false,
        extensions: extensions,
        editorProps: {
            attributes: {
                class: "Editor"
            }
        },
        content: content

    });

    return (
        <div className="text-editor-container">
            {editor && (


                <EditorContent editor={editor}>
                </EditorContent>
            )}
        </div>
    )
}

export default TextViewer;