import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getDealUpdates, getProject } from "../services/gutcheck-admin-api.service";
import Organization from "../../workspaces/types/Organization";
import Project from "../../projects/types/Project";

type DealUpdatesData = {
  fetched: boolean;
  dealUpdates: any;
  error: {message: any} | null;
}

const initState: DealUpdatesData = {
  fetched: false,
  dealUpdates: [],
  error: null
}

export const useDealUpdates = (projectId: number) => {
    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState(initState);

    const reloadDealUpdates = async () => {
      setState({
        ...state,
        fetched: false
      });

      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getDealUpdates(projectId, accessToken);

      if (data) {        
        const fetchedDealUpdates: DealUpdatesData = {
          fetched: true,
          dealUpdates: data.dealUpdates ?? [],
          error: error
        };
        
        setState(fetchedDealUpdates);

      } else {
        console.error(error);
      }
    }

    useEffect(() => {
        let isMounted = true;
    
        const fetchDealUpdates = async () => {
          if (!isMounted) return;    
          reloadDealUpdates();
        };
    
        fetchDealUpdates(); 
    
        return () => { isMounted = false };
    }, []);

    return { ...state, reloadDealUpdates};
};
