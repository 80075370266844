import React, { useEffect, useState } from 'react';
import { getOrganizations, createOrganization } from '../services/gutcheck-admin-api.service';
import { useAuth0 } from "@auth0/auth0-react";

type TAddOrganization = {
  parentCallback: any
}

const AddOrganizationForm = ({ parentCallback }: TAddOrganization) => {

  const [organizations, setOrganizations] = useState();
  const [form, setForm] = useState({
    name: '',
    project: null,    
  });

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getOrganizations(accessToken);

      if (data ) {
        setOrganizations(data.organizations.map( (organization: any) => {
          return { value: organization.id, label: organization.name };
        }));
      }
    };

    fetchData();

    return () => {
    }
  }, []);


  const handleChange = (event: any) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const accessToken = await getAccessTokenSilently();
    const organization = {
      name: form.name,
    }
    const { data, error } = await createOrganization(organization, accessToken);

    setForm({
      ...form,
      name: '',
    });

    parentCallback( data.organization );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="add-field textfield-wrapper">
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          value={form.name}
          onChange={handleChange}
        />
      </div>
     
      <button type="submit">Submit</button>
    </form>
  );
};

export { AddOrganizationForm };