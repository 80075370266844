import { Route, Routes, useParams } from "react-router-dom";
import { DealOverview } from "./DealOverview";
import { Deal } from "./Deal";
import { DealViewpoints } from "./DealViewpoints";
import { DealAnalysis } from "./DealAnalysis";
import { DealProspects } from "./DealProspects";
import { DealIndustryAngels } from "./DealIndustryAngels";
import { DealAdvisors } from "./DealAdvisors";
import { DealCandidates } from "./DealCandidates";
import { DealSetup } from "./DealSetup";
import { DealCollaboration } from "./DealCollaboration";
import { DealContext } from "../context";
import { useState } from "react";


export const DealRoutes = () => {

    return (
            <Routes>
                <Route path=":projectTag" element={<Deal />}>
                    <Route index element={<DealOverview />} />
                    <Route path="viewpoints" element={<DealViewpoints />} />
                    <Route path="analysis" element={<DealAnalysis />} />
                    <Route path="prospects" element={<DealProspects />} />
                    <Route path="industry-angels" element={<DealIndustryAngels />} />
                    <Route path="advisors" element={<DealAdvisors />} />
                    <Route path="candidates" element={<DealCandidates />} />
                    <Route path="setup" element={<DealSetup />} />
                    <Route path="collaboration" element={<DealCollaboration />} />
                </Route>
            </Routes>
    );
}