import { Route, Routes } from "react-router-dom";
import { Invitation } from "./Invitation";
import { InvitationIndex } from "./invitation-index";
import { AuthenticationGuard } from "../../../components/AuthenticationGuard";
import { InvitationReview } from "./invitation-review";
import { InvitationAccept } from "./invitation-accept";

export const InvitationsRoutes = () => {
    return (
        <Routes>
            <Route path=":invitationCode" element={<Invitation/>}>
              <Route index element={<InvitationIndex/>}/>
              <Route path="review" element={<AuthenticationGuard component={InvitationReview} />} />
              <Route path="accept" element={<AuthenticationGuard component={InvitationAccept} />} />
            </Route>
        </Routes>
    );
}