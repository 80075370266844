import { styled, Table, TableRow, TableCell, TableHead, TableBody, Typography, Select, MenuItem, Button } from "@mui/material";
import { useGetProjectMembers } from "../../hooks/useGetProjectMembers";
import { useState } from "react";
import { UserProfileImage } from "../../../users";
import { useAuth0 } from "@auth0/auth0-react";
import { removeProjectMember, updateProjectMember } from "../../services/gutcheck-admin-api.service";

const MembersList = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    gap: '5px',
});

const roles = [
    { value: "admin", label: "Admin" },
    { value: "contributor", label: "Contributor" },
    { value: "guest", label: "Guest" },
];



const ProjectMember = ({ project, member, reloadProjectMembers }: any) => {

    const { getAccessTokenSilently } = useAuth0();

    const [ memberForm, setMemberForm ] = useState({
        role: member.role,
        editMode: false,
    });

    const [ editMode, setEditMode ] = useState(false);

    const handleChange = (e: any) => {
        setMemberForm({
            ...memberForm,
            role: e.target.value,
        });
    }

    const handleCancel = () => {
        setMemberForm({
            ...memberForm,
            role: member.role,
            editMode: false,
        });
    }

    const handleRemove = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await removeProjectMember(project.id, member.user.id, accessToken);

        if (data) {
            setMemberForm({
                ...memberForm,
                editMode: false,
            });
            reloadProjectMembers();
        }
    }

    const handleSubmit = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateProjectMember(project.id, member.user.id, memberForm.role, accessToken);

        if (data) {
            setMemberForm({
                ...memberForm,
                editMode: false,
            });
            reloadProjectMembers();
        }
    }

    return (
        <TableRow key={member.id}>
            <TableCell sx={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                <UserProfileImage user={member.user} />
                <Typography variant="body1" sx={{ marginLeft: "10px" }}>
                    {member.user.name} <br />
                    {member.user.email_address}
                </Typography>
            </TableCell>
            <TableCell>
                <Select
                    value={memberForm.role}
                    onChange={(e) => handleChange(e)}
                    size="small"
                    disabled={!memberForm.editMode}
                >
                    {roles.map((role) => (
                        <MenuItem key={role.value} value={role.value} selected={ role.value === memberForm.role}>{role.label}</MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell>
                    { memberForm.editMode && <button onClick={handleSubmit}>Save</button> }
                    { memberForm.editMode && <Button size="small" onClick={handleCancel}>Cancel</Button> }
                    { memberForm.editMode && <Button size="small" sx={{color: "red"}} className="remove-member" onClick={handleRemove}>Remove</Button> }
                    { !memberForm.editMode && <button onClick={() => setMemberForm({...memberForm, editMode: true})}>Edit</button> }
            </TableCell>
        </TableRow>
    );
}


export const ProjectMembers = ({ project, user }: any) => {

    const { fetched, members, error, reloadProjectMembers } = useGetProjectMembers(project.id ?? "");
    const [editMember, setEditMember] = useState({
        open: false,
        member: null,
    });

    if (!fetched) return null;

    const handleEdit = (member: any) => {
        setEditMember({
            open: true,
            member: member,
        });
    }


    return (
        <MembersList>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: "40%"}}>Member</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell sx={{width: "40%"}}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {members.map((member: any) => (
                        <ProjectMember project={project} member={member} reloadProjectMembers={reloadProjectMembers} key={member.id} />
                    ))}
                </TableBody>
            </Table>
        </MembersList>
    );
}