import { useOutletContext, useParams } from "react-router-dom";
import useAdminGetProjectData from "../hooks/useAdminGetProjectData";
import { DealForm } from "./components/DealForm";

export const AdminProjectDealEdit = () => {
    const { projectId } = useParams();
    //const { fetched, organization, project, error } = useAdminGetProjectData(parseInt(projectId ?? ""));
    const { project, reloadProjectData } = useOutletContext<any>();


    return (
        <div className="admin-screen">
            <div className="admin-table-header">

                <h5>Deal / SPV Settings</h5>
            </div>
            <DealForm project={project} reloadProjectData={reloadProjectData} />
        </div>
    );
}