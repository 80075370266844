import { Route, Routes } from "react-router-dom";
import Home from "../views/Home";
import { NotFoundPage } from "../views/pages/NotFoundPage";
import { AuthenticationGuard } from "../components/AuthenticationGuard";
import { BasicLayout } from "../components/BasicLayout";
import { AppLayout } from "../components/AppLayout";
import { WorkspacesRoutes } from "../features/workspaces";
import { ProjectsRoutes } from "../features/projects";
import { InvitationsRoutes } from "../features/invitations";
import { LegalRoutes } from "../features/legal";
import { AdminRoutes } from "../features/admin";
import { UsersRoutes } from "../features/users";
import "../App.css";
import { Dashboard } from "../features/misc";
import UnrecognizedUser from "../components/UncrecognizedUser";
import { DealRoutes } from "../features/deal";


export const AppLayoutRoutes = () => {
    return (
        <AppLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/unrecognized" element={<UnrecognizedUser />} />
            <Route path="/dashboard" element={<AuthenticationGuard component={ Dashboard } />}/>
            <Route path="/profile/*" element={<AuthenticationGuard component={UsersRoutes} />} />

            <Route path="/o/:organizationTag/d/*" element={<AuthenticationGuard component={DealRoutes} />} />
            <Route path="/o/:organizationTag/project/*" element={<AuthenticationGuard component={ProjectsRoutes} />} />
            <Route path="/o/*" element={<AuthenticationGuard component={WorkspacesRoutes} />} />
            <Route path="/organization/*" element={<AuthenticationGuard component={WorkspacesRoutes} />} />
            <Route path="/invitation/*" element={<InvitationsRoutes/>} />
            <Route path="/admin/*" element={<AuthenticationGuard component={AdminRoutes} role="admin" />} />
            <Route path="/legal/*" element={<LegalRoutes/>} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AppLayout>
      );
}

export default AppLayoutRoutes;