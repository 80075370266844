import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getPDFSignedUrls } from "../features/admin/services/gutcheck-admin-api.service";

type TSignPDF = {
    revision_url: string
};

const initState: TSignPDF = {
    revision_url: ""
}

const useSignPdfUrl = (projectId: number, testUrl: string) => {
    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState(initState);

    useEffect(() => {
        let isMounted = true;

        const fetchProjectData = async () => {
            const accessToken = await getAccessTokenSilently();

            if(!testUrl) return;

            const res = await getPDFSignedUrls(projectId, testUrl, accessToken);

            if (!isMounted) return;

            if (res.data) {
                setState({
                    revision_url: res.data.project.revision_url
                });
            };
        };

        fetchProjectData();

        return () => {
            isMounted = false;
        };
    }, [testUrl]);

    return state.revision_url;
};

export default useSignPdfUrl;