const UserNotFound = () => (
  <div className="text-center hero my-5">
    <h3>Account not found</h3>

    <p className="lead">
      There is no account associated with this email address. Please contact the admin if you think this is an error.
    </p>
  </div>
);

export default UserNotFound;
