import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import ClientProvider from "../modules/ClientProvider";
import { useNavigate } from "react-router-dom";
import Organization from "../features/workspaces/types/Organization";
import Project from "../features/projects/types/Project";
import { loadProject } from "../features/admin/services/ai-chain-service";

type ProjectData = {
  fetched: boolean;
  organization: Organization | null;
  project: Project | null;
  aiResults: any | null;
  error: {message: any} | null;
  role: string | null;
}

const initState: ProjectData = {
  fetched: false,
  organization: null,
  project: null,
  aiResults: null,
  error: null,
  role: null
}

const useGetProjectData = (projectTag: string) => {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const [state, setState] = useState(initState);

    useEffect(() => {
        let isMounted = true;
    
        const fetchProjectData = async () => {
          const accessToken = await getAccessTokenSilently();
          const { data, error } = await ClientProvider.provideProjectClient().getProjectData(projectTag, accessToken);
    
          if (!isMounted) return;
    
          if (data) {
            data.project.result_json = data.project.result_json ? JSON.parse(data.project.result_json) : {};
            data.project.revision_result_json = data.project.revision_result_json ? JSON.parse(data.project.revision_result_json) : {};

            const aiResults = data.project.user_type === "marketer" ? await loadProject(data.project, accessToken) : null;            
            const fetchedProject: ProjectData = {
              fetched: true,
              organization: data.project.organization,
              project: data.project,
              aiResults: aiResults,
              error: error,
              role: data.role
            };
            
            setState(fetchedProject);

          } else {
            return navigate('/');
          }
        };
    
        fetchProjectData(); 
    
        return () => { isMounted = false };
    }, []);

    return state;
};

export default useGetProjectData;