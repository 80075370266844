import StyledDropzone from "../../../../components/AssetUpload";
import { uploadLogo } from "../../../../core/services/gutcheck-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, DialogProps, FormControlLabel, Modal, Stack, styled, Switch, TextField } from "@mui/material";
import { useState } from "react";
import { updateViewpoint, getCompanyByDomain, updateCompany } from "../../services/gutcheck-admin-api.service";
import { DealCompany, DealProfileImage } from "../../../../features/deal";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '50%'
};


const CompanyLogo = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
        maxHeight: "34px",
        maxWidth: "100px"
    },
    "&.mini": {
        "& img": {
            marginTop: "10px",
            maxHeight: "20px",
            maxWidth: "100px",
            filter: "grayscale(1)"
        }
    }
}));

const CompanyName = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem",
    fontWeight: 700,
    padding: "20px",
    textAlign: "center",
    "&.mini": {
        fontSize: "0.8rem",
        padding: "5px",
    }
}));

export const AdminCompany = ({ company, size = "medium" }: any) => {

    if (company?.logo_url) {
        return (
            <CompanyLogo className={size}>
                <img src={company.logo_url} alt={company.name} />
            </CompanyLogo>
        );
    }
    return (
        <CompanyName className={size}>
            {company?.name}
        </CompanyName>
    );
}

export const ViewpointForm = ({ viewpoint, project, user, setViewpoint, editMode, setEditMode, reloadProjectData }: any) => {

    const { getAccessTokenSilently } = useAuth0();

    const [originalViewpoint, setOriginalViewpoint] = useState(viewpoint);
    const [viewpointForm, setViewpointForm] = useState(viewpoint);
    const [queuedFiles, setQueuedFiles] = useState<File[]>([]);
    const [companyForm, setCompanyForm] = useState<any>(viewpoint.company);
    const [editCompany, setEditCompany] = useState(false);

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setEditMode(false);
    }

    const handleCancel = async (event: any) => {
        event.preventDefault();
        if (originalViewpoint !== viewpoint) {
            await reloadProjectData();
        }
        setEditMode(false);
    }

    const handleChange = (event: any) => {
        const value = event.target.type === "checkbox" ? !viewpointForm[event.target.name] : event.target.value;
        setViewpointForm({ ...viewpointForm, [event.target.name]: value });
    }

    const handleCompanyLookup = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getCompanyByDomain(companyForm.domain, accessToken);
        setCompanyForm(data.company);
    }

    const handleCompanySave = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        let companyLogoUrl = companyForm.logo_url;
        if (queuedFiles.length > 0) {
            const { data, error } = await uploadLogo(queuedFiles[0], companyForm.domain ?? companyForm.id, queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, ""), accessToken);
            companyLogoUrl = data.response.url;
        }

        companyForm.logo_url = companyLogoUrl;
        const { data, error } = await updateCompany(companyForm.id, companyForm, accessToken);
        setCompanyForm(data.company);
        setEditCompany(false);
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        const newViewpoint = viewpointForm;
        newViewpoint.company = companyForm;

        const { data, error } = await updateViewpoint(project.id, viewpoint.id, newViewpoint, accessToken);

        setViewpointForm(data.viewpoint);
        setCompanyForm(data.viewpoint.company);
        setOriginalViewpoint(data.viewpoint);
        //await reloadProjectData();
        //setEditMode(false);
    }

    return (
        <Modal
            open={editMode} onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="admin-modal-intro">
                    <h5>Edit Viewpoint</h5>
                    <p>Text goes here.</p>

                </div>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <Box sx={{ display: "flex", gap: "20px" }}>
                            <TextField size="small" name="name" label="Name" onChange={handleChange} value={viewpointForm?.name} sx={{ flex: 1 }} />
                            <TextField size="small" name="email" label="Email" onChange={handleChange} value={viewpointForm?.email} sx={{ flex: 1 }} />
                        </Box>
                        <Box sx={{ display: "flex", gap: "20px" }}>
                            <TextField size="small" name="linkedin" label="LinkedIn" onChange={handleChange} value={viewpointForm?.linkedin} sx={{ flex: 1 }} />
                        </Box>
                        <Box sx={{ display: "flex", gap: "20px" }}>
                            <DealProfileImage viewpoint={viewpointForm} size="small" />
                            <TextField size="small" name="image" label="Image URL" onChange={handleChange} value={viewpointForm?.image} sx={{ flex: 1 }} />
                        </Box>
                        <TextField sx={{ flex: 1 }} size="small" name="title" label="Title" onChange={handleChange} value={viewpointForm?.title} />
                        <Box sx={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                            <Box sx={{ border: "1px solid #c9c9c9", padding: "1rem", flex: 1 }}>
                                {editCompany ?
                                    <>
                                        <TextField size="small" name="domain" label="Domain" onChange={(e) => setCompanyForm({ ...companyForm, domain: e.target.value })} value={companyForm?.domain ?? ""} />
                                        <TextField size="small" name="company_name" label="Name" onChange={(e) => setCompanyForm({ ...companyForm, name: e.target.value })} value={companyForm?.name ?? viewpointForm.company_name} />
                                        <div className="logo-uploader">
                                            <StyledDropzone fileTypes={{ 'image/*': [] }} queuedFiles={[]} setQueuedFiles={setQueuedFiles} initialFile={companyForm?.logo_url ?? ""} />
                                        </div>
                                        <button type="button" onClick={handleCompanyLookup}>Lookup</button>
                                        <button type="button" onClick={handleCompanySave}>Save</button>
                                    </>
                                    :
                                    companyForm ?
                                        <AdminCompany company={companyForm} />
                                        :
                                        <TextField name="company_name" label="Company" onChange={handleChange} value={viewpointForm?.company_name} />
                                }
                            </Box>
                            <span onClick={() => setEditCompany(true)}>Edit Company</span>
                        </Box>
                        <div>
                            <FormControlLabel name="is_published" control={<Switch checked={viewpointForm?.is_published} onChange={handleChange} />} label="Visible to Company" />
                            <FormControlLabel name="hidden_from_guest" control={<Switch checked={!viewpointForm?.hidden_from_guest} onChange={handleChange} />} label="Visible to Guest" />
                            <FormControlLabel name="included_in_analysis" control={<Switch checked={viewpointForm?.included_in_analysis} onChange={handleChange} />} label="Included in Analysis" />
                        </div>
                        <button type="submit" disabled={originalViewpoint === viewpointForm && originalViewpoint.company === companyForm}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>Close</button>
                    </Stack>
                </form>
            </Box>
        </Modal>
    )
}