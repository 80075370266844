import { Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";

type FilterKeys = 'dealOwner' | 'upcoming' | 'active' | 'closed';

const existingStatus = [
  { value: "draft", label: "Setup & Launch" },
  { value: "evaluation", label: "Collecting Viewpoints" },
  { value: "analysis", label: "Analysis & Results" },
  { value: "firm_investment", label: "Firm Investment" },
  { value: "spv", label: "BrightLoop Expert SPV" },
  { value: "completed", label: "Completed" }
];

export const DealFilter = ( { allProjects, setFilteredProjects } :any) => {
  
  const [ filters, setFilters ] = useState({
    dealOwner: "all",
    upcoming: true,
    active: true,
    closed: true
  });


  useEffect(() => {
    if (allProjects.length === 0 ) {
      return;
    }
    const filteredProjects = allProjects.filter((project: any) => {
      if (filters.dealOwner === "user" && project.dealOwner !== "user") return false;
      if (!filters.upcoming && project.status === "draft") return false;
      if (!filters.active && (!project.isClosed && project.status !== "draft") ) return false;
      if (!filters.closed && project.isClosed) return false;
      return true;
    });
  
    setFilteredProjects(filteredProjects.sort((a: any, b: any) => existingStatus.findIndex( status => status.value === b.status) - existingStatus.findIndex( status => status.value === a.status)));
  }, [filters, allProjects, setFilteredProjects]);


  const handleFilterChange = (event: any) => {

    const name = event.target.name as FilterKeys;
    const value = event.target.name === "dealOwner" ? event.target.value : event.target.checked;

    setFilters({
      ...filters,
      [name]: value
    });
  }

  return (
    <div className="deal-filters-container">
    <FormControl className="deal-filters">
      <RadioGroup row className="deal-filter-owner" aria-labelledby="deal-filters-group-label" value={filters.dealOwner} onChange={handleFilterChange} name="dealOwner">
        <FormControlLabel value="all" control={<Radio disabled />} label="All Deals" />
        <FormControlLabel value="user" control={<Radio disabled />} label="My Deals" />
      </RadioGroup>
      <FormGroup row>
        <FormControlLabel control={<Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={filters.upcoming} onChange={handleFilterChange} />} name="upcoming" label="Upcoming" />
        <FormControlLabel control={<Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={filters.active} onChange={handleFilterChange} />} name="active" label="Active" />
        <FormControlLabel control={<Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={filters.closed} onChange={handleFilterChange} />} name="closed" label="Closed" />
      </FormGroup>
    </FormControl>
  </div>
  );
}