import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { Table } from "reactstrap";
import { AddUserForm } from "../components/form-add-user";
import { getAllUsers } from "../../../core/services/gutcheck-api.service";

type TAdminUsers = {
  users: any | null,
  filteredUsers: any | null,
  highlightedUser: any |null,
  addUserMessage: any | null
};

const initState: TAdminUsers = {
  users: null,
  filteredUsers: null,
  highlightedUser: null,
  addUserMessage: ""
}

export const AdminUsers = () => {
  const [ state, setState ] = useState(initState);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();

      const [respUsers] = await Promise.all([
        getAllUsers(accessToken)
      ]);

      if (respUsers) {
        setState({
          ...state,
          users: respUsers.data.users,
          filteredUsers: respUsers.data.users
        });
      };
    };

    fetchData();

    return () => {};
  }, []);

  if (!state.users) return <></>;

  const handleAddUserClick = async ( event: any ) => {
    event.target.parentElement.parentElement.classList.add("show-form");
    event.preventDefault();
    setState({
      ...state,
      addUserMessage: ""
    })
  };

  const handleCallback = async ( user: any, message: any ) => {
    const accessToken = await getAccessTokenSilently();
    const { data, error } = await getAllUsers(accessToken);

    if(user !== null) {
      setState({
        ...state,
        highlightedUser: user.id,
        users: data.users,
        filteredUsers: data.users,
        addUserMessage: message
      });
    } else {
      setState({
        ...state,
        addUserMessage: message
      })
    }

    document.getElementsByClassName("add-object-wrapper")[0].classList.remove("show-form");
  };

  const handleSearch = (event: any) => {
    const queryString = event.target.value;
    const filteredUsers = state.users.filter((user: any) => {
      return user.email_address.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
    });

    setState({
      ...state,
      filteredUsers: filteredUsers
    });
  };

  return (
    <>
      <div className="flex-box-wrapper">
        <div className="add-object-wrapper">
          <div className="add-object-toggle">
            <div className="add-object-message">{state.addUserMessage}</div>
            <button
              onClick={handleAddUserClick}
            >
              Add User
            </button>
          </div>
          <div className="add-object-form">
            <AddUserForm parentCallback={handleCallback} />
          </div>
        </div>
        <div className="admin-list-filter">
          Search: <input type="text" id="search-box" onChange={handleSearch} />
        </div>
      </div>
      <Table responsive hover className="gc-respondent-table"> 
        <thead>
        <tr>
          <th>Id</th>
          <th>Email</th>
          <th>Auth</th>
          <th>Role</th>
          <th>Organizations</th>       
        </tr>
        </thead>
        <tbody>

      {state.filteredUsers.map((user: any) => {
        const url = `/admin/user/${user.id}/edit`;
          return (
            <tr key={user.id} className={state.highlightedUser && user.id === state.highlightedUser ? "highlighted" : ""}>
              <td>{user.id}</td>
              <td><Link to={url}>{user.email_address ? user.email_address : "no email"}</Link></td>
              <td>{user.external_id.replace(/\|.*/,"")}</td>
              <td>{user.role}</td>
              <td>{user.organizations.map( (organization: any) => { return (<div key={organization.id} className="user-org-tag">{organization.name}</div>); })}</td>
            </tr>
          );
      })}
      </tbody>
      </Table>
    </>
  );
};
