import { useOutletContext } from "react-router-dom";
import { DealTitle } from "../components/DealTitle";

export const DealCollaboration = () => {
    const { project, organization, viewerRole } = useOutletContext<{ project: any, organization: any, viewerRole: any }>();
    return (
        <div>
            <DealTitle project={project} section="Collaboration" />
        </div>
    );
}   