import Loading from "../../../components/Loading";
import UserNotFound from "../../../components/UserNotFound";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { useUserContext } from "../../../features/users";

export const Dashboard = () => {
    const navigate = useNavigate();

    const { userContext, setUserContext } = useUserContext();

    const invitationCode = localStorage.getItem("invcode");

    useEffect(() => {

        if (invitationCode && invitationCode !== "") {
            return navigate(`/invitation/${invitationCode}/accept`);
        } else if (userContext.fetched && userContext.dbUser) {
            if (userContext.organizationMemberships?.length === 0 && userContext.projectMemberships?.length === 0) {
                navigate('/organization/new', { replace: true });
            } else if (userContext.organizationMemberships?.length === 0) {
                const project = userContext.projectMemberships[0].project;
                const url = project.experience === "winning" ? `/o/${project.organization.tag}/d/${project.tag}` : `/o/${project.organization.tag}/project/${project.tag}`;
                navigate(url, { replace: true });
            } else {
                navigate(`/o/${userContext.organizationMemberships[0].organization.tag}`, { replace: true });
            };
        } else {
            <UserNotFound />
        }

    }, [userContext.fetched]);



    if (!userContext.fetched) return <Loading />


    if (userContext.fetched && !userContext.dbUser) return <UserNotFound />

    return <></>;
}