import NavBar from "./NavBar";
import Footer from "./Footer";
import { ThemeProvider } from "@mui/material";
import { theme } from "../theme/theme";
import { useEffect } from "react";
import { getAllDataForSelf } from "../core/services/gutcheck-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "../features/users";

type PageProps = {
  children: any
}

export const AppLayout = ({ children }: PageProps) => {

  const { userContext, setUserContext } = useUserContext();
  const { user, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const fetchUserData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getAllDataForSelf(accessToken);
      if(data) {
        setUserContext({
          ...userContext,
          dbUser: data.user,
          projectMemberships: data.user?.memberships?.filter((membership: any) => { return membership.project }) || [],
          organizationMemberships: data.user?.memberships?.filter((membership: any) => { return membership.organization }) || [],
          fetched: true
        })
      }
    }

    if (user && !isLoading) fetchUserData();

  }, []);

  return (
      <ThemeProvider theme={theme}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <div className="page-wrapper">
            {children}
          </div>
        </div>
      </ThemeProvider>
  );
};
