import { useAuth0 } from "@auth0/auth0-react";
import { getSurveyResponses } from "../services/gutcheck-admin-api.service";
import { useEffect, useState } from "react";

type SurveyResponsesData = {
    fetched: boolean;
    surveyResponses: any;
    error: {message: any} | null;
}

const initState: SurveyResponsesData = {
    fetched: false,
    surveyResponses: [],
    error: null
}

export const useSurveyResponses = (projectId: number) => {
    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState(initState);

    const reloadSurveyResponses = async () => {
      setState({
        ...state,
        fetched: false
      });

      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getSurveyResponses(projectId, accessToken);

      if (data) {        
        const fetchedSurveyResponses: SurveyResponsesData = {
          fetched: true,
          surveyResponses: data.surveyResponses ?? [],
          error: error
        };
        
        setState(fetchedSurveyResponses);

      } else {
        console.error(error);
      }
    }

    useEffect(() => {
        let isMounted = true;
    
        const fetchSurveyResponses = async () => {
          if (!isMounted) return;    
          reloadSurveyResponses();
        };
    
        fetchSurveyResponses(); 
    
        return () => { isMounted = false };
    }, []);

    return { ...state, reloadSurveyResponses};
}