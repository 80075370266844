import { useOutletContext } from "react-router-dom";
import { DealTitle } from "../components/DealTitle";
import { DealProspectsListList } from "../components/DealProspectsList";

export const DealProspects = () => {
    const { project, organization, user, viewerRole } = useOutletContext<{ project: any, organization: any, user: any, viewerRole: any }>();
    return (
        <div>
            <DealTitle project={project} section="Prospects" />
            <DealProspectsListList project={project} viewpoints={project?.viewpoints} user={user} prospectStage="Engaged" />
            <DealProspectsListList project={project} viewpoints={project?.viewpoints} user={user} prospectStage="Requested Trial" />
            <DealProspectsListList project={project} viewpoints={project?.viewpoints} user={user} prospectStage="Requested Demo" />
        </div>
    );
}   