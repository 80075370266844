import { Link } from "react-router-dom";
import { ProjectShareControl } from "./project_share_component";

type ProjectStatusType = {
    project: any,
    currentRole: string,
    viewerRole: string
}

export const ProjectHeader = ({ project, currentRole, viewerRole }: ProjectStatusType) => {
    return (
        <>
            <div className="project-header-controls">
                {currentRole === "guest" &&
                    <h4><strong>{project.organization.name}</strong> research prepared for:</h4>
                }
                {currentRole !== "guest" &&
                    <h4><Link to={`/o/${project.organization.tag}`}>&larr; {project.organization.name} {project.user_type === "investor" ? "Dealspace" : "Dashboard"}</Link></h4>
                }
                <ProjectShareControl project={project} role={viewerRole} />

            </div>

            <div className="project-header overview-header">
                <h2>{project.name}</h2>
            </div>
        </>
    );
};