import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "reactstrap";
import logo from "../../../assets/images/brightloop-logo.svg"
import useAnalytics from "../../../hooks/useAnalytics";
import { Box, styled, Typography } from "@mui/material";

const SplashPage = styled(Box)(({ theme }) => ({


    "& .background-image": {
        position: "absolute",
        bottom: 0,
        right: 0,
        width: "90%",
        height: "90%",
        opacity: 0.4,
        zIndex: -1,
        overflow: "hidden",
    },

    "& .splash-content": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "100%",
        zIndex: 1,
        maxWidth: "500px",
        padding: "20px 40px",
        "& p": {
            fontSize: "1.5rem",
            marginBottom: "1rem"
        },
        "& .logo-image": {
            height: "60px"
        },
        "& .sign-up-controls": {
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            "& p": {
                fontSize: "1.2rem",
                marginBottom: "1rem"
            },
            "& .bl-attribution": {
                fontSize: "1rem",
                marginBottom: "1rem",
                "& img": {
                    height: "20px",
                    marginLeft: "0.5rem"
                }
            },
            "& .sub-attribution": {
                marginTop: "1rem",
                fontSize: "1rem",
                "& a": {
                    color: "#1a5dbd",
                    textDecoration: "none",
                    "&:hover": {
                        textDecoration: "underline"
                    }
                }
            },
        }
    },

}));

export const InvitationIndex = () => {
    const { user, loginWithRedirect } = useAuth0();
    const { invitation, loading } = useOutletContext<{ invitation: any, loading: boolean }>();
    const { trackEvent } = useAnalytics();

    const navigate = useNavigate();

    const {
        isLoading,
        isAuthenticated
    } = useAuth0();

    useEffect(() => {
        if (!loading && !isLoading && isAuthenticated) {
            return navigate(`/invitation/${invitation?.invitation_code}/review`);
        }
    }, [isLoading, isAuthenticated, loading, invitation])

    useEffect(() => {
        if (!loading && invitation) {
            trackEvent("Viewed invitation", { organization: invitation?.organization?.name, project: invitation?.project?.name, invitation_code: invitation.invitation_code, invitee_email: invitation.invitee_email });
        }
    }, [loading, invitation, user]);

    const handleSignUp = async () => {
        localStorage.setItem("invcode", invitation?.invitation_code);
        await loginWithRedirect({
            appState: {
                returnTo: `/invitation/${invitation?.invitation_code}/accept`,
            },
            authorizationParams: {
                prompt: "login",
                screen_hint: "signup",
                login_hint: invitation?.invitee_email
            },
        });
    };

    const handleSignIn = async () => {
        localStorage.setItem("invcode", invitation.invitation_code);
        await loginWithRedirect({
            appState: {
                returnTo: `/invitation/${invitation.invitation_code}/accept`,
            },
            authorizationParams: {
                prompt: "login",
                login_hint: invitation?.invitee_email
            },
        });
    };

    if (loading) {
        return <></>;
    }

    if (!loading && (!invitation || invitation.is_redeemed)) {
        return (
            <main className="page-main">
                <div className="gc_view">
                    <div className="text-center hero my-5">
                        <p className="invitation-text">This invitaion is no longer valid or has already been used.</p>
                    </div>
                </div>
            </main>
        )
    }

    if (invitation.invitee_role === "contributor" && invitation.project) {
        return (
            <SplashPage>
                    <img src="/images/illustrations/crowd-arrow.svg" className="background-image" />

                    <Box className="splash-content">
                        <p>
                            <Typography variant="h5" sx={{ margin: "1rem 0" }}>{invitation.source_user.name} invited you to start generating insights and opportunities for</Typography>
                            {invitation.project?.logo_url ? <img className="logo-image" src={invitation.project?.logo_url} /> : invitation.project?.company?.logo_url ? <img className="logo-image" src={invitation.project?.company?.logo_url} /> : <Typography variant="h5">{invitation.project?.name}</Typography>}

                        </p>
                        <Box className="sign-up-controls">
                        <p className="bl-attribution">Sign up with <img src={logo as unknown as string} /> to get started.</p>
                        <Button
                            id="qsSignupBtn"
                            color="primary"
                            onClick={handleSignUp}>
                            Sign Up
                        </Button>
                        <p className="sub-attribution">Alread have an account? <a href="#" onClick={handleSignIn}>Log In</a></p>
                        </Box>
                    </Box>
            </SplashPage>
        )
    } else {
        return (
            <main className="page-main">
                <div className="gc_view">
                    <div className="text-center hero my-5">

                        {invitation.project ?

                            invitation.invitee_role === "contributor" ?

                                <p className="invitation-intro">
                                    Ready to collaborate on<br />
                                    <span className="invitation-organization">{invitation.project.name}</span><br />
                                    insights and opportunities with the<br />
                                    <span className="invitation-organization">{invitation.organization.name}</span> team?<br />
                                </p>
                                :
                                <p className="invitation-intro">
                                    You've been invited to review the viewpoints<br />
                                    <span className="invitation-organization">{invitation.organization.name}</span> has collected for<br />
                                    <span className="invitation-organization">{invitation.project.name}</span><br />
                                </p>
                            :
                            <p className="invitation-intro">
                                Ready to collaborate with the<br />
                                <span className="invitation-organization">{invitation.organization.name}</span><br />
                                team on BrightLoop?
                            </p>
                        }
                        <p className="invitation-text invitation-bl-attribution">Sign up with <img src={logo as unknown as string} /> to get started.</p>
                        <Button
                            id="qsSignupBtn"
                            color="secondary"
                            className="invitation-button"
                            onClick={handleSignUp}>
                            Sign Up
                        </Button>
                        <p className="invitation-text">Alread have an account? <a href="#" onClick={handleSignIn}>Log In</a></p>
                    </div>
                </div>
            </main>
        );
    }

};
