import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getSurveyLinks } from "../services/gutcheck-admin-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import useAdminGetProjectData from "../hooks/useAdminGetProjectData";
import { Button } from "reactstrap";

export const AdminSurveyLinks = () => {

    const { projectId } = useParams();
    const [surveyLinks, setSurveyLinks] = useState([] as any);
    const [loading, setLoading] = useState(true);
    const { getAccessTokenSilently } = useAuth0();

    const { fetched, organization, project } = useAdminGetProjectData(parseInt(projectId ?? ""));

    useEffect(() => {

        const loadSurveyLinks = async () => {
            if (projectId) {
                const accessToken = await getAccessTokenSilently();
                const pid = parseInt(projectId);
                const { data, error } = await getSurveyLinks(pid, accessToken);

                if (data) {
                    setSurveyLinks(data.surveyLinks);
                    setLoading(false);
                }
            }
        }


        if (projectId) {
            loadSurveyLinks();
        }

    }, []);

    if (loading || !surveyLinks) return (<></>);


    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <h5>Survey Links</h5>
                <Button color="primary" tag={Link} to={`/admin/project/${projectId}/surveyLink/new`}>Create Survey Link</Button>
            </div>

            <Table>
                <TableHead>
                    <TableCell>Id</TableCell>
                    <TableCell>Link Name</TableCell>
                    <TableCell>Partner</TableCell>
                    <TableCell>Survey URL</TableCell>
                    <TableCell>Actions</TableCell>
                </TableHead>
                <TableBody>
                    {surveyLinks.map((surveyLink: any) => {
                        return (
                            <TableRow key={surveyLink.id}>
                                <TableCell>{surveyLink.id}</TableCell>
                                <TableCell>{surveyLink.name}</TableCell>
                                <TableCell>{surveyLink.partner_name}</TableCell>
                                <TableCell>{window.location.origin}/e/{surveyLink.public_id}</TableCell>
                                <TableCell>
                                    <Link to={`/admin/project/${projectId}/surveyLink/${surveyLink.id}`}>Edit</Link>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}