import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import ClientProvider from "..//modules/ClientProvider";

type ProjectInvitationsData = {
    fetched: boolean;
    invitations: any;
    error: { message: any } | null;
}

const initState: ProjectInvitationsData = {
    fetched: false,
    invitations: [],
    error: null
}

export const useGetProjectInvitations = (projectId: number) => {
    const { getAccessTokenSilently } = useAuth0();
    const projectClient = ClientProvider.provideProjectClient();

    const [state, setState] = useState(initState);

    const reloadProjectInvitations = async () => {
        setState({
            ...state,
            fetched: false
        });

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.getProjectInvitations(projectId, accessToken);

        if (data) {
            const fetchedProjectInvitations: ProjectInvitationsData = {
                fetched: true,
                invitations: data.invitations ?? [],
                error: error
            };
            setState(fetchedProjectInvitations);

        } else {
            console.error(error);
        }
    }

    useEffect(() => {
        let isMounted = true;

        const fetchProjectInvitations = async () => {
            if (!isMounted) return;
            reloadProjectInvitations();
        };

        fetchProjectInvitations();

        return () => { isMounted = false };
    }, []);

    return { ...state, reloadProjectInvitations };
};
