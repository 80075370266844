import React, { useEffect, useState } from 'react';
import { getOrganizations, addUser } from '../services/gutcheck-admin-api.service';
import { useAuth0 } from "@auth0/auth0-react";
import Select from 'react-select'

type TAddUserForm = {
  parentCallback: any
};

type TUserForm = {
  email: string,
  role: any,
  organizations: any | null
}

const initState: TUserForm = {
  email: '',
  role: '',
  organizations: null
}

const AddUserForm = ({ parentCallback }: TAddUserForm) => {

  const [organizations, setOrganizations] = useState();
  const [form, setForm] = useState(initState);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getOrganizations(accessToken);

      if (data ) {
        setOrganizations(data.organizations.map( (organization: any) => {
          return { value: organization.id, label: organization.name };
        }));
      }
    };

    fetchData();

    return () => {
    }
  }, []);


  const handleChange = (event: any) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };

  const handleRoleChange = (event: any) => {
    setForm({
      ...form,
      role: event
    });

  };

  const handleOrganizationsChange = (event: any) => {
    setForm({
      ...form,
      organizations: event
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const accessToken = await getAccessTokenSilently();
    const user = {
      email_address: form.email,
      role: form.role.value,
      organizations: form.organizations.map( (organization: any) => { return organization.value; })
    }
    const { data, error } = await addUser(user, accessToken);
    if(!error) {
      setForm({
        email: '',
        role: '',
        organizations: null
      });
      parentCallback( data.user, `${data.user.email_address} added successfully.` );

    } else {
      parentCallback( null, "There was an error. User may already exist.");
    }




  };

  const userRoles = [
    { value: "user", label: "user" },
    { value: "admin", label: "admin" }
  ];

  return (
    <form onSubmit={handleSubmit}>
      <div className="add-field textfield-wrapper">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="text"
          value={form.email}
          onChange={handleChange}
        />
      </div>
      <div className="add-field role-wrapper">
        <label htmlFor="roles">Role</label>
        <Select
          inputId="role"
          name="role"
          // inputProps={{ id: "role" }}
          options={userRoles}
          value={form.role}
          onChange={handleRoleChange}
        />
      </div>
      <div className="add-field organizations-wrapper">
        <label htmlFor="organizations">Organizations</label>
        <Select
          isMulti
          className="basic-multi-select"
          classNamePrefix="select"
          name="organizations"
          // inputProps={{ id: "organizations" }}
          // autoSize={false}
          options={organizations}
          value={form.organizations}
          onChange={handleOrganizationsChange}
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export { AddUserForm };