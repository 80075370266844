import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import StyledDropzone from "../../../components/AssetUpload";
import TextEditor from "../../../components/TextEditor";

type TForm = {
    form: any
};

type TProjectForm = TForm & {
    setForm: any
};

type TProjectFormTypes = TProjectForm & {
    setActiveInput: any,
    userType: any
}

type TProjectFormSection = TProjectForm & {
    activeInput: any
}

type TInvestorProjectForm = TProjectForm & {
    queuedFiles: any,
    setQueuedFiles: any,
    activeInput: any,
    setActiveInput: any,
    handleChangeInput: any,
    userType: any
}

type TProjectHeaderForm = TProjectForm & {
    isDisabled?: boolean
};

type TProjectSideForm = TForm & {
    handleStart: any,
    handleSave: any
};

type TProjectButton = {
    text: string,
    action: any,
    type: string
}

const _feedbackTypes = [
    {
        id: "prospects",
        name: "Prospects",
        description: "Get feedback from potential buyers and users to understand demand, differentiation and value"
    },
    {
        id: "customers",
        name: "Customers",
        description: "Learn how customers discovered the product, the alternatives they considered, how they are using the product today and expect to use it tomorrow."
    },
    {
        id: "experts",
        name: "Experts",
        description: "Get structured feedback and answers to key question from contacts and colleagues in your network."
    }
];

const _contentTypes = [
    { value: "Product description", title: "Product Description", subtitle: "Text, Word, Google Docs, etc.", description: "A multi paragraph description of a product, how it works, and what its benefits are.", id: 1, types: ['investor', 'entrepreneur'] },
    { value: "Marketing message", title: "Marketing Message", subtitle: "Text, Word, Google Docs, etc.", description: "Copy used in text based promotional materials or embedded in mutlmedia marketing assets", id: 3, types: ['marketer'] },
    { value: "Sales deck", title: "Sales Deck", subtitle: "PDF, PPT, Google Slides, etc.", description: "A multi modal presentation shared with prospect customers or investors", id: 4, types: ['investor', 'entrepreneur', 'marketer'] },
    { value: "Sales or Product demo", title: "Sales or Product Demo", subtitle: "YouTube, Vimeo, .mov, .mp4, etc.", description: "A video that describes your product in detail", id: 5, types: ['investor', 'entrepreneur', 'marketer'] },
];

const _goalTypes = [
    { value: "Demand", title: "Maximize Demand", subtitle: "Maximize the percentage of buyers who would purchase or use the product", id: 0, default: true },
    { value: "Differentiation", title: "Improve Differentiation", subtitle: "Maximize the percentage of buyers who see the product as unique vs. other products", id: 1, default: true },
    { value: "Perceived Value", title: "Increase Perceived Value", subtitle: "Increase the price that buyers will pay for the product", id: 2, default: true },
    { value: "Comprehension", title: "Increase Comprehension", subtitle: "Improve how quickly buyers understand what the product does", id: 3, default: false },
    { value: "Objections", title: "Minimize Objections", subtitle: "Identify and address reasons buyers might not purchase or use the product", id: 4, default: false },
    { value: "Positive Sentiment", title: "Increase Positive Sentiment", subtitle: "Increase the positive feelings and associations around the product", id: 5, default: false },
    { value: "Negative Sentiment", title: "Decrease Negative Sentiment", subtitle: "Identify and minimize negative  associations with the product", id: 6, default: false },
    { value: "Potential Virality", title: "Increase Potential Virality", subtitle: "Increase the likelihood a buyer will tell a friend or colleague about the product", id: 7, default: false },
    { value: "Action", title: "Improve Call to Action", subtitle: "Increase the likelihood that a buyer will sign up, download, purchase the product", id: 8 }
];

const _handleRadioClick = async (event: any) => {
    const typeDiv = event.currentTarget;
    typeDiv.querySelector("input[type=radio]").click();
};

const _stopPropagation = async (event: any) => {
    event.stopPropagation();
};

const handleTypeChange = (event: any, form: any, setForm: any, setActiveInput: any) => {
    setForm({
        ...form,
        type: event.target.value
    });
    setActiveInput("asset_file_id");
};

const handleChange = (event: any, form: any, setForm: any) => {
    setForm({
        ...form,
        [event.target.id]: event.target.value,
    });
};

const handleTextChange = (event: any, id: any, form: any, setForm: any) => {
    setForm({
        ...form,
        [id]: event
    })
}

const handleTooltipHover = (event: any, section: any, form: any, setForm: any) => {
    event.preventDefault();
    setForm({
        ...form,
        focus_section: section
    });
}

const handleFeedbackChange = (event: any, form: any, setForm: any) => {
    if (event.currentTarget.id === "prospects") {
        setForm({
            ...form,
            feedback_type: event.currentTarget.id
        });
    }
}

const handleGoalChange = (event: any, form: any, setForm: any) => {
    const enabledGoals = _goalTypes.map((goal: any) => {
        if (goal.default) return goal.value;
    })

    const currentGoals = form.goals;
    const selectedGoal = event.currentTarget.id;

    if (enabledGoals.includes(selectedGoal)) {
        if (currentGoals.includes(selectedGoal)) {
            currentGoals.splice(currentGoals.indexOf(selectedGoal), 1);
        } else {
            currentGoals.push(selectedGoal);
        }
        setForm({
            ...form,
            goals: currentGoals
        })
    }
}

const handleTiptapChange = (e: any, id: any, form: any, setForm: any) => {
    setForm({
        ...form,
        [id]: e.editor.getHTML()
    })
}

const FeedbackTypeSelect = ({ form, setForm }: TProjectForm) => {
    return (
        <div className={`investor-form-section ${form.name === "" && "hidden"}`}>
            <div className="investor-form-control" onMouseEnter={e => handleTooltipHover(e, "feedback_type", form, setForm)}>

                <div className="feedback-type-form">
                    <h4>What feedback loop do you want to kick off?</h4>
                    <div className="feedback-type-option-wrapper">
                        {_feedbackTypes.map((feedbackType) => {
                            return (
                                <div
                                    className={feedbackType.id === "prospects" ? `feedback-type ${form.feedback_type === "prospects" && "selected"}` : "feedback-type disabled"}
                                    id={feedbackType.id}
                                    onClick={e => handleFeedbackChange(e, form, setForm)}
                                    key={`feedback-type-${feedbackType.id}`}
                                >
                                    <h3>{feedbackType.name}</h3>
                                    <p>{feedbackType.description}</p>
                                    {(feedbackType.id !== "prospects") && (
                                        <div className="coming-soon">Coming Soon</div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={`investor-form-tips ${form.focus_section !== "feedback_type" ? "hidden" : ""}`}>
                <div className="tips-content">

                </div>
            </div>
        </div>
    )
}

const GoalTypeSelect = ({ form, setForm }: TProjectForm) => {
    return (
        <div className={`investor-form-section ${form.name === "" && "hidden"}`}>
            <div className="investor-form-control" onMouseEnter={e => handleTooltipHover(e, "feedback_type", form, setForm)}>

                <div className="goal-type-form">
                    <h4>Which goals do you want to optimize for?</h4>
                    <div className="goal-type-option-wrapper">
                        {_goalTypes.map((goalType) => {
                            return (
                                <div
                                    className={goalType.default ? `goal-type ${form.goals.includes(goalType.value) && "selected"}` : "goal-type disabled"}
                                    id={goalType.value}
                                    onClick={e => handleGoalChange(e, form, setForm)}
                                    key={`goal-type-${goalType.value}`}
                                >
                                    <h3>{goalType.title}</h3>
                                    <p>{goalType.subtitle}</p>
                                    {(!goalType.default &&
                                        <div className="coming-soon">Coming Soon</div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={`investor-form-tips ${form.focus_section !== "feedback_type" ? "hidden" : ""}`}>
                <div className="tips-content">

                </div>
            </div>
        </div>
    )
}

const AssetTypeCheckboxes = ({ userType, form, setForm, setActiveInput }: TProjectFormTypes) => {
    const options = _contentTypes.map(type => {

        if (!type.types.includes(userType)) return;
        const className = "splash-input " + (form.type === type.value ? "selected" : form.type !== "" ? "deselected" : "")
        return (
            <div className={className} onClick={_handleRadioClick} key={type.value.replace(/ /g, "-")}>
                <FormControlLabel label={<></>} control={<Radio checked={form.type === type.value} />} value={type.value} className="splash-check" onClick={_stopPropagation} />
                <div className="splash-checkbox-content">
                    <p className="bold">{type.title}</p>
                    <p className="subtitle">{type.subtitle}</p>
                </div>
            </div>
        );
    });

    return (
        <div className="project-form-section light two-part">
            <FormControl className="width-full">
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    className="asset-type-radio-group"
                    onChange={e => handleTypeChange(e, form, setForm, setActiveInput)}
                // required
                >
                    {options}
                </RadioGroup>
            </FormControl>
        </div>

    );
};

export const CreateInvestorProjectHero = ({ userType }: any) => {
    if (userType === "investor") {
        return (
            <div className="splash-hero">
                <div className="hero-title">More successful startups.</div>
                <div className="hero-text">Tap experts and executives to vet deals, win deals, and get better returns.</div>
            </div>

        );
    } else {
        return (
            <div className="splash-hero">
                <div className="hero-title">Make Everything Better.</div>
                <div className="hero-text">BrightLoop combines generative AI and customer feedback to help investors and business teams test and improve products, concepts, content, and copy - speeding time to market and product market fit.</div>
            </div>
        );
    }
}

export const CreateInvestorProjectHeader = ({ form, setForm, isDisabled = false }: TProjectHeaderForm) => {
    return (
        <div className="splash-header">
            <div className="oboarding-form-input">
                <input
                    id="name"
                    type="text"
                    value={form.name ?? ''}
                    onChange={e => handleChange(e, form, setForm)}
                    placeholder="What company are you evaluating?"
                    disabled={isDisabled}
                />
            </div>
            <div className="separator"></div>
        </div>
    );
};

export const CreateInvestorSideForm = ({ form, handleSave, handleStart }: TProjectSideForm) => {
    return (
        <div className="splash-side">
            <CreateProjectButton text={"Save Project"} action={handleSave} type={"secondary"} />
            <CreateProjectButton text={"Start Project"} action={handleStart} type={"primary"} />
            <div className="form-error">{form.error_message}</div>
        </div>
    );
};

const CreateProjectButton = ({ text, action, type = "secondary" }: TProjectButton) => {
    return (
        <div className={`side-button button ${type}`} onClick={action}>
            <p className="bold">{text}</p>
        </div>
    );
};


const AssetUpload = ({ form, setForm, queuedFiles, setQueuedFiles, activeInput, handleChangeInput }: TInvestorProjectForm) => {

    const pdfTypes = {
        'application/pdf': []
    };

    const videoTypes = {
        'video/*': []
    };

    const previousFile = form.asset_file_id ?? "";

    if (queuedFiles[0] && queuedFiles[0].name !== "" && form.asset_file_id === "") {
        setForm({
            ...form,
            asset_file_id: queuedFiles[0]?.name
        });
    }

    return (
        <div className={activeInput === "asset_file_id" ? "project-form-section" : "project-form-section hidden"}>
            <StyledDropzone fileTypes={(form.type === "Sales or Product demo") ? videoTypes : pdfTypes} queuedFiles={queuedFiles} setQueuedFiles={setQueuedFiles} initialFile={previousFile} />
        </div>
    )

};

const AssetUrl = ({ form, setForm, activeInput }: TProjectFormSection) => {
    if (activeInput === "asset_url") return (
        <div className="project-form-section">
            <div className="oboarding-form-input">

                <input
                    type="text"
                    id="asset_url"
                    value={form.asset_url ? form.asset_url : ""}
                    onChange={e => handleChange(e, form, setForm)}
                    placeholder="Type or paste a publicly accessible URL"
                />
            </div>
        </div>
    )

};

const AssetText = ({ form, setForm, activeInput }: TProjectFormSection) => {

    if (activeInput === "asset_text") return (
        <div className="project-form-section">
            <div className="asset-editor">
                <div className="oboarding-form-input">
                    {form.name != "" &&
                        <TextEditor content={form.asset_text} id="asset_text" form={form} setForm={setForm} />
                    }
                </div>
            </div>
        </div>
    )
};


const TargetAudience = ({ form, setForm }: TProjectForm) => {
    return (
        <div className={`investor-form-section ${form.asset_text === "" && form.asset_url === "" && form.asset_file_id === "" && "hidden"}`}>
            <div className="investor-form-control" onMouseEnter={e => handleTooltipHover(e, "target_audience", form, setForm)}>
                <h4 className="bold">Describe the ICP or target audience</h4>
                <p>Who are you building your business for?</p>
                <div className="project-form-section">

                    <div className="oboarding-form-input">
                        {form.name != "" &&
                            <TextEditor content={form.target_audience} id="target_audience" form={form} setForm={setForm} />
                        }
                    </div>
                </div>
            </div>
            <div className={`investor-form-tips ${form.focus_section !== "target_audience" ? "hidden" : ""}`}>
                <div className="tips-content">
                    <p><span className="bold">B2B Example</span><br />
                        VP Sales and VP Marketing at midsize companies (500 - 2500 employees) in the United States in Advertising, Media and Telecom verticals
                    </p>
                    <p><span className="bold">B2C Example</span><br />
                        U.S. women age 30-49 in households earning over $100,000 per year
                    </p>
                </div>
            </div>
        </div>

    )
}

const KeyQuestions = ({ form, setForm }: TProjectForm) => {
    return (
        <div className={`investor-form-section ${form.target_audience === "" && "hidden"}`}>
            <div className="investor-form-control" onMouseEnter={e => handleTooltipHover(e, "key_questions", form, setForm)}>
                <h4 className="bold">What do you want to learn?</h4>
                <p className="subtitle">List the key questions you want answered.</p>
                <div className="project-form-section">

                    <div className="oboarding-form-input">
                        {form.name != "" &&
                            <TextEditor content={form.key_questions} id="key_questions" form={form} setForm={setForm} />
                        }
                    </div>
                </div>
            </div>
            <div className={`investor-form-tips ${form.focus_section !== "key_questions" ? "hidden" : ""}`}>
                <div className="tips-content">
                    <p><span className="bold">Key Questions</span><br />
                        Many key questions are covered by default, so no need to repeat them. The default interview will ask about and measure:</p>
                    <ul>
                        <li>Overall demand and what drives demand</li>
                        <li>Key features and value propositions</li>
                        <li>Perceived value, price points and pricing models</li>
                        <li>Competitive products and competitive advantages</li>
                    </ul>
                    <p>If you have hypotheses about different business or individual characteristics that might result in higher demand, it would be helpful to list them. In B2B or B2C settings, you might want to learn:  </p>
                    <p><span className="bold">B2B Examples</span></p>
                    <ul>
                        <li>Are buyers that have outsourced their sales development function more likely to purchase?</li>
                        <li>Are buyers that use a SaaS based CRM product more likely to purchase?</li>
                    </ul>
                    <p><span className="bold">B2C Example</span></p>
                    <ul>
                        <li>Does frequent usage of social media increase demand for the product?</li>
                        <li>Are iPhone users more likely to subscribe than Android users?</li>
                    </ul>

                </div>
            </div>
        </div>
    )
}

export const CreateButtonForm = ({ form, handleSave, handleStart }: TProjectSideForm) => {
    const isDisabled = form.name === "" || (form.feedback_type === "" && form.goals.length === 0) || form.asset_type === "" || form.target_audience === "";
    return (
        <div className="investor-form-section">
            <div className="button-row">
                <CreateProjectButton text={"Save Project"} action={handleSave} type={"secondary"} />
                <CreateProjectButton text={"Start Project"} action={!isDisabled ? handleStart : null} type={`primary ${isDisabled ? "disabled" : ""}`} />
                <div className="form-error">{form.error_message}</div>
            </div>
        </div>
    );
};

export const AssetType = ({ userType, form, setForm, queuedFiles, setQueuedFiles, activeInput, setActiveInput, handleChangeInput }: TInvestorProjectForm) => {
    return (
        <div className={`investor-form-section ${(form.feedback_type === "" && form.goals.length == 0) && "hidden"}`}>
            <div className="investor-form-control" onMouseEnter={e => handleTooltipHover(e, "asset_type", form, setForm)}>
                <div className="">
                    <h4>What assets do you want to show prospects?</h4>
                    <p>Upload or link to whatever you have.</p>
                    <div className="asset-controls">

                        <div className="asset-control">

                            <AssetTypeCheckboxes userType={userType} form={form} setForm={setForm} setActiveInput={setActiveInput} />
                        </div>
                        <div className="asset-control">
                            <div className={form.type === "" ? "hidden" : ""}>
                                <h5 className="input-toggles">
                                    <span className={(activeInput === "asset_file_id") ? "active" : "inactive"} onClick={e => handleChangeInput(e, "asset_file_id")}>Upload a file</span>
                                    <span className={(form.type === "Product description" || form.type === "Marketing message") ? (activeInput === "asset_text") ? "active" : "inactive" : "hidden"} onClick={e => handleChangeInput(e, "asset_text")}>Enter your content</span>
                                    <span className={(form.type !== "Product description" && form.type !== "Marketing message") ? (activeInput === "asset_url") ? "active" : "inactive" : "hidden"} onClick={e => handleChangeInput(e, "asset_url")}>Provide a link</span>
                                </h5>

                                <AssetUpload userType={userType} form={form} setForm={setForm} queuedFiles={queuedFiles} setQueuedFiles={setQueuedFiles} activeInput={activeInput} setActiveInput={setActiveInput} handleChangeInput={handleChangeInput} />
                                <AssetUrl form={form} setForm={setForm} activeInput={activeInput} />
                                <AssetText form={form} setForm={setForm} activeInput={activeInput} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`investor-form-tips ${form.focus_section !== "asset_type" ? "hidden" : ""}`}>
                {form.type === "Product description" ?
                    <div className="tips-content">
                        <p><span className="bold">Format & Instructions</span></p>
                        <ul>
                            <li>Simple product description</li>
                            <li>Start with user value prop</li>
                            <li>Focus on user behavior</li>
                            <li>Minimize aspirational fluff</li>
                            <li>Include objection handling if you already know some objections</li>
                        </ul>
                        <p><span className="bold">Example</span><br />
                            Suppose there was an app on your phone where you could quickly order a ride, taxi or limo to take you from exactly where you were to your destination.</p>
                        <p>When you launched the app, it would use the GPS on your phone to locate where you were and show you your location on a map. The map would also show you the drivers that were nearby and available to pick you up. You would be able to enter your destination on the phone, and the app would calculate the fare. Once you approved it, the closest driver to accept the fare would pick you up and drop you off. </p>
                        <p>All drivers would be approved by the service, and you could elect to choose only licensed drivers like limos and taxis, or an everyday driver. You'd have the ability to rate each driver so only the best drivers were able to drive passengers. </p>
                        <p>Once you reached your destination, you'd simply get out of the car. Your credit card would be billed via the app and distributed to your driver offline. </p>
                    </div> : <></>
                }
            </div>
        </div>
    )
}

export const CreateInvestorForm = ({ userType, form, setForm, queuedFiles, setQueuedFiles, activeInput, setActiveInput, handleChangeInput }: TInvestorProjectForm) => {
    return (
        <>
            {userType === "marketer" ?
                <GoalTypeSelect form={form} setForm={setForm} />
                :
                <FeedbackTypeSelect form={form} setForm={setForm} />
            }
            <AssetType userType={userType} form={form} setForm={setForm} queuedFiles={queuedFiles} setQueuedFiles={setQueuedFiles} activeInput={activeInput} setActiveInput={setActiveInput} handleChangeInput={handleChangeInput} />
            <TargetAudience form={form} setForm={setForm} />
            <KeyQuestions form={form} setForm={setForm} />
        </>
    );
};