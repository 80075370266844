import { useAuth0 } from "@auth0/auth0-react";
import Project from "../../../features/projects/types/Project";
import { useDealUpdates } from "../hooks/useDealUpdates";
import { createDealUpdate, updateDealUpdate } from "../services/gutcheck-admin-api.service";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Modal, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { DealUpdateForm } from "./components/DealUpdateForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DealUpdateNotification } from "./components/DealUpdateNotification";
import { faClock as faClockRegular } from '@fortawesome/free-regular-svg-icons/faClock';
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/free-regular-svg-icons/faEnvelope';

export const AdminDealUpdates = () => {

    const { project } = useOutletContext<any>();
    const { fetched, dealUpdates, error, reloadDealUpdates } = useDealUpdates(project.id ?? "");
    const [ dealUpdate, setDealUpdate ] = useState(null);
    const [ editMode, setEditMode ] = useState(false);
    const [ sendMode, setSendMode ] = useState(false);


    const handleEdit = (dealUpdate: any) => {
        setDealUpdate(dealUpdate);
        setEditMode(true);
    }

    const handleSend = (dealUpdate: any) => {
        setDealUpdate(dealUpdate);
        setSendMode(true);
    }

    if (!fetched) { return <></>; }

    let systemNotificationCount = 0;

    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <h5>Deal Updates</h5>
                <div className="btn btn-primary" onClick={handleEdit}>Add Deal Update</div>
            </div>

            <Table className="deal-updates-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Posted Date</TableCell>
                         <TableCell>Body</TableCell>
                        <TableCell className="center">Published?</TableCell>
                        <TableCell>Last Sent</TableCell>
                        <TableCell className="admin-action">Send</TableCell>
                        <TableCell className="admin-action">Edit</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {dealUpdates.map((dealUpdate: any) => (
                        <TableRow key={dealUpdate.uuid}>
                            <TableCell>{dealUpdate.id}</TableCell>
                            <TableCell>{dealUpdate.published_at && dealUpdate.published_at !== "" ? (new Date(dealUpdate.published_at).toLocaleString()) : "--"}</TableCell>
                            <TableCell className={dealUpdate.is_published ? "published" : "not-published"}><p className="bold">{dealUpdate.title}</p>
                            <p>{dealUpdate.body}</p></TableCell>
                            <TableCell className="center">{dealUpdate.is_published ? "Yes" : "No"}</TableCell>
                            <TableCell>{dealUpdate.last_sent_at && dealUpdate.last_sent_at !== "" ? (new Date(dealUpdate.last_sent_at).toLocaleString()) : "--"}</TableCell>
                            { dealUpdate.type !== "DEAL_GREENLIT" && dealUpdate.type !== "SPV_OPENED" ?
                                <TableCell className="admin-action link" onClick={() => handleSend(dealUpdate)}><FontAwesomeIcon icon={faEnvelopeRegular}/></TableCell>
                                : !systemNotificationCount++ ?
                                <TableCell className="admin-action disabled"><Tooltip title="Sent daily to new experts"><FontAwesomeIcon icon={faClockRegular}/></Tooltip></TableCell>
                                :
                                <TableCell className="admin-action disabled"><Tooltip title="No longer valid to send"><span>--</span></Tooltip></TableCell>
                            }
                            <TableCell className="admin-action link" onClick={() => handleEdit(dealUpdate)}><FontAwesomeIcon icon="edit"/></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            { editMode &&
                <DealUpdateForm project={project} dealUpdate={dealUpdate} reloadDealUpdates={reloadDealUpdates} editMode={editMode} setEditMode={setEditMode}/>
            }
            { sendMode &&
                <DealUpdateNotification project={project} dealUpdate={dealUpdate} reloadDealUpdates={reloadDealUpdates} sendMode={sendMode} setSendMode={setSendMode}/>
            }
        </div>
    );
}