import { useState, Fragment, useEffect } from "react";
import { NavLink as RouterNavLink, useLocation, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoginButton } from "./buttons/login-button";
import { SignupButton } from "./buttons/signup-button";
import logo from "../assets/images/brightloop-logo-web.svg"
import { UserProfileImage, useUserContext } from "../features/users";

import {
    Collapse,
    Container,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import { useAuth0, User } from "@auth0/auth0-react";
import { getInvitation } from "../core/services/gutcheck-api.service";
import { useDealContext } from "../features/deal";
import { Box, Typography } from "@mui/material";

type NavState = {
    fetched: boolean,
    user: any,
    error: any,
    ok: any,
    currentOrg: any,
    currentOrgTag: string,
    currentProject: any,
    currentProjectTag: string,
    userRole: string
};

const initState: NavState = {
    fetched: false,
    user: null,
    error: null,
    ok: null,
    currentOrg: null,
    currentOrgTag: "",
    currentProject: null,
    currentProjectTag: "",
    userRole: ""
};

const DisplayLogo = ({ state, isAuthenticated, hideUser }: any) => {
    const organization = state.currentOrg;

    const logoUrl = organization?.logo_uploaded ? `https://[someurl]` : organization?.logo_url;
    const link = state.currentOrg?.userRole === "guest" ? `/o/${organization?.tag}/project/${organization?.tag}` : `/o/${organization?.tag}`;

    if (hideUser) {
        return (
            <Nav className="mr-auto " navbar>
                <NavItem key="1">
                    <img className="logo-img" src={logoUrl && logoUrl != "" ? logoUrl : logo} />
                </NavItem>
            </Nav>
        );
    }

    return (
        <Box sx={{
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
            gap: "10px"
        }}>
            <Nav className="mr-auto " navbar>
                <NavItem key="1">
                    <NavLink
                        tag={RouterNavLink}
                        to={isAuthenticated ? link : "/"}>
                        <img className="logo-img" src={logoUrl && logoUrl != "" ? logoUrl : logo} />
                    </NavLink>
                </NavItem>
            </Nav>
            <DisplayCompanyLogo state={state} isAuthenticated={isAuthenticated} hideUser={hideUser} />
        </Box>
    )
}

const DisplayCompanyLogo = ({ state, isAuthenticated, hideUser }: any) => {

    const { dealContext, setDealContext } = useDealContext();
    const projectTagMatcher = new RegExp(`${dealContext.project?.tag}`);
    if (projectTagMatcher.test(window.location.pathname)) {
        const companyLogo = dealContext.project?.company?.logo_url && dealContext.project?.company?.logo_url !== "" ? dealContext.project?.company?.logo_url :
            dealContext.project?.logo_url && dealContext.project?.logo_url !== "" ? dealContext.project?.logo_url :
                null;

        if (companyLogo) {
            return (
                <Nav className="mr-auto " navbar>
                    <NavItem key="1">
                        <Box sx={{ display: "flex", flexDirection: "row", gap: "20px", height: "100%", alignItems: "center" }}>
                        <Typography >for</Typography>
                        <Box sx={{ height: "100%"}}><img className="logo-img" src={companyLogo} height="100%"/></Box>
                    </Box>
                    </NavItem>
                </Nav>
            );
        } else {
            return (<Nav className="mr-auto " navbar>
                <NavItem key="1">
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", height: "100%", alignItems: "center" }}>
                        <Typography >for</Typography>
                        <Typography sx={{ fontWeight: 700, fontSize: "1.5rem"}}>{dealContext.project.name}</Typography>
                    </Box>
                </NavItem>
            </Nav>
            );
        }
    }

    return <></>;
}

const NavBar = ({ hideUser = false }) => {
    const [isOpen, setIsOpen] = useState(false);

    const [state, setState] = useState(initState);

    const [searchParams, setSearchParams] = useSearchParams();

    const { userContext, setUserContext } = useUserContext();
    const { dealContext, setDealContext } = useDealContext();

    const {
        user,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout,
    } = useAuth0();
    const toggle = () => setIsOpen(!isOpen);

    const location = useLocation();

    const forceRefresh = searchParams.has("refresh");

    useEffect(() => {
        let isMounted = true;

        // Grab organization tag from URL if it's there
        const newOrganizationTag = location.pathname.indexOf("/o/") === 0 ? location.pathname.replace(/^\/o\//, "").replace(/\/.*/, "") : "";
        const newProjectTag = location.pathname.indexOf("/project/") > 0 ? location.pathname.replace(/^.*\/project\//, "").replace(/\?.*/, "") : "";

        const fetchProjectData = async () => {

            if (!isMounted) {
                return;
            }

            if (userContext.dbUser) {
                const prevOrganization = state.currentOrg;
                const prevProject = state.currentProject;
                let currentOrg = null;
                let currentProject = null;
                let userRole = "";

                if (userContext.organizationMemberships?.length > 0) {
                    const currentOrganizationTag = newOrganizationTag ? newOrganizationTag : prevOrganization?.tag ? prevOrganization?.tag : userContext.organizationMemberships[0].organization.tag;

                    currentOrg = userContext.organizationMemberships?.find((organizationMembership: any) => organizationMembership.organization?.tag === currentOrganizationTag)?.organization;
                    userRole = "contributor";
                } else if (userContext.projectMemberships?.length > 0) {
                    const currentProjectTag = newProjectTag ? newProjectTag : prevProject?.tag ? prevProject?.tag : userContext.projectMemberships[0].project.tag;
                    currentProject = userContext.projectMemberships?.find((projectMembership: any) => projectMembership.project.tag === currentProjectTag);
                    currentOrg = currentProject?.project?.organization;
                    userRole = "guest";
                }

                if (forceRefresh) {
                    searchParams.delete("refresh");
                    setSearchParams(searchParams);
                }
                setState({
                    ...state,
                    fetched: userContext.fetched,
                    user: userContext.dbUser,
                    currentOrg: currentOrg,
                    currentOrgTag: currentOrg?.tag ?? "",
                    currentProject: currentProject,
                    currentProjectTag: currentProject?.tag ?? "",
                    userRole: userRole
                });
            } else if (userContext.fetched && !userContext.dbUser) {
                setState({
                    ...state,
                    fetched: userContext.fetched,
                    user: null,
                    currentOrg: null,
                    currentOrgTag: "",
                    currentProject: null,
                    currentProjectTag: "",
                    userRole: ""
                });
            }
        };

        const fetchInvitationData = async () => {
            const invitationCode = location.pathname.replace(/^\/invitation\//, "").replace(/\/.*/, "");
            const { data, error } = await getInvitation(invitationCode);
            if (data.invitation && data.invitation.organization) {
                const organization = data.invitation.organization;
                setState({
                    ...state,
                    fetched: true,
                    user: null,
                    error: null,
                    ok: null,
                    currentOrg: organization,
                    currentOrgTag: organization.tag
                });
            }
        }

        // If the user is authenticated...
        // We only load their data if we haven't done it yet or the new org isn't the same as the current org

        if (location.pathname.indexOf("invitation/") > 0) {
            fetchInvitationData();
        }

        if ((!isLoading && isAuthenticated) && (!state.fetched || newOrganizationTag !== state.currentOrg?.tag || searchParams.has("refresh") || forceRefresh)) {
            fetchProjectData();
        } else if (!isLoading && !isAuthenticated) {
            setState({
                ...state,
                fetched: true,
                user: null,
                error: null,
                ok: null,
                currentOrg: null,
                currentOrgTag: "",
                currentProject: null,
                currentProjectTag: "",
                userRole: ""
            });
        }

        return () => {
            isMounted = false;
        };
    }, [userContext, isAuthenticated, isLoading, location, forceRefresh]);

    if (isAuthenticated && !state.fetched) return <Fragment />

    if (location.pathname.indexOf("invitation/") > 0 && !state.fetched) return <Fragment />

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        });


    if (hideUser) {
        return (
            <div className="nav-container">
                <Navbar expand="md">
                    <DisplayLogo state={state} isAuthenticated={isAuthenticated} hideUser={hideUser} />
                </Navbar>
            </div>
        );

    }

    return (
        <div className="nav-container">
            <Navbar expand="md">
                {state.currentOrg && (
                    <div>
                        <RouterNavLink to={`/o/${state.currentOrg?.tag}`} className="nav-collapsed-company-name">{state.currentOrg?.name}</RouterNavLink>
                    </div>
                )}
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    { state.fetched && <DisplayLogo state={state} isAuthenticated={isAuthenticated} hideUser={hideUser} /> }
                    <Nav className="d-none d-md-block nav-auth" navbar>
                        {!hideUser && !isAuthenticated && !(location.pathname.indexOf("invitation/") > 0) && (
                            <Fragment>
                                <NavItem key="2">
                                    <LoginButton />
                                </NavItem>
                            </Fragment>
                        )}
                        {!hideUser && isAuthenticated && user && (
                            <UncontrolledDropdown nav inNavbar className="navbar-profile-info">
                                <div className="nav-company-name">{state.currentOrg?.name}</div>
                                <DropdownToggle nav caret id="profileDropDown">
                                    <UserProfileImage user={userContext.dbUser} />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>{user.name}</DropdownItem>
                                    {userContext && userContext.organizationMemberships.map((organizationMemberhip: any) => {
                                        const organization = organizationMemberhip.organization;
                                        return (
                                            <DropdownItem
                                                tag={RouterNavLink}
                                                to={`/o/${organization.tag}`}
                                                activeclassname="router-link-active"
                                                data={organization.tag}
                                                key={organization.tag}
                                                className="nav-dropdown-org-selector"

                                            >{organization.name}</DropdownItem>
                                        )
                                    })}
                                    <DropdownItem
                                        tag={RouterNavLink}
                                        to="/profile"
                                        className="dropdown-profile"
                                        activeclassname="router-link-exact-active"
                                    >
                                        <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                                    </DropdownItem>
                                    {state.userRole === "contributor" &&
                                        <DropdownItem
                                            tag={RouterNavLink}
                                            to={`/o/${state.currentOrgTag}/settings`}
                                            className="dropdown-settings"
                                            activeclassname="router-link-exact-active"
                                        >
                                            <FontAwesomeIcon icon="building" className="mr-3" /> Settings
                                        </DropdownItem>
                                    }
                                    {user['https://gutcheck.svpvc.com/roles']?.includes('admin') && (
                                        <DropdownItem
                                            tag={RouterNavLink}
                                            to="/admin"
                                            className="dropdown-admin"
                                            activeclassname="router-link-exact-active"
                                        >
                                            <FontAwesomeIcon icon="lock" className="mr-3" /> Admin
                                        </DropdownItem>
                                    )}
                                    <DropdownItem
                                        id="qsLogoutBtn"
                                        onClick={() => logoutWithRedirect()}
                                    >
                                        <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                                        out
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )}
                    </Nav>
                    {!hideUser && !isAuthenticated && !(location.pathname.indexOf("invitation/") > 0) && (
                        <Nav className="d-md-none" navbar>
                            <NavItem key="4">
                                <Button
                                    id="qsLoginBtn"
                                    color="primary"
                                    block
                                    onClick={() => loginWithRedirect({})}
                                >
                                    Log in
                                </Button>
                            </NavItem>
                        </Nav>
                    )}
                    {!hideUser && isAuthenticated && user && (
                        <Nav
                            className="d-md-none justify-content-between"
                            navbar
                            style={{ minHeight: 170 }}
                        >
                            <NavItem key="5">
                                <span className="user-info">
                                    <UserProfileImage user={userContext.dbUser} />
                                    <h6 className="d-inline-block">{user.name}</h6>
                                </span>
                            </NavItem>
                            <NavItem className="nav-collapsed-section-header" key="org-switch">
                                <FontAwesomeIcon icon="building" className="mr-3" />
                                <span>Switch Organizations:</span>
                            </NavItem>
                            {userContext && userContext.organizationMemberships.map((organizationMembership: any) => {
                                const organization = organizationMembership.organization;
                                return (
                                    <NavItem className="nav-collapsed-section-subitem" key={`org-switch-${organization.tag}`}>
                                        <RouterNavLink
                                            // tag={RouterNavLink}
                                            to={`/o/${organization.tag}`}
                                            // activeclassname="router-link-active"
                                            //onClick={handleOrgChange}
                                            // data={organization.tag}
                                            key={`org-switch-link-${organization.tag}`}
                                            className="nav-dropdown-org-selector"

                                        >{organization.name}
                                        </RouterNavLink>
                                    </NavItem>
                                )
                            })
                            }
                            <NavItem key="2">
                                <FontAwesomeIcon icon="user" className="mr-3" />
                                <RouterNavLink
                                    to="/profile"
                                // activeclassname="router-link-exact-active"
                                >
                                    Profile
                                </RouterNavLink>
                            </NavItem>
                            {user['https://gutcheck.svpvc.com/roles']?.includes('admin') && (
                                <NavItem key="3">
                                    <FontAwesomeIcon icon="lock" className="mr-3" />
                                    <RouterNavLink
                                        to="/admin"
                                    // activeclassname="router-link-exact-active"
                                    >
                                        Admin
                                    </RouterNavLink>
                                </NavItem>
                            )}
                            <NavItem key="4">
                                <FontAwesomeIcon icon="power-off" className="mr-3" />
                                <RouterNavLink
                                    to="#"
                                    id="qsLogoutBtn"
                                    onClick={() => logoutWithRedirect()}
                                >
                                    Log out
                                </RouterNavLink>
                            </NavItem>
                        </Nav>
                    )}
                </Collapse>
            </Navbar>
        </div>
    );
};

export default NavBar;
