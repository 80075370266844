import { Route, Routes } from "react-router-dom";
import { Profile } from "./Profile";
import { ProfileIndex } from "./profile-index";
import { ProfileComplete } from "./profile-complete";

export const UsersRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<Profile />}>
                <Route index element={<ProfileIndex />} />
                <Route path="complete" element={<ProfileComplete />} />
            </Route>
        </Routes>
    );
}