import { border, styled, width } from "@mui/system";
import CollaborativeEditor from "../../../../components/CollaborativeEditor";
import TextEditor from "../../../../components/TextEditor"
import { Box } from "@mui/material";
import { useState } from "react";

const EditorBox = styled(Box)({
    aspectRation: '25/9',
    width: '100%',
    "& .ProseMirror": {
        aspectRatio: '25/9',
        width: '100%',
        border: '1px solid #c9c9c9',
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        padding: '1rem',
    }

});

export const DealSetupICP = ({ project, user }: any) => {

    const [targetAudienceText, setTargetAudienceText] = useState<any>(project.icp || {});

    return (
        <Box>
            <p>What types of buyers do you sell to?</p>
            <EditorBox>
                <CollaborativeEditor documentName={`${project.organization.tag}/${project.tag}/target_audience`} content={project.iterations[0].target_audience} form={targetAudienceText} setForm={setTargetAudienceText} />
            </EditorBox>
        </Box>
    )
}