import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import UserNotFound from "../../../components/UserNotFound";
import Loading from "../../../components/Loading";
import { updateDataForSelf } from '../../../core/services/gutcheck-api.service';
import { useUserContext } from "../context";


export const ProfileComplete = () => {
    const navigate = useNavigate();
    const [ userTypeForm, setUserTypeForm ] = useState({
        defaultType: ""
    });

    const { getAccessTokenSilently } = useAuth0();

    const userTypes = [
        {
            id: "investor",
            name: "Investor",
            description: "Using feedback to validate a potential investment"

        },
        {
            id: "entrepreneur",
            name: "Entrepreneur",
            description: "Using feedback to vet a new product or develop a market"

        },
        {
            id: "marketer",
            name: "Marketer",
            description: "Using feedback to improve messaging and collateral"

        }
    ];

    const { userContext, setUserContext } = useUserContext();

    if (!userContext.fetched) return <Loading />

    if (userContext.fetched && !userContext.dbUser) return <UserNotFound />

    const organization = useLocation().state.organization;

    const handleClick = (e: any) => {
        const defaultType = e.currentTarget.id;
        if ( defaultType ) {
            setUserTypeForm({
                ...userTypeForm,
                defaultType: defaultType
            });
        }
    };

    const handleSubmit = async () => {
        const accessToken = await getAccessTokenSilently();
        const res = await updateDataForSelf(userTypeForm, accessToken);

        if (res.data.user) {
            return navigate(`/o/${organization.tag}/project/new`, {state: {organization: organization}});
        }
    };
    
    return (
        <div className="user-type-form">
            <h2>What best describes you?</h2>
            <p className="subtitle">And what are you looking to do for this project?</p>
            <div className="user-type-option-wrapper">
                {userTypes.map((userType) => {
                    return (
                        <div
                            className={userTypeForm.defaultType === userType.id ? "user-type selected" : "user-type"}
                            id={userType.id}
                            onClick={e => handleClick(e)}
                        >
                            <h3>{userType.name}</h3>
                            <p>{userType.description}</p>
                        </div>
                    )
                })}
            </div>
            <button className={userTypeForm.defaultType === "" ? "btn disabled" : "btn"} onClick={handleSubmit}>Next</button>
        </div>
    );
}
