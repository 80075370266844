import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useAnalytics from "../hooks/useAnalytics";

const UnrecognizedUser = () => {

    const { loginWithRedirect } = useAuth0();
    const { trackUser, trackEvent } = useAnalytics();

    let [searchParams, setSearchParams] = useSearchParams();

    const handleSignUp = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/login/silent",
            },
            authorizationParams: {
                prompt: "login",
                screen_hint: "signup",
            },
        });
    };

    useEffect(() => {
        if (searchParams.get("email") === null) return;
        //trackUser({ emailAddress: searchParams.get("email") });
        trackEvent("Unrecognized User", { email: searchParams.get("email") });
    }, [searchParams]);

    const handleDemo = () => {
        window.open("https://link.brightloop.ai/meetings/abrydon/request-demo?uuid=ed92a67e-c2f4-4de2-8ca8-b5f5d4b6b72e", "_blank");
    }

    return (
        <main className="page-main">
            <div className="gc_view splash-unrecognized">
                <h2>Apologies - We don't recognize you... yet.</h2>
                <div className="signup-options flex-row">
                    <div className="signup-option center">
                        <h5>Received an invite?</h5>
                        <div className="display-box white">
                            <p>If you received a personalized invitation, please be sure to sign up using the email address it was sent to.</p>
                            <div className="bold link" onClick={handleSignUp}>Sign Up</div>
                        </div>
                    </div>
                    <div className="signup-option center">
                        <h5>Joining your team?</h5>
                        <div className="display-box white">
                            <p>If your firm is already using BrightLoop and you'd like to join them,  use your official  email address or ask for an invitation.</p>
                            <div className="bold link" onClick={handleSignUp}>Sign Up</div>
                        </div>
                    </div>
                    <div className="signup-option center">
                        <h5>Kicking the tires?</h5>
                        <div className="display-box white">
                            <p>New to BrightLoop and want to learn more about how it can help you vet and win deals?</p>
                            <div className="bold link" onClick={handleDemo}>Schedule a demo</div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
export default UnrecognizedUser;