import { callExternalApi } from "../../../core/services/external-api.service";

const apiServerUrl = (process.env.REACT_APP_AI_CHAIN_ORIGIN ?? "http://127.0.0.1:3002") + "/ai";

export const loadProject = async ( project: any, accessToken: any ) => {
    const body = {
        name: project.name,
        projectTag: project.tag,
        organizationTag: project.organization.tag,
        assetType: project.iterations[0]?.type.title,
        assetText: project.iterations[0]?.asset_text && project.iterations[0]?.asset_text !== "" ? project.iterations[0].asset_text : project.name,
        goalText: project.iterations[0]?.goals?.map( (goal: any) => goal.title ).join(", "),
        audienceText: project.iterations[0]?.target_audience,
        guardrailsText: "",
        userType: project.user_type ?? "marketer",
        users: project.users ?? [],
    };
    
    const config = {
        url: `${apiServerUrl}/create`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: body
    }

    const { data, error } = await callExternalApi({ config });
    
    return {
        data: data || null,
        error
    }
};

export const resetProject = async ( project: any, accessToken: any) => {
    const body = {
        name: project.name,
        projectTag: project.tag,
        organizationTag: project.organization.tag,
        assetType: project.type,
        assetText: "",
        goalText: project.goals.map( (goal: any) => goal.title ).join(", "),
        audienceText: project.target_audience,
        guardrailsText: "",
        userType: project.user_type ?? "marketer",
        responseJson: "",
        performance: {},
        insights: "",
        suggestions: "",
        revisions: ""
    };
    
    const config = {
        url: `${apiServerUrl}/reset`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: body
    }

    const { data, error } = await callExternalApi({ config });
    
    return {
        data: data || null,
        error
    }
};

export const loadPrompts = async (accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/prompts`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });
    
    return {
        data: data || null,
        error
    }
};

export const savePrompt = async ( prompt: any, accessToken: any ) => {
    const config = {
        url: `${apiServerUrl}/prompts`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: prompt
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
};


export const collectFeedback = async ( project: any, accessToken: any ) => {
    const config = {
        url: `${apiServerUrl}/${project._id}/collectFeedback`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });
    
    return {
        data: data || null,
        error
    }
}

export const measurePerformance = async ( project: any, accessToken: any ) => {
    const config = {
        url: `${apiServerUrl}/${project._id}/measurePerformance`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });
    
    return {
        data: data || null,
        error
    }
}

export const extractInsights = async ( project: any, form: any, accessToken: any ) => {

    const config = {
        url: `${apiServerUrl}/${project._id}/extractInsights`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: form
    }

    const { data, error } = await callExternalApi({ config });
    
    return {
        data: data || null,
        error
    }
}

export const generateSuggestions = async ( project: any, form: any, accessToken: any ) => {
    const config = {
        url: `${apiServerUrl}/${project._id}/generateSuggestions`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: form
    }

    const { data, error } = await callExternalApi({ config });
    
    return {
        data: data || null,
        error
    }
}

export const generateRevision = async ( project: any, form: any, accessToken: any ) => {
    const config = {
        url: `${apiServerUrl}/${project._id}/generateRevision`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: form
    }

    const { data, error } = await callExternalApi({ config });
    
    return {
        data: data || null,
        error
    }
}

export const extractHighlights = async ( project: any, form: any, accessToken: any ) => {

    const config = {
        url: `${apiServerUrl}/${project._id}/extractHighlights`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: form
    }

    const { data, error } = await callExternalApi({ config });
    
    return {
        data: data || null,
        error
    }
}
