import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { createProject } from "../../../core/services/gutcheck-api.service";
import ServiceProvider from "../../../modules/ServiceProvider";
import { CreateForm, CreateProjectHeader, CreateProjectHero, CreateSideForm } from "../components/project_form_components";
import ProjectFormData from "../types/ProjectFormData";
import useCreateProjectFormData from "../../../hooks/useCreateProjectFormData";

const fixForm = (form: ProjectFormData) => {
  const createAttrs = (attrs: any) => {
    return attrs.map((val: string) => {
      return {
        value: val,
        label: val
      }
    });
  };

  return {
    ...form,
    goals: createAttrs(form.goals)
  };
};

export const CreateProjectIteration = () => {
  const navigate = useNavigate();

  const { project, organization, viewerRole } = useOutletContext<{project: any, organization: any, viewerRole: any, aiResults: any}>();

  const [form, setForm] = useCreateProjectFormData(project.tag, false, project.iterations.at(-1).iteration_number);

  const { getAccessTokenSilently } = useAuth0();

  const handleSave = async (event: any) => {
    if (form.name === "") {
      setForm({
        ...form,
        error_message: "A project name is required."
      });
      return;
    }

    if (form.goals && form.goals.length > 3) {
      setForm({
        ...form,
        error_message: "Select up to three metrics."
      });
      return;
    }

    try {
      event.preventDefault();
      const accessToken = await getAccessTokenSilently();
      const { error } = await createProject(project.organization!.id, fixForm(form), accessToken);

      if (error) throw error;

      return navigate(`/o/${project.organization?.tag}/`);
    } catch (error) {
      console.error(error);
    };
  };

  const handleStart = async (event: any) => {
    if (form.name === "" || form.type === "" || form.asset_text === "" || (form.goals && form.goals.length === 0) || form.target_audience === "") {
      setForm({
        ...form,
        error_message: "All fields are required to start your project."
      });
      return;
    }

    if (form.goals && form.goals.length > 3) {
      setForm({
        ...form,
        error_message: "Select up to three metrics."
      });
      return;
    }

    try {
      event.preventDefault();
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await createProject(project.organization!.id, fixForm(form), accessToken);

      if (error) throw error;

      await ServiceProvider
        .provideProjectService()
        .start(data.project.id, accessToken);

      return navigate(`/o/${project.organization?.tag}/project/${data.project.tag ? data.project.tag : "no-name"}`, { state: { project: data.project } });
    } catch (error) {
      console.error(error);
    };
  };

  return (
    <main className="page-main">
      <div className="gc_view">
        <div className="splash-page">
          <CreateProjectHero />
          <CreateProjectHeader form={form} setForm={setForm} />

          <div className="splash-container">
            <CreateForm form={form} setForm={setForm} />
            <CreateSideForm form={form} handleSave={handleSave} handleStart={handleStart} />
          </div>
        </div>
      </div>
    </main>
  );
};
