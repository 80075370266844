import { useOutletContext } from "react-router-dom";
import { DealTitle } from "../components/DealTitle";
import { DealAngelsList } from "../components/DealAngelsList";

export const DealIndustryAngels = () => {
    const { project, organization, viewerRole, user } = useOutletContext<{ project: any, organization: any, viewerRole: any, user: any }>();
    return (
        <div>
            <DealTitle project={project} section="Industry Angels" />
            <DealAngelsList project={project} viewpoints={project.viewpoints} user={user} angelStatus="Funded" />
            <DealAngelsList project={project} viewpoints={project.viewpoints} user={user} angelStatus="Reserved" />
            <DealAngelsList project={project} viewpoints={project.viewpoints} user={user} angelStatus="Soft-Circled" />
        </div>
    );
}   