import React from "react";
import { NavLink, Outlet, useOutlet, useOutletContext, useParams } from "react-router-dom";
import { useOrganization } from "../hooks/useOrganization";
import { Table } from "@mui/material";


export const AdminOrganizationProjects = () => {

    const { organization } = useOutletContext<{ organization: any }>();

    if (!organization) return;

    return (
        <Table>
            <thead>
                <tr>
                    <th>Project Name</th>
                    <th>Project Tag</th>
                    <th>Project Status</th>
                    <th>Project Type</th>
                    <th>Project Start Date</th>
                    <th>Project End Date</th>
                </tr>
            </thead>
            <tbody>
                {organization.projects.map((project: any) => (
                    <tr key={project.id}>
                        <td>{project.name}</td>
                        <td>{project.tag}</td>
                        <td>{project.status}</td>
                        <td>{project.type}</td>
                        <td>{project.startDate}</td>
                        <td>{project.endDate}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}