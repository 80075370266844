import NavBar from "./NavBar";
import Footer from "./Footer";
import { ThemeProvider, Box } from "@mui/material";
import { theme } from "../theme/theme";

type PageProps = {
  children: any
}

export const PageLayout = ({ children }: PageProps) => {
  return (
    <ThemeProvider theme={theme}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Box className="flex-grow-1 mt-5">
          {children}
        </Box>
        <Footer />
      </div>
    </ThemeProvider>
  );
};
