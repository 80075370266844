import { Accordion, AccordionDetails, AccordionSummary, Box, Modal, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '50%'
};

export const SpvFaq = ({ surveyResponse, isOpen, setIsOpen }: any) => {

    const [expanded, setExpanded] = useState<string | false>(false);

    const project = surveyResponse.survey_link.project;

    const faqContent = [
        {
            question: "What is BrightLoop?",
            answer: "BrightLoop helps venture firms gather expert viewpoints on startups they are considering funding and invest alongside them."
        },
        {
            question: "How does BrightLoop work with venture firms?",
            answer: "BrightLoop partners with venture firms to evaluate an investment in a new startup. BrightLoop gathers expert viewpoints on the startup. If the venture firm invests, BrightLoop works with the venture firm and the startup to open an opportunity for accredited executives that added helpful viewpoints to also co-invest alongside them."
        },
        {
            question: "Will I definitely be offered an opportunity to co-invest?",
            answer: "No, there's a few things that need to happen before you'll be offered an opportunity to co-invest. The venture firm running the evaluation will need to decide that they're funding the startup and the venture firm and the startup will need to agree to open an allocation to individuals that helped evaluate the startup. Lastly, your viewpoint will need to be considered helpful and either inform the investment decision or the company's strategy."
        },
        {
            question: "Do I have to co-invest?",
            answer: "No, co-investing and advising a company are at your discretion"
        },
        {
            question: "What is an SPV?",
            answer: "It's often difficult for a company raising a venture round to accept small individual checks, so BrightLoop creates an SPV (special purpose vehicle), an entity whose sole purpose is to make an investment in a specific company. Co-investors invest in the SPV, and the SPV purchase shares in the company. For more background on SPVs see an AngelList resource <a href=\"https://learn.angellist.com/articles/spv\" target=\"_blank\">here</a>."
        },
        {
            question: "How does BrightLoop make money?",
            answer: "To open opportunities for more executives to co-invest in VC-backed companies, BrightLoop sets a 1.5% annual management fee to cover the cost of collecting the viewpoints, paying incentives to participants, and managing the investment through liquidity. The BrightLoop carry is 20%. This means individual co-investors will receive 100% of the proceeds until their investment is recouped and then receive 80% of proceeds beyond that. For more on fund structures, please see <a href=\"https://learn.angellist.com/articles/management-fees\" target=\"_blank\">here</a>."
        },
        {
            question: "Who's behind BrightLoop?",
            answer: "BrightLoop is co-founded by <a href=\"https://www.brightloop.ai/about\" target=\"_blank\">Jeff Patterson</a> and <a href=\"https://www.brightloop.ai/about\" target=\"_blank\">Antony Brydon</a>. The company is backed by <a href=\"http://tuesday.vc\" target=\"_blank\">Tuesday Capital</a>, one of the early investors in AngelList. Fund counsel is <a href=\"https://mh-llp.com/attorneys/todd-betke/\" target=\"_blank\">Todd Betke</a> of M&H LLP, and Fund Finance is managed by Kathy Ryan, Founder & Chair at <a href=\"https://roseryan.com/\" target=\"_blank\">RoseRyan</a>."
        }
    ];

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const handleClose = () => {
        setIsOpen(false);
    }

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Box sx={modalStyle}>
                {faqContent.map((faq, index) => {
                    return (
                        <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                className="accordion-faq-question"
                            >
                                {faq.question}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })

                }

            </Box>
        </Modal>
    )
}