import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import ClientProvider from "..//modules/ClientProvider";

type ProjectAssetsData = {
    fetched: boolean;
    projectAssets: any;
    error: { message: any } | null;
}

const initState: ProjectAssetsData = {
    fetched: false,
    projectAssets: [],
    error: null
}

export const useGetProjectAssets = (projectId: number) => {
    const { getAccessTokenSilently } = useAuth0();
    const projectClient = ClientProvider.provideProjectClient();

    const [state, setState] = useState(initState);

    const reloadProjectAssets = async () => {
        setState({
            ...state,
            fetched: false
        });

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.getProjectAssets(projectId, accessToken);

        if (data) {
            const fetchedProjectAssets: ProjectAssetsData = {
                fetched: true,
                projectAssets: data.assets ?? [],
                error: error
            };
            setState(fetchedProjectAssets);

        } else {
            console.error(error);
        }
    }

    useEffect(() => {
        let isMounted = true;

        const fetchProjectAssets = async () => {
            if (!isMounted) return;
            reloadProjectAssets();
        };

        fetchProjectAssets();

        return () => { isMounted = false };
    }, []);

    return { ...state, reloadProjectAssets };
};
