import React, { useEffect, useState } from 'react';
import { createProject } from '../services/gutcheck-admin-api.service';
import { useAuth0 } from "@auth0/auth0-react";

type TProjectFrom = {
  parentCallback: any
}

const AddProjectForm = ({ parentCallback }: TProjectFrom) => {

  const [form, setForm] = useState({
    name: '',
    type: ''
  });

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      // const accessToken = await getAccessTokenSilently();
      // const { data, error } = await getOrganizations(accessToken);

      // if (data) {
      //   setOrganizations(data.organizations.map( organization => {
      //     return { value: organization.id, label: organization.name };
      //   }));
      // }
    };

    fetchData();

    return () => {
    }
  }, []);

  const handleChange = (event: any) => {
    setForm({
      ...form,
      name: event.target.value,
    });
  };

  const handleTypeChange = (event: any) => {
    setForm({
      ...form,
      type: event.target.value,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const accessToken = await getAccessTokenSilently();
    const project = {
      name: form.name,
      type: form.type
    }
    
    const { data, error } = await createProject(project, accessToken);

    setForm({
      name: '',
      type: ''
    });

    parentCallback( data.project );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="add-field textfield-wrapper">
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          value={form.name}
          onChange={handleChange}
        />
      </div>
      <div className="add-field textfield-wrapper">
        <label htmlFor="type">Type</label>
        <input
          id="type"
          type="text"
          value={form.type}
          onChange={handleTypeChange}
        />
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

export { AddProjectForm };