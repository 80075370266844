import { useEffect, useState } from "react";
import Project from "../../projects/types/Project";

export const WorkspaceSummary = ({ projects }: any) => {

    const [stats, setStats] = useState({
        deals: 0,
        viewpoints: 0,
        demoRequests: 0,
        advisors: 0,
        angels: 0,
        investmentAmountLow: 0,
        investmentAmountHigh: 0
    });

    useEffect(() => {
        if (!projects) return;

        let deals = 0;
        let viewpoints = 0;
        let demoRequests = 0;
        let advisors = 0;
        let angels = 0;
        let investmentAmountLow = 0;
        let investmentAmountHigh = 0;

        projects.forEach((project: any) => {
            if (project.isSample) return;
            deals++;
            viewpoints += project.statsViewpoints ?? 0;
            demoRequests += project.statsDemoRequests ?? 0;
            advisors += project.statsAdvisors ?? 0;
            angels += project.statsAngels ?? 0 ;
            investmentAmountLow += project.statsInvestmentAmountLow ?? 0;
            investmentAmountHigh += project.statsInvestmentAmountHigh ?? 0   ;
        });

        setStats({
            deals,
            viewpoints,
            demoRequests,
            advisors,
            angels,
            investmentAmountLow,
            investmentAmountHigh
        });
    }, [projects]);

    //const investmentAmount = (Math.round(((stats.investmentAmountLow + stats.investmentAmountHigh) / 2) / 1000) * 1000).toLocaleString("en-US");
    const investmentRange = stats.investmentAmountHigh > 0 ?
    `\$${Intl.NumberFormat('en', { notation: 'compact' }).format(stats.investmentAmountLow)}-\$${Intl.NumberFormat('en', { notation: 'compact' }).format(stats.investmentAmountHigh)}`
    : "$0";


    return (
        <div className="display-box workspace-summary-container">
            <div className="workspace-summary-stat">
                <div className="workspace-summary-stat-value">{stats.deals} deal{stats.deals !== 1 && "s"}</div>
                <div className="workspace-summary-stat-label">Evaluated</div>
            </div>
            <div className="workspace-summary-stat">
                <div className="workspace-summary-stat-value">{stats.viewpoints} viewpoint{stats.viewpoints !== 1 && "s"}</div>
                <div className="workspace-summary-stat-label">Collected from buyers</div>
            </div>
            <div className="workspace-summary-stat">
                <div className="workspace-summary-stat-value">{stats.demoRequests} demo{stats.demoRequests !== 1 && "s"}</div>
                <div className="workspace-summary-stat-label">Requested from buyers</div>
            </div>
            <div className="workspace-summary-stat">
                <div className="workspace-summary-stat-value">{stats.advisors} advisor{stats.advisors !== 1 && "s"}</div>
                <div className="workspace-summary-stat-label">Interested in advising</div>
            </div>
            <div className="workspace-summary-stat">
                <div className="workspace-summary-stat-value">{investmentRange}</div>
                <div className="workspace-summary-stat-label">Soft-circled</div>
            </div>

        </div>
    );
}
