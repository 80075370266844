import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { getViewpoints, loadStats, updateViewpoint, updateViewpointProfile } from "../services/gutcheck-admin-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { v4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdminViewpointsList } from "./components/ViewpointsList";

const interestFields = [
    {
        key: "interest_demo",
        text: "Demo",
        count: 0,
        countText: "want a"
    },
    {
        key: "interest_beta",
        text: "Beta",
        count: 0,
        countText: "want to"
    },
    {
        key: "interest_investor",
        text: "Co-Invest",
        count: 0,
        countText: "want to"
    },
    {
        key: "interest_advisor",
        text: "Advise",
        count: 0,
        countText: "want to"
    },
    {
        key: "interest_employment",
        text: "Job",
        count: 0,
        countText: "want a"
    },
    {
        key: "interest_followup",
        text: "Follow Up",
        count: 0,
        countText: 'open to'
    },
];


export const AdminViewpoints = () => {

    const { project, reloadProjectData } = useOutletContext<any>();
    const { getAccessTokenSilently } = useAuth0();

    const [viewpoints, setViewpoints] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [successRowIds, setSuccessRowIds] = useState<number[]>([]);
    const [errorRowIds, setErrorRowIds] = useState<number[]>([]);

    const [editViewpoint, setEditViewpoint] = useState<any>(null);


    useEffect(() => {

        let isMounted = true;

        const fetchViewpoints = async () => {
            if (!isMounted) return;

            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getViewpoints(project.id, accessToken);

            if (data) {
                setViewpoints(data.viewpoints);
                setLoading(false);
            }
        };

        if (project) {
            fetchViewpoints();
        }

        return () => { isMounted = false; };
    }, []);

    const handleRowClick = (event: any) => {
        setEditViewpoint(event.target.parentElement.id);
    }

    const handleReloadStats = async (event: any) => {
        event.preventDefault();

        const origText = event.target.innerText;

        event.target.innerText = "Reloading...";
        const accessToken = await getAccessTokenSilently();
        const updatedProject = await loadStats(project.id, accessToken);
        const { data, error } = await getViewpoints(project.id, accessToken);

        if (data) {
            setViewpoints(data.viewpoints);
            setLoading(false);
            reloadProjectData();
        }
        event.target.innerText = origText;
    }

    const handleToggle = (event: any) => {
        const viewpointId = parseInt(event.target.value);
        let updatedViewpoint: any = null;
        const updatedViewpoints = viewpoints.map((viewpoint) => {
            if (viewpoint.id === viewpointId) {
                viewpoint[event.target.name] = !viewpoint[event.target.name];
                updatedViewpoint = viewpoint;
            }
            return viewpoint;
        });

        if (updatedViewpoint) {
            setEditViewpoint(updatedViewpoint.id);
            setViewpoints(updatedViewpoints);
        }
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();
        let updatedViewpoint: any = null;
        const updatedViewpoints = viewpoints.map((viewpoint) => {
            if (viewpoint.id === editViewpoint) {
                updatedViewpoint = viewpoint;
            }
            return viewpoint;
        });

        if (updatedViewpoint) {
            const { data, error } = await updateViewpoint(project.id, updatedViewpoint.id, updatedViewpoint, accessToken);
            if (error) {
                console.error(error);
                setErrorRowIds(prevState => [...prevState, editViewpoint]);
                setTimeout(() => {
                    setErrorRowIds(prevState => prevState.filter(id => id !== editViewpoint));
                }, 2000);
                return;
            }

            //setViewpoints(updatedViewpoints);
            setSuccessRowIds(prevState => [...prevState, editViewpoint]);
            setTimeout(() => {
                setSuccessRowIds(prevState => prevState.filter(id => id !== editViewpoint));
            }, 2000);
            setEditViewpoint(null);
            reloadProjectData();
        }
    }

    const handleUpdateProfile = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateViewpointProfile(editViewpoint, accessToken);
        if (error) {
            console.error(error);
            return;
        }
        setEditViewpoint(null);
        reloadProjectData();
    }

    if (viewpoints.length === 0) {
        return <div className="admin-screen">No Viewpoints</div>;
    }

    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <h5>Viewpoints</h5>
                <div className="button btn btn-secondary" onClick={handleReloadStats} >Reload Stats</div>
            </div>
            <AdminViewpointsList
                project={project}
                sectionName="Review Queue" 
                viewpoints={viewpoints.filter((viewpoint: any) => !viewpoint.is_reviewed)}
                reloadProjectData={reloadProjectData}
            />

            <AdminViewpointsList
                project={project}
                sectionName="Approved"
                viewpoints={viewpoints.filter((viewpoint: any) => { return (viewpoint.is_reviewed && viewpoint.is_approved)} )}
                reloadProjectData={reloadProjectData}
            />

            <AdminViewpointsList
                project={project}
                sectionName="Rejected"
                viewpoints={viewpoints.filter((viewpoint: any) => viewpoint.is_reviewed && !viewpoint.is_approved)}
                reloadProjectData={reloadProjectData}
            />

        </div>
    );
}