import { useNavigate, useOutlet, useOutletContext, useParams } from "react-router-dom";
import { SurveyLinkForm } from "../components/form-survey-link";
import { useAuth0 } from "@auth0/auth0-react";
import { createSurveyLink } from "../services/gutcheck-admin-api.service";
import useAdminGetProjectData from "../hooks/useAdminGetProjectData";

export const AdminSurveyLinkCreate = () => {
    const { projectId } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const { project, reloadProjectData } = useOutletContext<any>();

    const handleSubmit = async (formData: any) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await createSurveyLink(projectId, formData, accessToken);
        if (data) {
            reloadProjectData();
            navigate(`/admin/project/${projectId}/surveyLinks`);
            return;
        }  
    }

    return (
        <div>
            <h4>Create Survey Link</h4>
            <SurveyLinkForm  onSubmit={handleSubmit}/>
        </div>
    );
}