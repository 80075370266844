import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getRespondentData } from "../core/services/gutcheck-api.service";

const useGetRespondentHtml = (respondentId: string, project: any) => {
    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState({
        respondentHtml: {
            __html: ""
        },
    });

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            if (!respondentId) {
                setState({
                    ...state,
                    respondentHtml: { __html: '' }
                });
                return;
            }
            
            const accessToken = await getAccessTokenSilently();

            const { data } = await getRespondentData(respondentId, project, accessToken);

            if (!isMounted) return;

            if (data) {
                setState({
                    ...state,
                    respondentHtml: { __html: data.html }
                });
            };
        };

        fetchData(); 

        return () => { isMounted = false };
    }, [respondentId]);

    return [state.respondentHtml];
};

export default useGetRespondentHtml;