import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { Box, Checkbox, DialogProps, FormControlLabel, MenuItem, Modal, Select, Stack, TextField } from "@mui/material";
import { createSurveyResponse, getSurveyLinks, updateSurveyResponse } from "../../services/gutcheck-admin-api.service";
import { useAuth0 } from "@auth0/auth0-react";

dayjs.extend(utc);

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '50%'
};

const statuses = [
    { value: "init", label: "Not Completed" },
    { value: "complete", label: "Completed" },
    { value: "screened", label: "Screened Out" },
    { value: "over_quota", label: "Over Quota" }
];

export const SurveyResponseForm = ({ project, surveyResponse, reloadSurveyResponses, editMode, setEditMode }: any) => {

    const initialSurveyResponse = {
        name: surveyResponse.name ?? "",
        email_address: surveyResponse.email_address ?? "",
        invited_to_invest: surveyResponse.invited_to_invest ?? false,
        completed_at: surveyResponse.completed_at ? dayjs.utc(surveyResponse.completed_at).local().format('YYYY-MM-DDTHH:mm') : "",
        survey_link_id: surveyResponse.survey_link?.id ?? "",
        reserved_spot: surveyResponse.reserved_spot ?? false,
        reserved_amount: surveyResponse.reserved_amount ?? 0,
        status: surveyResponse.status ?? "init",
        funded: surveyResponse.funded ?? false,
        funded_amount: surveyResponse.funded_amount ?? 0
    }

    const [surveyResponseData, setSurveyResponseData] = useState<any>(initialSurveyResponse);
    const [surveyLinks, setSurveyLinks] = useState<any>([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchSurveyLinks = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getSurveyLinks(project.id, accessToken);
            if (data) {
                setSurveyLinks(data.surveyLinks);
            } else {
                console.error(error);
            }
        }

        if (project) {
            fetchSurveyLinks();
        }
    }, [project]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();
        const newSurveyResponse = surveyResponseData;
        newSurveyResponse.completed_at = new Date(surveyResponseData.completed_at).toISOString();
        if (surveyResponse.id) {
            await updateSurveyResponse(project.id, surveyResponse.id, newSurveyResponse, accessToken);
        } else {
            await createSurveyResponse(project.id, newSurveyResponse, accessToken);
        }
        setSurveyResponseData(initialSurveyResponse);
        await reloadSurveyResponses();
        setEditMode(false);
    }

    const handleChange = (event: any) => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        setSurveyResponseData({ ...surveyResponseData, [event.target.name]: value });
    }

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setEditMode(false);
    }

    const handleCancel = (event: any) => {
        event.preventDefault();
        setEditMode(false);
    }

    if (!surveyLinks) {
        return <></>
    }

    const url = `${process.env.REACT_APP_AUTH0_CALLBACK_URL}/e/${surveyResponse.survey_link?.public_id}/expert/${surveyResponse.public_id}`;

    return (
        <Modal
            open={editMode} onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <h4>{surveyResponse.id ? "Edit Survey Response" : "Add Survey Response"}</h4>

                <form onSubmit={handleSubmit}>

                    <Stack spacing={2} sx={{marginTop: '20px'}}>
                        <TextField
                            label="Evaluation URL"
                            name="evaluation_url"
                            value={url}
                            disabled
                        />
                        <TextField
                            label="Co-Invest URL"
                            name="co_invest_url"
                            value={`${url}/invest`}
                            disabled
                        />
                        <TextField
                            label="Name"
                            name="name"
                            value={surveyResponseData.name}
                            onChange={handleChange}
                            required
                        />
                        <TextField
                            label="Email"
                            name="email_address"
                            value={surveyResponseData.email_address}
                            onChange={handleChange}
                            required
                        />

                        <TextField
                            label="Survey Link"
                            name="survey_link_id"
                            value={surveyResponseData.survey_link_id}
                            onChange={handleChange}
                            select
                            required
                        >
                            {surveyLinks.map((surveyLink: any) => (
                                <MenuItem key={surveyLink.id} value={surveyLink.id}>{surveyLink.name}</MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            label="Viewpoint Status"
                            name="status"
                            value={surveyResponseData.status}
                            onChange={handleChange}
                            select
                            required
                        >
                            {statuses.map((status: any) => (
                                <MenuItem key={status.value} value={status.value}>{status.label}</MenuItem>
                            ))}
                        </TextField>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={surveyResponseData.invited_to_invest}
                                        onChange={handleChange}
                                        name="invited_to_invest"
                                    />
                                }
                                label="Invited to Invest"
                            />
                            <TextField
                                label="Completed At"
                                name="completed_at"
                                value={surveyResponseData.completed_at}
                                onChange={handleChange}
                                type="datetime-local"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                                sx={{ width: '65%' }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={surveyResponseData.reserved_spot}
                                        onChange={handleChange}
                                        name="reserved_spot"
                                    />
                                }
                                label="Reserved Spot"
                            />
                            <TextField
                                label="Reserved Amount"
                                name="reserved_amount"
                                value={surveyResponseData.reserved_amount}
                                onChange={handleChange}
                                required
                                sx={{ width: '65%' }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={surveyResponseData.funded}
                                        onChange={handleChange}
                                        name="funded"
                                    />
                                }
                                label="Funded"
                            />
                            <TextField
                                label="Funded Amount"
                                name="funded_amount"
                                value={surveyResponseData.funded_amount}
                                onChange={handleChange}
                                required
                                sx={{ width: '65%' }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                            <button type="submit">Submit</button>
                        </Box>
                    </Stack>
                </form>
            </Box>
        </Modal>
    )
}