import { useEffect, useState } from "react";
import useAnalytics from "./useAnalytics";
import { useUserContext } from "../features/users";

type UserContext = {
    fetched: boolean;
    user: any | null;
    projectMemberships: any[] | null;
    organizationMemberships: any[] | null;
    error: {message: any} | null;
}

const initState: UserContext = {
    fetched: false,
    user: null,
    projectMemberships: null,
    organizationMemberships: null,
    error: null
}

const useGetUserData = () => {
    const { trackUser } = useAnalytics();

    const [state, setState] = useState(initState);

    const { userContext, setUserContext } = useUserContext();

    useEffect(() => {
        let isMounted = true;

        if (userContext.dbUser && userContext.fetched) {
            setState({
                ...state,
                user: userContext.dbUser,
                projectMemberships: userContext.projectMemberships,
                organizationMemberships: userContext.organizationMemberships,
                fetched: true,
                error: null
            })
        }

        if (!userContext.dbUser && userContext.fetched) {
            const userContext: UserContext = {
                fetched: true,
                user: null,
                projectMemberships: null,
                organizationMemberships: null,
                error: {message: "User not found"}
            }
            setState(userContext);
        }

        return () => {
            isMounted = false;
        };
    }, [userContext]);

    return [state.fetched, state.user, state.organizationMemberships, state.projectMemberships, state.error];

};

export default useGetUserData;