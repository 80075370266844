import { Outlet, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import NotFound from "../../../components/NotFound";
import useGetProjectData from "../../../hooks/useGetProjectData";
import useGetUserData from "../../../hooks/useGetUserData";
import Intercom from '@intercom/messenger-js-sdk';
import { useEffect } from "react";

export const Project = () => {
  const { projectTag } = useParams();

  const projectData = useGetProjectData(projectTag ?? "");
  const [ fetched, user] = useGetUserData();

  useEffect(() => {
    if (fetched && user && process.env.REACT_APP_INTERCOM_APP_ID) {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: user.uuid,
        email: user.emailAddress,
        name: user.name,
        created_at: user.createdAt
      });
    } else {
      console.warn('Intercom is not configured');
    }
  }, [fetched, user]);
  if (!projectData.fetched || !fetched ) return <Loading />;

  if (projectData.fetched && !projectData.project) return <NotFound />;

  const viewerRole = projectData.role;

  return (
        <Outlet context={{organization: projectData.organization, project: projectData.project, viewerRole: viewerRole, aiResults: projectData.aiResults?.data?.project}} />
  );
};

