import { useState } from "react";
import { Box, Button, Divider, FormGroup, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { updateProject } from "../../services/gutcheck-admin-api.service";

const status = [
    { value: "draft", label: "Setup & Launch" },
    { value: "evaluation", label: "Collecting Viewpoints" },
    { value: "analysis", label: "Analysis & Results" },
    { value: "firm_investment", label: "Firm Investment" },
    { value: "spv", label: "BrightLoop Expert SPV" },
    { value: "completed", label: "Completed" }
];

const experience = [
    { value: "evaluation", label: "Evaluation" },
    { value: "winning", label: "Winning" },
];

export const DealProjectSettings = ({ project, user }: any) => {

    const { getAccessTokenSilently } = useAuth0();

    const [projectForm, setProjectForm] = useState<any>({
        project: {
            name: project.name,
            experience: project.experience,
            status: project.status,
            analysis_url: project.iterations[0].analysis_url,
        },
        editMode: false,
        message: ""
    });

    const setEditMode = (mode: boolean) => {
        setProjectForm({
            ...projectForm,
            editMode: mode,
            message: ""
        });
    }

    const handleChange = (e: any) => {
        setProjectForm({
            ...projectForm,
            project: {
                ...projectForm.project,
                [e.target.name]: e.target.value,
            },
        });
    }

    const handleCancel = () => {
        setProjectForm({
            ...projectForm,
            project: project,
            editMode: false,
        });
    }

    const handleSubmit = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateProject(project.id, projectForm.project, accessToken);
        if (data) {
            setProjectForm({
                ...projectForm,
                editMode: false,
                message: "Project updated"
            });
        } else {
            setProjectForm({
                ...projectForm,
                message: error?.message
            });
        }
    }

    return (
        <Box>

            <Stack spacing={2} >
                <Box>
                    <TextField
                        name="name"
                        label="Project Name"
                        value={projectForm.project.name}
                        onChange={handleChange}
                        disabled={!projectForm.editMode}
                        fullWidth
                        size="small"
                    />
                </Box>
                <Box>
                    <TextField
                        name="experience"
                        label="Experience"
                        value={projectForm.project.experience}
                        onChange={handleChange}
                        disabled={!projectForm.editMode}
                        select
                        fullWidth
                        size="small"
                    >
                        {experience.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box>
                    <TextField
                        name="status"
                        label="Status"
                        value={projectForm.project.status}
                        select
                        onChange={handleChange}
                        disabled={!projectForm.editMode}
                        fullWidth
                        size="small"
                    >
                        {status.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box>
                    <TextField
                        name="analysis_url"
                        label="Analysis URL"
                        value={projectForm.project.analysis_url}
                        onChange={handleChange}
                        disabled={!projectForm.editMode}
                        fullWidth
                        size="small"
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "10px", alignItems: "center"}}>
                    {!projectForm.editMode && <Typography>{projectForm.message}</Typography>}
                    {!projectForm.editMode && <Button onClick={() => setEditMode(true)}>Edit</Button>}

                    {projectForm.editMode && <Button onClick={handleCancel}>Cancel</Button>}
                    {projectForm.editMode && <Button variant="contained" onClick={handleSubmit}>Save</Button>}
                </Box>
            </Stack>

        </Box>
    );
};
