import { Outlet, NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

export const Admin = () => {

  const { user } = useAuth0();

  return (
<>
<aside className="page-sidebar sidebar-light">
  <div className="sidebar-tab-container">
  <NavLink to={`/admin/projects`}>
      <div className="sidebar-tab">
        <p><span className="sidebar-label">Projects</span></p>
      </div>
    </NavLink>
    <NavLink to={`/admin/organizations`}>
      <div className="sidebar-tab">
        <p><span className="sidebar-label">Organizations</span></p>
      </div>
    </NavLink>
    <NavLink to={`/admin/users`}>
      <div className="sidebar-tab">
        <p><span className="sidebar-label">Users</span></p>
      </div>
    </NavLink>
    <NavLink to={`/admin/experts`}>
      <div className="sidebar-tab">
        <p><span className="sidebar-label">Experts</span></p>
      </div>
    </NavLink>
  </div>
</aside>
<main className="page-main admin-page sidebar">
  <div className="gc_view">
    <Outlet/>
  </div>
</main>
</>
  );
}

