import Loading from "../../../components/Loading";
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import StyledDropzone from "../../../components/AssetUpload";
import { updateOrganization, uploadLogo } from "../../../core/services/gutcheck-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { FormGroup, FormControlLabel, Switch, TextField } from "@mui/material";
import { useWorkspace } from "../hooks";
import useAnalytcs from "../../../hooks/useAnalytics";

type TAdminOrganization = {
    name: string,
    tag: string,
    logo_url: string,
    projects: any | null,
    use_branding: boolean,
    email_domain: string,
    whitelist_enabled: boolean
}

const initState: TAdminOrganization = {
    name: '',
    tag: '',
    logo_url: '',
    projects: null,
    use_branding: false,
    email_domain: '',
    whitelist_enabled: false
}

export const WorkspaceSettings = () => {

    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const { trackPage } = useAnalytcs();

    const context = useOutletContext<any>();
    const { organizationTag } = useParams();

    const initQueuedFiles: File[] = [];
    const [queuedFiles, setQueuedFiles] = useState(initQueuedFiles);

    const [form, setForm] = useState(initState);
    const { organization, isLoading, error } = useWorkspace(organizationTag ?? "");

    useEffect(() => {
        trackPage("dealspace settings", { organization: organizationTag ?? ""})
        //(window as any).analytics.page("dealspace settings");
    }, []);

    useEffect(() => {
        if (!isLoading && (error || !organization)) {
            navigate('/dashboard');
        } else if(organization) {
            setForm({
                name: organization?.name,
                tag: organization?.tag,
                logo_url: organization?.logo_url,
                projects: organization?.projects,
                use_branding: organization?.logo_url !== null && organization?.logo_url !== '',
                email_domain: organization?.email_domain,
                whitelist_enabled: organization?.whitelist_enabled
            });
        }
    }, [isLoading]);

    if (isLoading) return <Loading />

    if(!organization) return <Loading />


    const handleChange = (event: any) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setForm({
            ...form,
            [event.target.name]: value,
        });
    };

    const handleBrandSwitch = () => {
        if (form.use_branding) {
            setForm({
                ...form,
                logo_url: '',
                use_branding: false
            });
        } else {
            setForm({
                ...form,
                use_branding: true
            });
        }
    }

    const handleSubmit = async () => {
        const accessToken = await getAccessTokenSilently();

        let logoUrl = form.logo_url;
        if (queuedFiles.length > 0) {
            const { data, error } = await uploadLogo(queuedFiles[0], organization.tag, queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, ""), accessToken);
            logoUrl = data.response.url;
        }

        const updatedOrganization = {
            name: form.name,
            logo_url: logoUrl,
            email_domain: form.email_domain,
            whitelist_enabled: form.whitelist_enabled
        }

        const { data, error } = await updateOrganization(organization.id, updatedOrganization, accessToken);

        if (!error) {
            return navigate(`/o/${organization?.tag}?refresh=true`);
        }
    }



    return (
        <div className="dashboard">
            <div className="organization-header">
                <h2 className="dashboard-title">{organization.name} Settings</h2>
                {/*<OrganizationInviteControl organization={organization} />*/}
            </div>
            <div className="organization-settings">
                <div className="organization-settings-section">
                    <div className="edit-page-form">
                        <div className="edit-page-section textfield-wrapper">
                            <h5 className="edit-section-header">Organization Details</h5>
                            <TextField name="name" id="name" label="Organization Name" type="text" value={form.name} onChange={handleChange} />
                        </div>
                        <div className="edit-page-section textfield-wrapper">
                            <h5 className="edit-section-header">Branding</h5>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={form.use_branding} onChange={handleBrandSwitch} />} label="Use Custom Branding" />
                            </FormGroup>
                            {form.use_branding &&
                                <div className="logo-uploader">
                                    <StyledDropzone fileTypes={{ 'image/*': [] }} queuedFiles={[]} setQueuedFiles={setQueuedFiles} initialFile={form.logo_url ?? ""} />
                                    <p className="branding-guidelines">Logos should be in PNG or SVG format with a transparent background.</p>
                                </div>
                            }
                        </div>
                        <div className="edit-page-section textfield-wrapper">
                            <h5 className="edit-section-header">Access</h5>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={form.whitelist_enabled} onChange={handleChange} name="whitelist_enabled"/>} label="Allow access to anyone at the following domains:" />
                            </FormGroup>
                            <TextField multiline rows={4} name="email_domain" label="Email Domains (1 per line)" type="text" value={form.email_domain} onChange={handleChange} />
                        </div>
                        <div className="organization-settings-section-content-item">
                            <button onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
