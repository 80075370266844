import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { SurveyLinkForm } from "../components/form-survey-link";
import { useAuth0 } from "@auth0/auth0-react";
import { createSurveyLink, getSurveyLink, updateSurveyLink } from "../services/gutcheck-admin-api.service";
import { useEffect, useState } from "react";

export const AdminSurveyLinkEdit = () => {
    const { projectId, surveyLinkId } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({} as any);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null as any);

    const { project, reloadProjectData } = useOutletContext<any>();

    useEffect(() => {
        let isMounted = true;

        const fetchSurveyLinkData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await getSurveyLink(projectId, surveyLinkId, accessToken);

                if (isMounted) {
                    setFormData(data.surveyLink);
                    setIsLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                    setIsLoading(false);
                }
            }
        }

        fetchSurveyLinkData();

        return () => { isMounted = false };

    }, []);

    const handleSubmit = async (formData: any) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await updateSurveyLink(projectId, surveyLinkId, formData, accessToken);
        if (data) {
            reloadProjectData();
            navigate(`/admin/project/${projectId}/surveyLinks`);
            return;
        }  
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div>
            <h4>Edit Survey Link</h4>
            <SurveyLinkForm  initialData={formData} onSubmit={handleSubmit}/>
        </div>
    );
}