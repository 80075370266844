import StyledDropzone from "../../../../components/AssetUpload";
import { createInvestmentMaterial, updateInvestmentMaterial } from "../../services/gutcheck-admin-api.service";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, FormControlLabel, Modal, Stack, Switch, TextField } from "@mui/material";
import type { DialogProps } from "@mui/material";
import { uploadLogo } from "../../../../core/services/gutcheck-api.service";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '50%'
};

export const InvestmentMaterialForm = ({ project, investmentMaterial, investmentMaterials, reloadInvestmentMaterials, editMode, setEditMode }: any) => {
    const { getAccessTokenSilently } = useAuth0();

    const initialInvestmentMaterial = {
        title: investmentMaterial?.title ?? "",
        description: investmentMaterial?.description ?? "",
        asset_url: investmentMaterial?.asset_url ?? "",
        thumbnail_url: investmentMaterial?.thumbnail_url ?? "",
        index: investmentMaterial?.index ?? investmentMaterials.reduce(function(prev: any, current: any) { return (prev && prev.index > current.index ? prev : current) }) ?? 0,
        is_published: investmentMaterial?.is_published ?? false
    }

    const [investmentMaterialForm, setInvestmentMaterialForm] = useState(initialInvestmentMaterial);

    const initQueuedFiles: File[] = [];
    const [queuedFiles, setQueuedFiles] = useState(initQueuedFiles);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        let thumbnailUrl = investmentMaterialForm.thumbnail_url;
        if (queuedFiles.length > 0) {
            const { data, error } = await uploadLogo(queuedFiles[0], project.tag, queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, ""), accessToken);
            thumbnailUrl = data.response.url;
        }

        const newInvestmentMaterial = investmentMaterialForm;
        newInvestmentMaterial.thumbnail_url = thumbnailUrl;

        if (investmentMaterial?.id) {
            await updateInvestmentMaterial(project.id, investmentMaterial.id, newInvestmentMaterial, accessToken);
        } else {
            await createInvestmentMaterial(project.id, newInvestmentMaterial, accessToken);
        }
        setInvestmentMaterialForm(initialInvestmentMaterial);
        await reloadInvestmentMaterials();
        setEditMode(false);
    }

    const handleChange = (event: any) => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        setInvestmentMaterialForm({ ...investmentMaterialForm, [event.target.name]: value });
    }

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setEditMode(false);
    }

    const handleCancel = (event: any) => {
        event.preventDefault();
        setEditMode(false);
    }

    return (
        <Modal
            open={editMode} onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="admin-modal-intro">
                    <h5>{investmentMaterial?.id ? "Edit Investment Material" : "Add Investment Material"}</h5>
                    <p>Descriptive text goes here.</p>

                </div>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <TextField type="number" name="index" label="Index" onChange={handleChange} value={investmentMaterialForm?.index} />
                        <TextField name="title" label="Title" onChange={handleChange} value={investmentMaterialForm?.title} />
                        <TextField multiline rows={4} name="description" label="Description" onChange={handleChange} value={investmentMaterialForm?.description}></TextField>
                        <TextField name="asset_url" label="Asset URL" onChange={handleChange} value={investmentMaterialForm?.asset_url} />
                        <div>
                            <FormControlLabel name="is_published" control={<Switch checked={investmentMaterialForm?.is_published} onChange={handleChange} />} label="Is Published" />
                        </div>
                        <div className="edit-page-form-field textfield-wrapper">
                            <label>Thumbnail</label>
                            <div className="logo-uploader">
                                <StyledDropzone fileTypes={{ 'image/*': [] }} queuedFiles={[]} setQueuedFiles={setQueuedFiles} initialFile={investmentMaterialForm.thumbnail_url ?? ""} />
                                <p className="branding-guidelines">Thumbnails shouldn't contain any sensitive information.</p>
                            </div>
                        </div>
                        <button type="submit">Submit</button>
                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>

                    </Stack>
                </form>
            </Box>
        </Modal>
    );
}