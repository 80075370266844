import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getOrganization } from '../services/gutcheck-admin-api.service';

type OrganizationData = {
    fetched: boolean;
    organization: any;
    error: { message: any } | null;
}

const initState: OrganizationData = {
    fetched: false,
    organization: null,
    error: null
}

export const useOrganization = (organizationId: string) => {
    const { getAccessTokenSilently } = useAuth0();
    const [state, setState] = useState(initState);
    const reloadOrganization = async () => {
        setState({
            ...state,
            fetched: false
        });
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getOrganization(organizationId, accessToken);
        if (data) {
            const fetchedOrganization: OrganizationData = {
                fetched: true,
                organization: data.organization,
                error: error
            };
            setState(fetchedOrganization);
        } else {
            console.error(error);
        }
    };
    useEffect(() => {
        let isMounted = true;
        const fetchOrganization = async () => {
            if (!isMounted)
                return;
            reloadOrganization();
        };
        fetchOrganization();
        return () => {
            isMounted = false;
        };
    }, []);
    return { ...state, reloadOrganization };
}