import { Route, Routes } from "react-router-dom";
import { BasicLayout } from "../components/BasicLayout";
import "../App.css";
import { EvaluationsRoutes } from "../features/evaluations";

export const BasicLayoutRoutes = () => {
    return (
      <>
        <BasicLayout>
          <Routes>
            <Route path="*" element={<EvaluationsRoutes/>} />
          </Routes>
        </BasicLayout>
        </>
      );
}

export default BasicLayoutRoutes;