import { useAuth0 } from "@auth0/auth0-react";
import { Link, useOutletContext } from "react-router-dom";
import { fetchLeads, getTargetAudience } from "../services/gutcheck-admin-api.service";
import { ProjectMembers } from "./components/ProjectMembers";
import { DealProjectSettings } from "./components/DealProjectSettings";
import { Box } from "@mui/material";

export const AdminProjectView = () => {

    const { getAccessTokenSilently } = useAuth0();
    const { project } = useOutletContext<{ project: any }>();

    const handleFetchLeads = async () => {
        const accessToken = await getAccessTokenSilently();
        const leads = await fetchLeads(project.id, accessToken);
    }

    const handleFetchDocuments = async () => {
        const accessToken = await getAccessTokenSilently();
        const documents = await getTargetAudience(project.id, accessToken);
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px"}}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', justifyContent: "space-between" }}>
                <Box className="display-box" sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "10px"}}>
                    <h5>Handy Links</h5>
                    <ul>
                        <li>
                            <Link to={`/o/${project.organization.tag}/project/${project.tag}`}>Old Project page</Link>
                        </li>
                        <li>
                            <Link to={`/o/${project.organization.tag}/d/${project.tag}`}>New Project page</Link>
                        </li>
                        <li>
                            <span className="link" onClick={handleFetchLeads}>Fetch leads</span>
                        </li>
                    </ul>
                </Box>
                <Box className="display-box white" sx={{flex: 1, display: "flex", flexDirection: "column", gap: "10px"}}>
                    <h5>Quick Edits</h5>
                    <DealProjectSettings project={project} />
                </Box>
            </Box>


            <Box className="display-box white">
            <h5>Members</h5>
            <ProjectMembers project={project} />
            </Box>
        </Box>
    );
}