import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getInvestmentMaterials } from "../services/gutcheck-admin-api.service";

type InvestmentMaterialsData = {
  fetched: boolean;
  investmentMaterials: any;
  error: {message: any} | null;
}

const initState: InvestmentMaterialsData = {
  fetched: false,
  investmentMaterials: [],
  error: null
}

export const useInvestmentMaterials = (projectId: number) => {
    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState(initState);

    const reloadInvestmentMaterials = async () => {
      setState({
        ...state,
        fetched: false
      });

      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getInvestmentMaterials(projectId, accessToken);

      if (data) {        
        const fetchedInvestmentMaterials: InvestmentMaterialsData = {
          fetched: true,
          investmentMaterials: data.investmentMaterials ?? [],
          error: error
        };
        
        setState(fetchedInvestmentMaterials);

      } else {
        console.error(error);
      }
    }

    useEffect(() => {
        let isMounted = true;
    
        const fetchInvestmentMaterials = async () => {
          if (!isMounted) return;    
          reloadInvestmentMaterials();
        };
    
        fetchInvestmentMaterials(); 
    
        return () => { isMounted = false };
    }, []);

    return { ...state, reloadInvestmentMaterials};
};
