import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, styled, TableProps, Dialog } from "@mui/material";
import { DealExecutive } from "./DealExecutive";
import { DealCompany } from "./DealCompany";
import { useEffect, useReducer, useState } from "react";

const ProspectTable = styled(Table)<TableProps>(({ theme }) => ({
    borderCollapse: "separate",
    borderSpacing: "0 0.5rem",
    marginBottom: "60px",
    "& th": {
        fontWeight: 700,
        fontSize: "1rem",
        borderBottom: "1px solid #000000",
        "&.td-sm": {
            width: "10%"
        },
        "&.td-md": {
            width: "20%"
        },
        "&.td-lg": {
            width: "30%"
        },
        "&.sortable": {
            cursor: "pointer"
        }
    },
    "& td": {
        fontSize: "1rem",
        "&.action": {
            fontSize: "1.5rem",
            cursor: "pointer",
        }
    },
    "& .td-center": {
        textAlign: "center",
    }
}));

const ProspectHeading = styled("h5")(({ theme }) => ({
    fontSize: "1.5rem",
    margin: "1rem 0",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    "& span": {
        fontSize: "1rem",
        marginLeft: "1rem",
        color: "#000000",
        backgroundColor: "#c9c9c9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.5rem",
        width: "35px",
        height: "35px",
        borderRadius: "50%"
    }
}));

const ProspectStaging = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#f8f9fa",
    borderTop: "1px solid #000000",
    padding: "40px",
    marginBottom: "60px",
    "& p": {
        padding: "30px",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: "1px solid #c9c9c9"
    }
}));

type TViewpointsState = {
    viewpoints: any[];
    filteredViewpoints: any[];
};

const demandOptions = [
    {
        value: "Yes, definitely",
        label: "Very High"
    },
    {
        value: "Yes, probably",
        label: "High"
    },
    {
        value: "Maybe",
        label: "Medium"
    },
    {
        value: "No, probably not",
        label: "Low"
    },
    {
        value: "No, definitely not",
        label: "Very Low"
    }
]

const roleOptions = [
    {
        value: "Decide",
        label: "DECIDE"
    },
    {
        value: "Recommend",
        label: "RECOMMEND"
    },
    {
        value: "Evaluate",
        label: "EVALUATE"
    },
    {
        value: "Finance",
        label: "FINANCE"
    },
    {
        value: "Implement",
        label: "IMPLEMENT"
    },
    {
        value: "None",
        label: "NONE"
    }
]


const viewpointsReducer = (state: TViewpointsState, action: any) => {
    switch (action.type) {
        case 'SORT':
            const { sortCriteria } = action.payload;
            const filteredViewpoints = state.viewpoints;
            return {
                ...state,
                filteredViewpoints: filteredViewpoints.sort((a: any, b: any) => {
                    let aVal;
                    let bVal;

                    switch(sortCriteria.field) {
                        case 'demand':
                            aVal = demandOptions.findIndex((option) => option.value === a[sortCriteria.field]);
                            bVal = demandOptions.findIndex((option) => option.value === b[sortCriteria.field]);
                            break;
                        case 'role':
                            aVal = roleOptions.findIndex((option) => option.value === a[sortCriteria.field]);
                            bVal = roleOptions.findIndex((option) => option.value === b[sortCriteria.field]);
                            break;
                        case 'company':
                            aVal = !a[sortCriteria.field] || /^[^a-z]/i.test(a[sortCriteria.field]) ? 'zzzzzzz' : a[sortCriteria.field].toLowerCase();
                            bVal = !b[sortCriteria.field] || /^[^a-z]/i.test(b[sortCriteria.field]) ? 'zzzzzzz' : b[sortCriteria.field].toLowerCase();
                            break;
                        default:
                            aVal = a[sortCriteria.field];
                            bVal = b[sortCriteria.field];
                    }

                    if (sortCriteria.direction === 'asc') {
                        return aVal > bVal ? 1 : -1;
                    } else {
                        return aVal < bVal ? 1 : -1;
                    }
                })
            };
            break;
        default:
            return state;
    }
}


export const DealProspectsListList = ({ project, viewpoints, user, prospectStage }: any) => {

    const viewpointsList = viewpoints?.filter((viewpoint: any) => {
        switch (prospectStage) {
            case "Requested Demo":
                return viewpoint.interest_demo && !viewpoint.interest_beta;
            case "Requested Trial":
                return viewpoint.interest_beta;
            case "Engaged":
                return viewpoint.interest_engaged;
            default:
                return false;
        }
    });

    const [state, dispatch] = useReducer(viewpointsReducer, { viewpoints: viewpointsList, filteredViewpoints: viewpointsList });
    const [sortCriteria, setSortCriteria] = useState<{ field: string, direction: string }>({ field: 'demand', direction: 'asc' });
    const [introDialogOpen, setIntroDialogOpen] = useState(false);

    const handleSortChange = (event: any) => {
        const { name, value } = event.target;
        setSortCriteria({ field: name, direction: value });
    }

    useEffect(() => {
        dispatch({
            type: 'SORT',
            payload: {
                sortCriteria
            }
        });
    }, [sortCriteria]);

    if (!viewpointsList?.length) {
        return (
            <Box>
                <ProspectHeading>{prospectStage} <span>0</span></ProspectHeading>
                <ProspectStaging>
                    <p>Request introductions to the companies you want to engage</p>
                </ProspectStaging>
            </Box>
        );
    }

    return (
        <Box>
            <ProspectHeading>{prospectStage} <span>{viewpointsList.length}</span></ProspectHeading>
            <ProspectTable>
                <TableHead>
                    <TableRow>
                        <TableCell className="td-sm td-center sortable" onClick={() => handleSortChange({ target: { name: 'company', value: sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Company</TableCell>
                        <TableCell className="td-lg">Contact</TableCell>
                        <TableCell className="td-sm td-center sortable" onClick={() => handleSortChange({ target: { name: 'role', value: sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Role</TableCell>
                        <TableCell className="td-sm td-center sortable" onClick={() => handleSortChange({ target: { name: 'demand', value: sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Purchasing Intent</TableCell>
                        <TableCell className="td-sm td-center sortable" onClick={() => handleSortChange({ target: { name: 'max_spend', value: sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Max Annual Spend</TableCell>
                        <TableCell className="td-sm td-center">Intro</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.filteredViewpoints?.map((viewpoint: any) => (
                        <TableRow key={viewpoint.id}>
                            <TableCell>
                                <DealCompany viewpoint={viewpoint} />
                            </TableCell>
                            <TableCell>
                                <DealExecutive project={project} viewpoint={viewpoint} style="prospect-list" user={user} />
                            </TableCell>
                            <TableCell className="td-center">{viewpoint.role}</TableCell>
                            <TableCell className="td-center">{demandOptions.find((option) => option.value === viewpoint?.demand)?.label}</TableCell>
                            <TableCell className="td-center">${viewpoint.max_spend?.toLocaleString()}</TableCell>
                            <TableCell className="td-center action" onClick={() => setIntroDialogOpen(true)}><FontAwesomeIcon icon="plus"/></TableCell>
                            </TableRow>
                    ))}
                </TableBody>
            </ProspectTable>
            <Dialog open={introDialogOpen} onClose={() => setIntroDialogOpen(false)}>
                <Box>
                    <h5>Request an Introduction</h5>
                    <p>Please contact <a href="mailto:hello@brightloop.ai">hello@brightloop.ai</a> to enable this feature.</p>
                </Box>
            </Dialog>
        </Box>

    )

}
