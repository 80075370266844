import { ReactElement, Ref, forwardRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetRespondentHtml from "../../../hooks/useGetRespondentHtml";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, BoxProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, IconButton, Modal, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import zIndex from "@mui/material/styles/zIndex";

type TRespondent = {
  project: any, 
  respondent: any,
  handleRespondant: any
};

const scroll: DialogProps['scroll'] = 'paper';

const BootstrapDialog = styled(Dialog)(() => ({
  zIndex: 2500,
  '& .MuiDialogContent-root': {
    padding: '3%',
  },
  '& .MuiPaper-root': {
    position: 'initial',
    maxWidth: '900px'
  },
}));

const ViewpointBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  overflowY: "hidden",
  backgroundColor: "#ffffff",
  padding: "40px 20px 20px",
  borderRadius: "20px",
  maxWidth: "1200px",
  "& #responseMeta": {
    padding: "0 20px",
  },
  "& #navTabs": {
      borderBottom: "1px solid #c9c9c9",
      "& .navTab": {
          border: "1px solid #ffffff",
          borderBottom: "none",
          color: "rgba(0, 0, 0, 0.6)",
          "&:hover": {
              color: "#000000"
          }
      },
      "& .navTab.selected": {
          backgroundColor: "#f8f9fa",
          border: "1px solid #c9c9c9",
          borderBottom: "none",
          color: "#000000",
      },
  },
  "& #contentContainer": {
      backgroundColor: "#ffffff",
      "& .answerSummary": {
          padding: 0
      }
  },
  "& .executive-profile": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "start",
      marginBottom: "40px",
      gap: "20px",
      padding: "20px",
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Viewpoint = ({project, respondent, handleRespondant}: TRespondent) => {
  const [respondentHtml] = useGetRespondentHtml(respondent.id, project);

  const [isPrintView, setisPrintView] = useState(false);

  const handleViewSwitchClick = () => {
    if (!isPrintView) {
      Array.from(document.getElementsByClassName("sectionContainer")).forEach((element) => {
        const sectionName = element.getAttribute("id");
        const titleDiv = document.createElement("div");
        titleDiv.classList.add("project-section-title");
        titleDiv.innerHTML = sectionName ? sectionName : '';
        element.prepend(titleDiv);
      });
      
      document.getElementsByClassName("respondent-page")[0].classList.add("print-view");
      document.getElementsByClassName("print-view-toggle")[0].classList.add("depressed");
      setisPrintView(true);

    } else {
      document.getElementsByClassName("respondent-page")[0].classList.remove("print-view");
      Array.from(document.getElementsByClassName("project-section-title")).forEach((element: any) => {
        element.parentNode.removeChild(element);
      });
      document.getElementsByClassName("print-view-toggle")[0].classList.remove("depressed");
      setisPrintView(false);
    };
  }

  return (
   
                    <Modal open={respondent.isOpen} onClose={handleRespondant}>

      <ViewpointBox>
      <Box className="executive-content" sx={{ overflowY: "scroll", height: "100%" }}>

        <IconButton
            aria-label="close"
            onClick={handleRespondant}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
        >
          <CloseIcon />
        </IconButton>
        <div className="dialog-content">
          <div dangerouslySetInnerHTML={respondentHtml} />
        </div>
        </Box>
      </ViewpointBox>
    </Modal>
  );
};
