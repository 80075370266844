import { useAuth0 } from "@auth0/auth0-react";
import { loadStats } from "../services/gutcheck-admin-api.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const AdminReloadStats = (project: any) => {

    const [loading, setLoading] = useState(false);

    const { getAccessTokenSilently, user } = useAuth0();
    const handleReloadStats = async (projectId: any) => {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const updatedProject = await loadStats(projectId, accessToken);
        setLoading(false);
    }

    if (user && user['https://gutcheck.svpvc.com/roles']?.includes('admin')) {
        return (
            <div className="refresh-viewpoints" onClick={() => handleReloadStats(project.project.id)}><FontAwesomeIcon icon="rotate-right" onClick={() => handleReloadStats} spin={loading} /></div>
        );
    }
}