import { Container, Row, Col } from "reactstrap";

import Loading from "../../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useUserContext } from "../context";
import { UserProfileImage } from "../components";

export const ProfileIndex = () => {
  const { user } = useAuth0();
  const { userContext, setUserContext } = useUserContext();

  return user ? (
    <Container className="md-7">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <UserProfileImage user={userContext.dbUser} size="large"/>
        <Col md>
          <h2>{userContext.dbUser?.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Row>
      </Row>
    </Container>
  ) : '';
};
