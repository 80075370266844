import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { acceptInvitation } from "../../../core/services/gutcheck-api.service";

export const InvitationAccept = () => {
    const { invitation, loading } = useOutletContext<{ invitation: any, loading: boolean }>();

    const navigate = useNavigate();

    const {
        isLoading,
        isAuthenticated,
        user,
        getAccessTokenSilently
    } = useAuth0();

    useEffect(() => {

        let isMounted = true;

        const acceptInvitationAttempt = async () => {

            const accessToken = await getAccessTokenSilently();

            if (!isMounted) return;

            if (invitation?.invitation_code !== "") {
                const { data, error } = await acceptInvitation(invitation?.invitation_code, accessToken);

                if (!error) {
                    localStorage.removeItem("invcode");
                    if ( invitation?.project && invitation?.project?.experience === "winning" ) {
                        return navigate(`/o/${invitation?.organization?.tag}/d/${invitation?.project?.tag}`, { replace: true, state: { invitationAccepted: true }})
                    } else if (invitation?.project) {
                        return navigate(`/o/${invitation?.organization?.tag}/project/${invitation?.project?.tag}`, { replace: true, state: { invitationAccepted: true }})
                    } else {
                        return navigate(`/o/${invitation?.organization?.tag}`, { replace: true, state: { invitationAccepted: true }})
                    }
                }
            }
        };

        if(!loading) {
            acceptInvitationAttempt();
        }

        return () => { isMounted = false };
    }, [loading, invitation]);

    if (loading) return (<></>);

    return (
        <div>
        </div>
    )
};
