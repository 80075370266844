import { getDealUpdates } from "../../../core/services/gutcheck-api.service";
import { useEffect, useState } from "react";

export const DealUpdates = ({ surveyResponse }: any) => {

    const [dealUpdates, setDealUpdates] = useState([]);

    useEffect(() => {
        const fetchDealUpdates = async () => {
            const { data, error } = await getDealUpdates(surveyResponse.public_id);
            setDealUpdates(data.dealUpdates);
        }
        fetchDealUpdates();
    }, []);

    if (!dealUpdates || dealUpdates.length === 0) return <></>;
    
    return (
        <div className="investment-deal-updates">
            <h5>Deal Updates</h5>
            <div>
                {dealUpdates.map((dealUpdate: any) => (
                    <div key={dealUpdate.id} className="deal-update">
                        <div >
                            <p className="deal-update-title">{dealUpdate.title}</p>
                            <p className="deal-update-body">{dealUpdate.body}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );


}