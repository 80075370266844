import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AdminIndex = () => {

  const navigate = useNavigate();

  useEffect(() => {
    return navigate('/admin/projects');
  }, []);

    return (
      <Fragment>
        Admin Index
      </Fragment>
    );

}
