import { useOutletContext } from "react-router-dom";
import { DealTitle } from "../components/DealTitle";
import { DealExecutivesList } from "../components/DealExecutivesList";
import { DealOverviewStats } from "../components/DealOverviewStats";
import { DealLeads } from "../components/DealLeads";

export const DealOverview = () => {
    const { project, organization, viewerRole, user } = useOutletContext<{ project: any, organization: any, viewerRole: any, user: any }>();
    return (
        <div>
            { project.viewpoints.length > 0 ?
                <DealTitle project={project} section="Meet Your Executives" subheading="So far, they've generated ..." />
                :
                <DealTitle project={project} section="We're Finding Executives" subheading="And will let you know when they start weighing in ..." />
            }
            { project?.viewpoints.length > 0 && <DealOverviewStats project={project} /> }
            <DealExecutivesList project={project} viewpoints={project?.viewpoints} user={user} />
            <DealLeads project={project} />
        </div>
    );
}   