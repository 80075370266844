import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createProject, uploadAsset } from "../../../core/services/gutcheck-api.service";
import ServiceProvider from "../../../modules/ServiceProvider";
import { CreateForm, CreateProjectHeader, CreateProjectHero, CreateSideForm } from "../components/project_form_components";
import { CreateInvestorForm, CreateInvestorProjectHero, CreateButtonForm, CreateInvestorProjectHeader, CreateInvestorSideForm } from "../components/project_investor_form_components";
import ProjectFormData from "../types/ProjectFormData";
import Loading from "../../../components/Loading";
import UserNotFound from "../../../components/UserNotFound";
import useGetUserData from "../../../hooks/useGetUserData";
import { v4 } from "uuid";
import LoadingOverlay from 'react-loading-overlay-ts';
import userAnalytics from "../../../hooks/useAnalytics";

const fixForm = (form: ProjectFormData) => {
  const createAttrs = (attrs: any) => {
    return attrs.map((val: string) => {
      return {
        value: val,
        label: val
      }
    });
  };

  return {
    ...form,
    goals: createAttrs(form.goals)
  };
};

const userTypes = [
  {
    id: "investor",
    name: "Investor",
    description: "Looking to use feedback to validate an opportunity"

  },
  {
    id: "entrepreneur",
    name: "Entrepreneur",
    description: "Looking to use feedback to vet a new product or venture"

  },
  {
    id: "marketer",
    name: "Marketer",
    description: "Looking to use feedback to improve marketing collateral"

  }
];

export const CreateProject = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { trackEvent } = userAnalytics();

  const navigate = useNavigate();

  const { organizationTag } = useParams();

  const initState: ProjectFormData = {
    name: "",
    user_type: "",
    feedback_type: "",
    type: "",
    asset_text: "",
    asset_url: "",
    asset_file_id: "",
    file: null,
    goals: [],
    target_audience: "",
    key_questions: "",
    iteration_number: 0,
    error_message: '',
    form_step: 0,
    focus_section: '',
    completion_date: null,
    lead_list: null,
    distinction: null,
    market_size: null,
    is_closed: false,
    is_archived: false,
    graph_url: '',
    public_title: '',
    spv_platform_url: '',
    round: '',
    amount_raising: 0,
    amount_premoney_valuation: 0,
    share_class: '',
    pro_rata_rights: false,
    spv_allocation: 0,
    spv_estimated_fees: 0,
    spv_leads_investment: 0,
    spv_total_carry: 0,
    spv_management_fee: '',
    spv_minimum_investment: 0,
    investor_deck: '',
    deal_terms_url: '' ,
    auto_publish: true,
    distinction_question: '',
    distinction_options: '',
    evaluation_delay_hours: 48,
    spv_platform_name: ''
  };

  const initQueuedFiles: File[] = [];

  const [form, setForm] = useState(initState);
  const [queuedFiles, setQueuedFiles] = useState(initQueuedFiles);
  const [activeInput, setActiveInput] = useState("asset_file_id");
  const [isUploading, setIsUploading] = useState(false);

  const [fetched, user, organizationMemberships] = useGetUserData();
  //const organization = useLocation().state.organization;
  if (!fetched) return <Loading />

  if (fetched && !user) return <UserNotFound />

  const organization = organizationMemberships.find((organizationMembership: any) => organizationMembership.organization.tag === organizationTag)?.organization;

  if (user.defaultType == null || user.defaultType === "") {
    navigate('/profile/complete', { state: { organization: organization }, replace: true });
    return;
  } else if (form.user_type !== user.defaultType) {
    setForm({
      ...form,
      user_type: user.defaultType
    });
  }

  const handleChangeInput = (event: any, input: string) => {
    setActiveInput(input);
  }

  const handleSave = async (event: any) => {
    if ((user.defaultType === "investor" || user.defaultType === "entrepreneur") && form.goals && form.goals.length === 0) {
      form.goals.push("Demand");
    }

    if (form.name === "") {
      setForm({
        ...form,
        error_message: "A project name is required."
      });
      return;
    }

    if (form.goals && form.goals.length > 3) {
      setForm({
        ...form,
        error_message: "Select up to three metrics."
      });
      return;
    }

    try {
      event.preventDefault();
      setIsUploading(true);

      const projectForm = form;
      projectForm.asset_text = (projectForm.user_type === "marketer" || activeInput === "asset_text") ? projectForm.asset_text : "";
      projectForm.asset_url = activeInput === "asset_url" ? projectForm.asset_url : "";
      projectForm.asset_file_id = activeInput === "asset_file_id" ? projectForm.asset_file_id : "";

      if (activeInput === "asset_file_id" && queuedFiles.length > 0) {
        const fileId = v4() + queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, "");
        projectForm.asset_file_id = fileId;
      }

      const accessToken = await getAccessTokenSilently();
      const { data, error } = await createProject(organization.id, fixForm(projectForm), accessToken);

      const project = data.project;
      trackEvent("saved project", { organization: organization.tag, project: project.tag });

      if (activeInput === "asset_file_id" && queuedFiles.length > 0) {
        const { data, error } = await uploadAsset(queuedFiles[0], organization.tag, project?.tag, projectForm.asset_file_id, accessToken);
      }

      if (error) throw error;

      navigate(`/o/${organization.tag}/`);
      return;
    } catch (error) {
      setIsUploading(false);
      console.error(error);
    };
  };

  const handleStart = async (event: any) => {

    if ((user.defaultType === "investor" || user.defaultType === "entrepreneur") && form.goals && form.goals.length === 0) {
      form.goals.push("Demand");
    }

    if (form.name === "" || form.type === "" || (form.asset_text === "" && form.asset_url === "" && form.asset_file_id === "" && queuedFiles.length == 0) || (form.goals && form.goals.length === 0) || form.target_audience === "") {
      setForm({
        ...form,
        error_message: "All fields are required to start your project."
      });
      return;
    }

    if (form.goals && form.goals.length > 3) {
      setForm({
        ...form,
        error_message: "Select up to three metrics."
      });
      return;
    }

    try {
      event.preventDefault();

      setIsUploading(true);
      const projectForm = form;
      projectForm.asset_text = (projectForm.user_type === "marketer" || activeInput === "asset_text") ? projectForm.asset_text : "";
      projectForm.asset_url = activeInput === "asset_url" ? projectForm.asset_url : "";
      projectForm.asset_file_id = activeInput === "asset_file_id" ? projectForm.asset_file_id : "";

      if (activeInput === "asset_file_id" && queuedFiles.length > 0) {
        const fileId = v4() + queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, "");
        projectForm.asset_file_id = fileId;
      }

      const accessToken = await getAccessTokenSilently();
      const { data, error } = await createProject(organization.id, fixForm(projectForm), accessToken);

      const project = data.project;

      trackEvent("started project", { organization: organization.tag, project: project.tag });

      if (activeInput === "asset_file_id" && queuedFiles.length > 0) {
        const { data, error } = await uploadAsset(queuedFiles[0], organization.tag, project?.tag, projectForm.asset_file_id, accessToken);
      }

      if (error) throw error;

      await ServiceProvider
        .provideProjectService()
        .start(data.project.id, accessToken);

        navigate(`/o/${organization.tag}/project/${data.project.tag ? data.project.tag : "no-name"}`, { state: { project: data.project } });
        return;
    } catch (error) {
      setIsUploading(false);
      setForm({
        ...form,
        error_message: "Something went wrong"
      })
      console.error(error);
    };
  };

  const handleUserTypeChange = () => {
    navigate(`/profile/complete`, { state: { organization: organization } });
    return;
  }

  return (
    <main className="page-main">
      <div className="gc_view">
        <LoadingOverlay
          active={isUploading}
          spinner
          text='Uploading...'
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.75)'
            }),
            spinner: (base) => ({
              ...base,
              width: '300px',
              '& svg circle': {
                stroke: '#feb70f'
              }
            }),
            content: (base) => ({
              ...base,
              color: '#141414',
              position: 'relative',
              top: '-300px'
            })
          }}
        >
          <div className="splash-page investor">
            <CreateInvestorProjectHero userType={form.user_type} />
            <div className="user-type">Current role: {user.defaultType.charAt(0).toUpperCase() + user.defaultType.slice(1)} <span onClick={handleUserTypeChange}>(change)</span></div>

            <CreateProjectHeader form={form} setForm={setForm} />

            <div className={`splash-container ${form.name === "" && "hidden"}`}>
              <div className="splash-view investor-form">
                <CreateInvestorForm userType={user.defaultType} form={form} setForm={setForm} queuedFiles={queuedFiles} setQueuedFiles={setQueuedFiles} activeInput={activeInput} setActiveInput={setActiveInput} handleChangeInput={handleChangeInput} />
                <CreateButtonForm form={form} handleSave={handleSave} handleStart={handleStart} />
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    </main>
  )

};
