import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getProjects } from "../services/gutcheck-admin-api.service";

type TProjects = {
    projects: any | null
}

const initState: TProjects = {
    projects: null,
}

const useGetProjects = () => {
    const [ state, setState ] = useState(initState);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            const response = await getProjects(accessToken);

            if (response) {
                setState({
                    ...state,
                    projects: response.data.projects,
                });
            };
        };

        fetchData();

        return () => {};
    }, []);

    return [state.projects];
};

export default useGetProjects;