import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProjectMembers } from "../services/gutcheck-admin-api.service";

type ProjectMembersData = {
    fetched: boolean;
    members: any;
    error: { message: any } | null;
}

const initState: ProjectMembersData = {
    fetched: false,
    members: [],
    error: null
}

export const useGetProjectMembers = (projectId: number) => {


    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState(initState);

    const reloadProjectMembers = async () => {
        setState({
            ...state,
            fetched: false
        });

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getProjectMembers(projectId, accessToken);

        if (data) {
            const fetchedProjectMembers: ProjectMembersData = {
                fetched: true,
                members: data.members ?? [],
                error: error
            };

            setState(fetchedProjectMembers);

        } else {
            console.error(error);
        }
    }

    useEffect(() => {
        let isMounted = true;

        const fetchProjectMembers = async () => {
            if (!isMounted) return;
            reloadProjectMembers();
        };

        fetchProjectMembers();

        return () => { isMounted = false };
    }, []);

    return { ...state, reloadProjectMembers };
};
