import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutlet, useOutletContext } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { loadProject, resetProject, loadPrompts, collectFeedback, measurePerformance, extractInsights, generateSuggestions, generateRevision, extractHighlights, savePrompt } from "../services/ai-chain-service";
import { ControlBox } from "./widgets/admin-aichain-widgets";
import useAdminGetProjectData from "../hooks/useAdminGetProjectData";
import TextViewer from "../../../components/TextViewer";


export const AdminProjectAi = () => {
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const { project } = useOutletContext<{ project: any }>();

    const [state, setState] = useState({
        aiProject: {
            assetText: "",
            responseJson: "",
            insights: "",
            suggestions: "",
            revisions: [],
            highlights: "",
            performance: {}
        },
        prompts: [],
        error: false,
        loading: ""
    });

    const [insightsForm, setInsightsForm] = useState({
        promptKey: "",
        promptVersion: "",
        promptOriginal: "",
        promptActive: "",
        temperature: 0.25,
        formattedPrompt: ""
    });

    const [suggestionsForm, setSuggestionsForm] = useState({
        promptKey: "",
        promptVersion: "",
        promptOriginal: "",
        promptActive: "",
        temperature: 0.25,
        formattedPrompt: ""
    });

    const [revisionForm, setRevisionForm] = useState({
        promptKey: "",
        promptVersion: "",
        promptOriginal: "",
        promptActive: "",
        temperature: 0.25,
        formattedPrompt: ""
    });

    const [highlightsForm, setHighlightsForm] = useState({
        promptKey: "",
        promptVersion: "",
        promptOriginal: "",
        promptActive: "",
        temperature: 0.25,
        formattedPrompt: ""
    });

    const FormatText = ({ text, section }: any) => {
        if (section === "suggestions" && /^```json/.test(text ?? "")) {
            const data = JSON.parse(text.replace(/(```json|```)/g, ""));
            return (
                <div>
                    {Object.keys(data).map((key: any) => {
                        return (
                            <div>
                                <strong>{key}</strong>
                                <ol>
                                    {data[key].map((entry: any) => {
                                        return (
                                            <li><strong>{entry.title}</strong><br />{entry.description}</li>
                                        );
                                    })}
                                </ol>
                            </div>
                        )
                    })}
                </div>
            )

        } else if (/<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(text ?? "")) {
            return (
                <TextViewer content={text ?? ""} />
            );
        } else {
            const paragraphs = text.split(/\n/);
            return (
                <div>
                    {paragraphs.map((paragraph: string) => {
                        if (paragraph !== "") {
                            return (
                                <p>{paragraph}</p>
                            );
                        } else {
                            return (
                                <br />
                            );
                        }
                    })}
                </div>
            );
        }
    }

    const handleConnect = async () => {

        setState({
            ...state,
            loading: "connect"
        });

        const accessToken = await getAccessTokenSilently();

        const loadResults = await loadProject(project, accessToken);
        const promptResults = await loadPrompts(accessToken);

        if (loadResults.error) {
            setState({
                ...state,
                error: true

            });
        } else {
            setState({
                ...state,
                aiProject: loadResults.data.project,
                prompts: promptResults.data.prompts,
                loading: ""
            });

            const insightsPrompt = promptResults.data.prompts.find((p: any) => p.key === "insights_universal");
            const suggestionsPrompt = promptResults.data.prompts.find((p: any) => p.key === "suggestions_universal");
            const revisionPrompt = promptResults.data.prompts.find((p: any) => p.key === "revision_universal");
            const highlightsPrompt = promptResults.data.prompts.find((p: any) => p.key === "highlights_universal");

            setInsightsForm({
                ...insightsForm,
                promptKey: insightsPrompt.key,
                promptVersion: insightsPrompt.version,
                promptOriginal: insightsPrompt.text,
                promptActive: insightsPrompt.text
            });

            setSuggestionsForm({
                ...suggestionsForm,
                promptKey: suggestionsPrompt.key,
                promptVersion: suggestionsPrompt.version,
                promptOriginal: suggestionsPrompt.text,
                promptActive: suggestionsPrompt.text
            });

            setRevisionForm({
                ...revisionForm,
                promptKey: revisionPrompt.key,
                promptVersion: revisionPrompt.version,
                promptOriginal: revisionPrompt.text,
                promptActive: revisionPrompt.text
            });

            setHighlightsForm({
                ...highlightsForm,
                promptKey: highlightsPrompt.key,
                promptVersion: highlightsPrompt.version,
                promptOriginal: highlightsPrompt.text,
                promptActive: highlightsPrompt.text
            });

        }
    }

    const handleProjectReset = async () => {
        setState({
            ...state,
            loading: "connect"
        });

        const accessToken = await getAccessTokenSilently();

        const loadResults = await resetProject(project, accessToken);
        const promptResults = await loadPrompts(accessToken);

        if (loadResults.error) {
            setState({
                ...state,
                error: true

            });
        } else {
            setState({
                ...state,
                aiProject: loadResults.data.project,
                prompts: promptResults.data.prompts,
                loading: ""
            });

            const insightsPrompt = promptResults.data.prompts.find((p: any) => p.key === "insights_universal");
            const suggestionsPrompt = promptResults.data.prompts.find((p: any) => p.key === "suggestions_universal");
            const revisionPrompt = promptResults.data.prompts.find((p: any) => p.key === "revision_universal");
            const highlightsPrompt = promptResults.data.prompts.find((p: any) => p.key === "highlights_universal");

            setInsightsForm({
                ...insightsForm,
                promptKey: insightsPrompt.key,
                promptVersion: insightsPrompt.version,
                promptOriginal: insightsPrompt.text,
                promptActive: insightsPrompt.text
            });

            setSuggestionsForm({
                ...suggestionsForm,
                promptKey: suggestionsPrompt.key,
                promptVersion: suggestionsPrompt.version,
                promptOriginal: suggestionsPrompt.text,
                promptActive: suggestionsPrompt.text
            });

            setRevisionForm({
                ...revisionForm,
                promptKey: revisionPrompt.key,
                promptVersion: revisionPrompt.version,
                promptOriginal: revisionPrompt.text,
                promptActive: revisionPrompt.text
            });

            setHighlightsForm({
                ...highlightsForm,
                promptKey: highlightsPrompt.key,
                promptVersion: highlightsPrompt.version,
                promptOriginal: highlightsPrompt.text,
                promptActive: highlightsPrompt.text
            });
        }
    }

    const handleCollect = async () => {

        if (state.aiProject) {
            setState({
                ...state,
                loading: "feedback"
            });

            const accessToken = await getAccessTokenSilently();
            const results = await collectFeedback(state.aiProject, accessToken);
            setState({
                ...state,
                aiProject: results.data.project,
                loading: ""
            });
        }
    }

    const handlePerformance = async () => {

        if (state.aiProject) {
            setState({
                ...state,
                loading: "performance"
            });

            const accessToken = await getAccessTokenSilently();
            const results = await measurePerformance(state.aiProject, accessToken);
            setState({
                ...state,
                aiProject: results.data.project,
                loading: ""
            });
        }
    }

    const handleInsights = async () => {

        if (state.aiProject) {
            setState({
                ...state,
                loading: "insights"
            });

            const accessToken = await getAccessTokenSilently();
            const results = await extractInsights(state.aiProject, insightsForm, accessToken);
            setState({
                ...state,
                aiProject: results.data.project,
                loading: ""
            });
            setInsightsForm({
                ...insightsForm,
                formattedPrompt: results.data.llmResponse.formattedPrompt
            });
        }
    }

    const handleSuggestions = async () => {

        if (state.aiProject) {
            setState({
                ...state,
                loading: "suggestions"
            });

            const accessToken = await getAccessTokenSilently();
            const results = await generateSuggestions(state.aiProject, suggestionsForm, accessToken);
            setState({
                ...state,
                aiProject: results.data.project,
                loading: ""
            });
            setSuggestionsForm({
                ...suggestionsForm,
                formattedPrompt: results.data.llmResponse.formattedPrompt
            });
        }
    }

    const handleRevision = async () => {

        if (state.aiProject) {
            setState({
                ...state,
                loading: "revision"
            });

            const accessToken = await getAccessTokenSilently();
            const results = await generateRevision(state.aiProject, revisionForm, accessToken);
            setState({
                ...state,
                aiProject: results.data.project,
                loading: ""
            });
            setRevisionForm({
                ...revisionForm,
                formattedPrompt: results.data.llmResponse.formattedPrompt
            });
        }
    }

    const handleHighlights = async () => {

        if (state.aiProject) {
            setState({
                ...state,
                loading: "highlights"
            });

            const accessToken = await getAccessTokenSilently();
            const results = await extractHighlights(state.aiProject, highlightsForm, accessToken);
            setState({
                ...state,
                aiProject: results.data.project,
                loading: ""
            });
            setHighlightsForm({
                ...highlightsForm,
                formattedPrompt: results.data.llmResponse.formattedPrompt
            });
        }
    }

    const handleSubmit = async ({ stage, form, func }: any) => {
        if (state.aiProject) {
            setState({
                ...state,
                loading: stage
            });

            const results = await func(state.aiProject, form);
            setState({
                ...state,
                aiProject: results.data.project,
                loading: ""
            });
        }
    }

    const ProjectDetails = ({ project }: any) => {
        if (state.loading === "connect") {
            return (
                <div className="results-output">
                    Loading...
                </div>
            )
        } else if (project && project.assetText) {
            return (
                <div className="results-output">
                    <FormatText text={project.assetText} />
                    <div>
                        <p><span className="bold">Asset Type</span><br />
                            {project.assetType}</p>
                        <p><span className="bold">Goals</span><br />
                            {project.goalText}</p>
                        <p><span className="bold">Target Audience</span><br />
                            {project.audienceText}</p>
                        <p><span className="bold">Guardrails</span><br />
                            {project.guardrailsText}</p>
                    </div>
                </div>
            )
        } else {
            return (<div className="results-output"></div>)
        }
    }


    const FeedbackDetails = ({ project }: any) => {
        if (state.loading === "feedback") {
            return (
                <div className="results-output">
                    Loading...
                </div>
            )
        } else if (project && project.responseJson) {
            const feedbackData = JSON.parse(project.responseJson);
            return (
                <div className="results-output">
                    {feedbackData.responses.length} responses
                </div>
            )
        } else {
            return (<div className="results-output"></div>)
        }
    }

    const PerformanceDetails = ({ project }: any) => {
        if (state.loading === "performance") {
            return (
                <div className="results-output">
                    Loading...
                </div>
            )
        } else if (project && project.performance && Object.keys(project.performance).length) {
            return (
                <div className="results-output">
                    <p>Demand Top Box: {project.performance.topBox.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</p>
                    <p>Demand Top Two Box: {project.performance.topTwoBox.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</p>
                </div>
            )
        } else {
            return (<div className="results-output"></div>)
        }
    }

    const InsightDetails = ({ project }: any) => {
        if (state.loading === "insights") {
            return (
                <div className="results-output">
                    Loading...
                </div>
            )
        } else if (project.insights) {
            const data = JSON.parse(project.insights.replace(/(```json|```)/g, ""));
            const feedbackData = JSON.parse(project.responseJson);
            return (
                <div className="results-output">
                    <h4>Market Insights</h4>
                    <p>Feedback was collected from {feedbackData.responses.length} mobile app users in the United States, between the ages of 18-60 years old. Here's what they had to say:</p>
                    <strong>What They Liked Most</strong>
                    <ol>
                        {data["Stack Ranked Benefits"].map((entry: any) => {
                            return (
                                <li><strong>{entry.title}</strong><br />{entry.description}</li>
                            );
                        })}
                    </ol>
                    <strong>Their Top Concerns</strong>
                    <ol>
                        {data["Specific Concerns"].map((entry: any) => {
                            return (
                                <li><strong>{entry.title}</strong><br />{entry.description}</li>
                            );
                        })}
                    </ol>
                    <strong>Other Concerns They Mentioned</strong>
                    <ol>
                        {data["General Concerns"].map((entry: any) => {
                            return (
                                <li><strong>{entry.title}</strong><br />{entry.description}</li>
                            );
                        })}
                    </ol>
                </div>
            )
        } else {
            return (<div className="results-output"></div>)
        }
    }

    const SuggestionDetails = ({ project }: any) => {
        if (state.loading === "suggestions") {
            return (
                <div className="results-output">
                    Loading...
                </div>
            )
        } else if (project && project.suggestions) {
            return (
                <div className="results-output">
                    <h4>Suggested Edits and Ideas</h4>
                    <p>These are suggestions on how to reinforce the strengths and address the concerns identified by market feedback in order to {project?.goalText?.replace(/, /g, " and ").toLowerCase()}.</p>
                    <FormatText text={project.suggestions} section="suggestions" />
                </div>
            )
        } else {
            return (<div className="results-output"></div>)
        }
    }

    const RevisionDetails = ({ project }: any) => {
        if (state.loading === "revision") {
            return (
                <div className="results-output">
                    Loading...
                </div>
            )
        } else if (project && project.revisions && project.revisions.length > 0) {
            return (
                <div className="results-output">
                    <FormatText text={project.revisions[project.revisions.length - 1]} section="revisions"/>
                </div>
            )
        } else {
            return (<div className="results-output"></div>)
        }
    }

    const HighlightsDetails = ({ project }: any) => {
        if (state.loading === "highlights") {
            return (
                <div className="results-output">
                    Loading...
                </div>
            )
        } else if (project.highlights) {
            return (
                <div className="results-output">
                    <FormatText text={project.highlights} section="highlights" />
                </div>
            )
        } else {
            return (<div className="results-output"></div>)
        }
    }

    const handleChange = (event: any, form: any, setForm: any) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };

    const handleReset = (event: any, form: any, setForm: any) => {
        setForm({
            ...form,
            promptActive: form.promptOriginal
        });
    };

    const handleSave = async (event: any, form: any, setForm: any) => {

        const accessToken = await getAccessTokenSilently();
        const newPrompt = await savePrompt({ key: form.promptKey, text: form.promptActive }, accessToken);
        if (newPrompt.data) {
            setForm({
                ...form,
                promptVersion: newPrompt.data.version,
                promptOriginal: newPrompt.data.text,
                promptActive: newPrompt.data.text
            });
        }
    };

    const ResultsBox = (stage: any, project: any, handleSubmit: any, active: any) => {
        return (
            <div className="results-section">
                <p className={active ? "btn" : "btn disabled"} onClick={handleSubmit}>Execute</p>
                <div className="results-output">

                </div>

            </div>
        )
    }

    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <h5>AI Chain</h5>
            </div>
            <div className="chain-stage">
                <div className="stage-content">

                    <div className="control-section">
                        <p className="stage-title">Load Project</p>

                    </div>
                    <div className="results-section">
                        <div>
                            <p className="btn" onClick={handleConnect}>Execute</p>
                            <p className="btn btn-secondary" onClick={handleProjectReset}>Reset</p>
                        </div>

                        <ProjectDetails project={state.aiProject} />
                    </div>
                </div>
            </div>
            <div className="chain-stage">
                <div className="stage-content">
                    <div className="control-section">
                        <p className="stage-title">Load Feedback</p>

                    </div>
                    <div className="results-section">
                        <div><p className={state.aiProject && state.aiProject?.assetText ? "btn" : "btn disabled"} onClick={handleCollect}>Execute</p></div>

                        <FeedbackDetails project={state.aiProject} />
                    </div>
                </div>
            </div>
            <div className="chain-stage">
                <div className="stage-content">
                    <div className="control-section">
                        <p className="stage-title">Measure Quantitative Results</p>

                    </div>
                    <div className="results-section">
                        <div><p className={state.aiProject && state.aiProject?.responseJson ? "btn" : "btn disabled"} onClick={handlePerformance}>Execute</p></div>

                        <PerformanceDetails project={state.aiProject} />
                    </div>
                </div>
            </div>
            <div className="chain-stage">
                <div className="stage-content">
                    <div className="control-section">
                        <p className="stage-title">L1 - Qualitative Insights</p>

                        <ControlBox form={insightsForm} setForm={setInsightsForm} stage={"insights"} handleChange={handleChange} handleSubmit={handleInsights} handleReset={handleReset} handleSave={handleSave} active={(state.aiProject.performance && Object.keys(state.aiProject?.performance).length > 0)} />
                    </div>
                    <div className="results-section">
                        <p className={(state.aiProject.performance && Object.keys(state.aiProject?.performance).length > 0) ? "btn" : "btn disabled"} onClick={handleInsights}>Execute</p>

                        <InsightDetails project={state.aiProject} />
                    </div>
                </div>
            </div>
            <div className="chain-stage">
                <div className="stage-content">
                    <div className="control-section">
                        <p className="stage-title">L2 - Generate Suggestions</p>

                        <ControlBox form={suggestionsForm} setForm={setSuggestionsForm} stage={"suggestions"} handleChange={handleChange} handleSubmit={handleSuggestions} handleReset={handleReset} handleSave={handleSave} active={(state.aiProject?.insights !== "")} />
                    </div>
                    <div className="results-section">
                        <p className={(state.aiProject?.insights !== "") ? "btn" : "btn disabled"} onClick={handleSuggestions}>Execute</p>

                        <SuggestionDetails project={state.aiProject} />
                    </div>
                </div>
            </div>
            <div className="chain-stage">
                <div className="stage-content">
                    <div className="control-section">
                        <p className="stage-title">L3 - Generate Revisions</p>

                        <ControlBox form={revisionForm} setForm={setRevisionForm} stage={"revision"} handleChange={handleChange} handleSubmit={handleRevision} handleReset={handleReset} handleSave={handleSave} active={(state.aiProject?.suggestions !== "")} />
                    </div>
                    <div className="results-section">
                        <p className={(state.aiProject?.suggestions !== "") ? "btn" : "btn disabled"} onClick={handleRevision}>Execute</p>

                        <RevisionDetails project={state.aiProject} />
                    </div>
                </div>
            </div>
            <div className="chain-stage">
                <div className="stage-content">
                    <div className="control-section">
                        <p className="stage-title">XX - Extract Highlights</p>

                        <ControlBox form={highlightsForm} setForm={setHighlightsForm} stage={"revision"} handleChange={handleChange} handleSubmit={handleHighlights} handleReset={handleReset} handleSave={handleSave} active={(state.aiProject?.highlights !== "")} />
                    </div>
                    <div className="results-section">
                        <p className={(state.aiProject?.highlights !== "") ? "btn" : "btn disabled"} onClick={handleHighlights}>Execute</p>

                        <HighlightsDetails project={state.aiProject} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminProjectAi;