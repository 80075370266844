import { Route, Routes } from "react-router-dom";
import { Workspace } from "./Workspace";
import { WorkspaceDeals } from "./WorkspaceDeals";
import { WorkspaceSettings } from "./WorkspaceSettings";
import { WorkspaceTeam } from "./WorkspaceTeam";
import { CreateWorkspace } from "./CreateOrganization";

export const WorkspacesRoutes = () => {
    return (
        <Routes>
            <Route path="/new" element={<CreateWorkspace />} />
            <Route path=":organizationTag" element={<Workspace />}>
                <Route index element={<WorkspaceDeals />} />
                <Route path="settings" element={<WorkspaceSettings />} />
                <Route path="team" element={<WorkspaceTeam />} />    
            </Route>
        </Routes>
    );
}