import { callExternalApi } from "../../../core/services/external-api.service";

const apiServerUrl = process.env.REACT_APP_API_ORIGIN;

export const getOrganizations = async (accessToken: any) => {

    const config = {
        url: `${apiServerUrl}/admin/organization/all`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };

};

export const getOrganization = async (id: string, accessToken: any) => {

    const config = {
        url: `${apiServerUrl}/admin/organization/${id}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };

};

export const createOrganization = async (organization: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/organization`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: organization
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };
};

export const updateOrganization = async (id: string, organization: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/organization/${id}`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: organization
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };
};

export const addUser = async (user: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/user`,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: user
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };
};

export const getUser = async(userId: string, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/user/${userId}`,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };
};

export const getUserByEmailAddress = async(emailAddress: string, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/user/email/${emailAddress}`,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };
};


export const updateUser = async(userId: string, user: any, accessToken: any) => {

    const config = {
        url: `${apiServerUrl}/admin/user/${userId}`,
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: user
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };
};

export const getProjects = async ( accessToken: any ) => {
    const config = {
        url: `${apiServerUrl}/admin/project/all`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };
};
  
export const getProject = async (projectId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    };
        
    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    };
};

export const getPDFSignedUrls = async (projectId: number, testUrl: string, accessToken: any) => {
    const config = {
      url: `${apiServerUrl}/admin/project/${projectId}/signed?aws_url=${testUrl}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
     
    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
  };

export const createProject = async ( project: any, accessToken: any ) => {
    const config = {
        url: `${apiServerUrl}/admin/project`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: {
            projectName: project.name,
            projectType: project.type
        }
    }

    const { data, error } = await callExternalApi({ config });
    return {
        data: data || null,
        error
    }
};

export const updateProject = async ( projectId: number, project: any, accessToken: any ) => {
    let goalAttributes = '';
  
    let projectData = project;

    if (project.goals) {
        project.goals.forEach((element: any, idx: number) => {
            if (idx !== 0) goalAttributes += ", ";
            goalAttributes += element.value;
        });
        projectData = { ...project, goals: goalAttributes };
    }

    
    // This value is pulled from S3 on update.
    // Existing value is too large for request.
    project.lead_list = "";

    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: projectData
    }

    const { data, error } = await callExternalApi({ config });
    return {
        data: data || null,
        error
    }
};

export const loadStats = async ( projectId: number, accessToken: any ) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/loadStats`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });
    return {
        data: data || null,
        error
    }
}

export const createConceptRevision = async ( projectForm: any, conceptForm: any, accessToken: any ) => {
    const body = {
        "use_case": projectForm.type,
        "goals": projectForm.goals.map((g: any) => g.value),
        "asset": projectForm.asset_text,
        "target_audience": projectForm.target_audience,
        "feedback": conceptForm.feedback,
        "guidance": conceptForm.guidance,
        "temperature": conceptForm.temperature
    };

    const config = {
        url: "http://18.219.107.9:8003/api/asset_generator_by_feedback",
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: body
    }

    const { data, error } = await callExternalApi({ config });
    
    return {
        data: data || null,
        error
    }
};

export const getSurveyLinks = async (projectId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/surveyLinks`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const createSurveyLink = async (projectId: any, surveyLink: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/surveyLink`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: surveyLink
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const getSurveyLink = async ( projectId: any, surveyLinkId: any, accessToken: any ) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/surveyLink/${surveyLinkId}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const updateSurveyLink = async (projectId: any, surveyLinkId: any, surveyLink: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/surveyLink/${surveyLinkId}`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: surveyLink
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const getViewpoints = async (projectId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/viewpoints`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const updateViewpoint = async (projectId: number, viewpointId: number, viewpoint: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/viewpoint/${viewpointId}`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: viewpoint
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const approveViewpoint = async (projectId: number, viewpointId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/viewpoint/${viewpointId}/approve`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const rejectViewpoint = async (projectId: number, viewpointId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/viewpoint/${viewpointId}/reject`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const getNotifications = async (projectId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/notification`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const sendDealNotification = async (projectId: number, notificationType: string, notificationForm: any, dealUpdateId: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/notification`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: {
            notificationType: notificationType,
            subject: notificationForm.subject,
            headline: notificationForm.headline,
            body: notificationForm.body,
            dealUpdateId: dealUpdateId
        }   
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const getDealUpdates = async (projectId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/dealUpdates`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const createDealUpdate = async (projectId: number, dealUpdate: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/dealUpdate`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: dealUpdate
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const getDealUpdate = async (projectId: number, dealUpdateId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/dealUpdate/${dealUpdateId}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const updateDealUpdate = async (projectId: number, dealUpdateId: number, dealUpdate: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/dealUpdate/${dealUpdateId}`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: dealUpdate
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const getInvestmentMaterials = async (projectId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/investmentMaterials`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const createInvestmentMaterial = async (projectId: number, investmentMaterial: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/investmentMaterial`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: investmentMaterial
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const getInvestmentMaterial = async (projectId: number, investmentMaterialId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/investmentMaterial/${investmentMaterialId}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const updateInvestmentMaterial = async (projectId: number, investmentMaterialId: number, investmentMaterial: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/investmentMaterial/${investmentMaterialId}`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: investmentMaterial
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const getSurveyResponses = async (projectId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/surveyResponses`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const createSurveyResponse = async (projectId: number, surveyResponse: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/surveyResponse`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: surveyResponse
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const updateSurveyResponse = async (projectId: number, surveyResponseId: number, surveyResponse: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/surveyResponse/${surveyResponseId}`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: surveyResponse
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const updateViewpointProfile = async (viewpointId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/viewpoint/${viewpointId}/profile`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const getCompanyByDomain = async (domain: string, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/viewpoint/company/${domain}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const updateCompany = async (companyId: number, company: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/viewpoint/company/${companyId}`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: company
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const fetchLeads = async (projectId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/leads`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const getTargetAudience = async (projectId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/targetAudience`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

 export const getProjectMembers = async (projectId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/members`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const addProjectMember = async (projectId: number, userId: number, role: string, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/member/${userId}`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: {
            role: role
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const removeProjectMember = async (projectId: number, userId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/member/${userId}`,
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const updateProjectMember = async (projectId: number, userId: number, role: string, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/project/${projectId}/member/${userId}`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: {
            role: role
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const getOrganizationMembers = async (organizationId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/organization/${organizationId}/members`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const addOrganizationMember = async (organizationId: number, memberForm: any, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/organization/${organizationId}/member`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: memberForm
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const removeOrganizationMember = async (organizationId: number, userId: number, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/organization/${organizationId}/member/${userId}`,
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}

export const updateOrganizationMember = async (organizationId: number, userId: number, role: string, accessToken: any) => {
    const config = {
        url: `${apiServerUrl}/admin/organization/${organizationId}/member/${userId}`,
        method: "PATCH",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: {
            role: role
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    }
}



