import { useOutletContext } from "react-router-dom";
import { DealTitle } from "../components/DealTitle";
import { Box } from "@mui/material";

export const DealAnalysis = () => {
    const { project, organization, viewerRole } = useOutletContext<{ project: any, organization: any, viewerRole: any }>();
    return (
        <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>
            <DealTitle project={project} section="Analysis" />
            <Box
            component={"iframe"}
                src={project.iterations?.at(-1)?.analysis_url}
                style={{
                    display: "flex",
                    flex: 1,
                    minWidth: "100%",
                }}
                frameBorder="0"
            >
            </Box>
        </Box>
    );
}   