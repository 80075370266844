export const NotificationTemplate = ({ subject, title, body }: any) => {

    const tableStyle = {
        width: "100%",
        maxWidth: 600,
        backgroundColor: "#FFFFFF",
        color: "#000000",
        padding: 0,
        fontFamily: "arial, helvetica, sans-serif"
    };

    const imageStyle = {
        fontSize: "6px",
        lineHeight: 10,
        padding: "20px 0px 20px 0px",
        display: "block",
        color: "#000000",
        textDecoration: "none",
        fontFamily: "Helvetica, arial, sans-serif",
        maxWidth: "30% !important",
        height: "auto !important",
    };

    const textStyle = {
        padding: "18px 0px 18px 0px",
        lineHeight: "20px"
    };

    const dividerStyle = {
        padding: "20px 0px 0px 0px",
    };

    const footerStyle = {
        padding: "10px 0px 18px 0px",
        lineHeight: "22px"
    };

    return (
        <div>
            <p><span className="bold">Subject:</span> {subject}</p>
            <table width="100%" cellPadding="0" cellSpacing="0" border={0} style={tableStyle} align="center">
                <tbody>
                    <tr>
                        <td role="modules-container" style={tableStyle} width="100%" align="left">

                            <table className="wrapper" role="module" data-type="image" border={0} cellPadding="0" cellSpacing="0" width="100%" style={{ tableLayout: "fixed" }} data-muid="4b6fd6e8-8a8e-4ffc-a801-7ca3bddbdfa5">
                                <tbody>
                                    <tr>
                                        <td style={imageStyle} valign="top" align="left">
                                            <img className="max-width" style={imageStyle} width="180" alt="" data-proportionally-constrained="true" data-responsive="true" src="http://cdn.mcauto-images-production.sendgrid.net/031725f2573dccfe/285bbc90-2ae0-4d39-aae5-ea12c0c7cfb7/412x70.png" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="module" role="module" data-type="text" border={0} cellPadding="0" cellSpacing="0" width="100%" style={{ tableLayout: "fixed" }} data-muid="7c847d3d-697a-48e0-a8af-6f82c2c3fb7c" data-mc-module-version="2019-10-22">
                                <tbody>
                                    <tr>
                                        <td style={textStyle} height="100%" valign="top" role="module-content">
                                            <div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}>
                                                    <span style={{ fontFamily: "arial, helvetica, sans-serif", fontSize: "24px" }}>
                                                        <strong>{title}</strong>
                                                    </span>
                                                </div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}><br /></div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}><br /></div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}>{body}</div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}><br /></div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}>Click below to view the investor deck and deal terms:</div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}><br /></div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}><a href="" target="_blank">[Link to expert co-invest page goes here]</a></div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}><br /></div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}>Save this email and use this link at any time to get back to this deal page for updates.</div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}><br /></div>
                                                <div style={{ fontFamily: "inherit", textAlign: "inherit" }}>BrightLoop</div>
                                                <div></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="module" role="module" data-type="divider" border={0} cellPadding="0" cellSpacing="0" width="100%" style={{ tableLayout: "fixed" }} data-muid="82ebfe65-7820-462e-be92-e9a9f6760c71">
                                <tbody>
                                    <tr>
                                        <td style={dividerStyle} role="module-content" height="100%" valign="top">
                                            <table border={0} cellPadding="0" cellSpacing="0" align="center" width="100%" style={{ lineHeight: "1px", fontSize: "1px" }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ padding: "0px 0px 1px 0px", backgroundColor: "#c9c9c9" }}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="module" role="module" data-type="text" border={0} cellPadding="0" cellSpacing="0" width="100%" style={{ tableLayout: "fixed" }} data-muid="b7691134-9df8-4a01-b6db-807ca3d146f0" data-mc-module-version="2019-10-22">
                                <tbody>
                                    <tr>
                                        <td style={footerStyle} height="100%" valign="top" role="module-content">
                                            <div>
                                                <div style={{ fontFamily: "inherit", textAlign: "center" }}>
                                                    <span style={{ fontSize: "12px" }}>This email was sent to [recipient_email_address]</span>
                                                </div>
                                                <div></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};