import { useOutlet, useOutletContext } from "react-router-dom"
import { OrganizationMembers } from "./components/OrganizationMembers"
import { Button } from "@mui/material";
import { useState } from "react";
import { AddOrganizationMember } from "./components/AddOrganizationMember";

export const AdminOrganizationMembers = () => {

    const { organization, reloadOrganization } = useOutletContext<{ organization: any, reloadOrganization: any }>();

    const [ open, setOpen ] = useState(false);

    return (
        <div>
            <h5>Members</h5>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>Add Member</Button>
            <OrganizationMembers organization={organization}/>
            <AddOrganizationMember organization={organization} reloadOrganization={reloadOrganization} open={open} setOpen={setOpen}/>
        </div>
    )
}