import { styled } from "@mui/material";

const CompanyLogo = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
        maxHeight: "34px",
        maxWidth: "100px"
    },
    "&.mini": {
        "& img": {
            marginTop: "10px",
            maxHeight: "20px",
            maxWidth: "100px",
            filter: "grayscale(1)"
        }
    },
    "&.left": {
        justifyContent: "flex-start",
        padding: "",
    }
}));

const CompanyName = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem",
    fontWeight: 700,
    padding: "20px",
    textAlign: "center",
    "&.mini": {
        fontSize: "0.8rem",
        padding: "5px",
    },
    "&.left": {
        justifyContent: "flex-start",
        padding: "20px 20px 20px 0",
    }
}));

export const DealCompany = ({ viewpoint, size="medium", style="centered" }: any) => {

    if (viewpoint?.company?.logo_url) {
        return (
            <CompanyLogo className={`${size} ${style}`}>
                <img src={viewpoint.company.logo_url} alt={viewpoint.company.name} />
            </CompanyLogo>
        );
    }
    return (
        <CompanyName className={`${size} ${style}`}>
            {viewpoint?.company?.name ?? viewpoint.company_name}
        </CompanyName>
    );
}