import { BaseSyntheticEvent } from "react";
import { TOrganization } from "../routes/CreateOrganization";

type TCreateOrganizationsProps = {
    form: TOrganization, 
    handleSubmit: (event: any) => Promise<void>, 
    handleChange: (event: BaseSyntheticEvent) => void
}

export const OrganizationHero = () => {
    return (
        <div className="splash-hero">
            <div className="hero-title">Welcome to BrightLoop!</div>
            <div className="hero-text">You're almost in, we just need to setup your dealspace first.</div>
        </div>
    );
};

export const CreateOrganizationForm = ({form, handleSubmit, handleChange}: TCreateOrganizationsProps) => {
    return (
        <form onSubmit={handleSubmit}>
            <div className="oboarding-form-input">
            <input
                id="organizationName"
                type="text"
                className="splash-company-field"
                value={form.name}
                onChange={handleChange}
            />
            </div>

            <button type="submit">Next</button>
        </form>
    );
};
