import { useEffect, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { createConceptRevision, updateProject} from "../services/gutcheck-admin-api.service";
import { CreateProjectHeader } from "../../projects/components/project_form_components";
import useSignPdfUrl from "../../../hooks/useSignPdfUrl";
import { AdminConceptRevisionContainer, AdminResultsContainer, CreateAiChainLink, CreateEditSaveButtons, CreateProjectHeaderStatusBar, ProjectsContainer, RevisionContainer } from "./widgets/admin-projects-widgets";
import { Separator } from "../../../components/utils/Separator";
import Loading from "../../../components/Loading";
import useAdminGetProjectData from "../hooks/useAdminGetProjectData";

const toJson = (element: any) => {
    try {
        return JSON.parse(element);
    } catch(e) {
        console.error(e);
        return {};
    }
};

export const AdminProjectEdit = () => {
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const { project, reloadProjectData } = useOutletContext<any>();
    const existingProject = project;

    const [state, setState] = useState({
        isEdit: false,
        canSave: false,
        highlightedPhrases: [],
        tab: "Overview"
    });

    const [projectForm, setProjectForm] = useState({
        id: -1,
        name: existingProject.name,
        iteration_id: -1,
        iteration_tag: existingProject.iterations?.at(-1)?.tag ?? "",
        type:  existingProject.iterations?.at(-1)?.type.title ?? "",
        status: existingProject.status ?? "",
        user_type: existingProject.user_type ?? "",
        iteration_status: existingProject.iterations?.at(-1)?.status ?? "",
        iteration_number: existingProject.iterations?.at(-1)?.iteration_number ?? -1,
        asset_text: existingProject.iterations?.at(-1)?.asset_text ?? "",
        asset_url: existingProject.iterations?.at(-1)?.asset_url ?? "",
        asset_file_id: existingProject.iterations?.at(-1)?.asset_file_id ?? "",
        goals: existingProject.iterations?.at(-1)?.goals ?? [],
        target_audience: existingProject.iterations?.at(-1)?.target_audience ?? "",
        key_questions: existingProject.iterations?.at(-1)?.key_questions ?? "",
        analysis_url: existingProject.iterations?.at(-1)?.analysis_url ?? "",
        summary_url: existingProject.iterations?.at(-1)?.summary_url ?? "",
        result_json: toJson(existingProject.iterations?.at(-1)?.result_json ?? "{}") ,
        revision_text: existingProject.iterations?.at(-1)?.revision_text ?? "",
        revision_result_json: toJson(existingProject.iterations?.at(-1)?.revision_result_json ?? "{}"),
        revision_url: existingProject.iterations?.at(-1)?.revision_url ?? "",
        organization: {...existingProject.organization, value: existingProject.organization.id, label: existingProject.organization.name },
        completion_date: existingProject.completion_date ?? "",
        distinction: existingProject.distinction ?? "",
        market_size: existingProject.market_size ?? "",
        stats_viewpoints: existingProject.stats_viewpoints ?? 0,
        stats_demo_requests: existingProject.stats_demo_requests ?? 0,
        stats_advisors: existingProject.stats_advisors ?? 0,
        stats_angels: existingProject.stats_angels ?? 0,
        stats_investment_amount_low: existingProject.stats_investment_amount_low ?? 0,
        stats_investment_amount_high: existingProject.stats_investment_amount_high ?? 0,
        stats_demand: existingProject.stats_demand ?? 0,
        stats_distinction: existingProject.stats_distinction ?? 0,
        is_closed: existingProject.is_closed ?? false,
        is_archived: existingProject.is_archived ?? false,
        graph_url: existingProject.graph_url ?? "",
        public_title: existingProject.public_title ?? "",
        spv_platform_url: existingProject.spv_platform_url ?? "",
        round: existingProject.round ?? null,
        amount_raising: existingProject.amount_raising ?? null,
        amount_premoney_valuation: existingProject.amount_premoney_valuation ?? null,
        share_class: existingProject.share_class ?? null,
        pro_rata_rights: existingProject.pro_rata_rights ?? null,
        spv_allocation: existingProject.spv_allocation ?? null,
        spv_estimated_fees: existingProject.spv_estimated_fees ?? null,
        spv_leads_investment: existingProject.spv_leads_investment ?? null,
        spv_total_carry: existingProject.spv_total_carry ?? null,
        spv_management_fee: existingProject.spv_management_fee ?? null,
        spv_minimum_investment: existingProject.spv_minimum_investment ?? null,
        investor_deck: existingProject.investor_deck ?? "",
        deal_terms_url: existingProject.deal_terms_url ?? "",
        auto_publish: existingProject.auto_publish ?? false,
        demand_override: existingProject.demand_override ?? "",
        distinction_question: existingProject.distinction_question ?? "",
        distinction_options: existingProject.distinction_options ?? "",
        evaluation_delay_hours: existingProject.evaluation_delay_hours ?? 48,
        spv_platform_name: existingProject.spv_platform_name ?? "",
    });

    const [form, setForm] = useState({
        result_json: existingProject.iterations?.at(-1)?.result_json ?? "{}",
        revision_result_json: existingProject.iterations?.at(-1)?.revision_result_json ?? "{}",
    });

    const [conceptForm, setConceptForm] = useState({
        feedback: '',
        guidance: '',
        temperature: '',
        gtp_response: '',
        llm_response: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    
    const [assetPagination, setAssetPagination] = useState({
        numPages: null,
        currentPage: 1
    });

    let signedRevisionUrl = useSignPdfUrl(existingProject.id, projectForm.revision_url);
    let signedAssetFileId = useSignPdfUrl(existingProject.id, projectForm.asset_file_id);

    const handleChange = (event: any) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setProjectForm({
            ...projectForm,
            [event.target.id]: value,
        });
    };

    const handleConceptChange = (event: any) => {
        setConceptForm({
            ...conceptForm,
            [event.target.id]: event.target.value,
        });
    };

    const handleFormChange = (event: any) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };

    const handleGoalChange = (event: any) => {
        try {
            setProjectForm({
                ...projectForm,
                goals: event,
            });    
        } catch (e) {};
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();
        
        await updateProject(existingProject.id, projectForm, accessToken);
        reloadProjectData();
        
        return navigate(`/admin/project/${existingProject.id}`);
    };

    const handleStatusChange = (event: any) => {
        if (state.isEdit) {
            setProjectForm({
                ...projectForm,
                status: event.target.id,
                iteration_status: event.target.id
            });
        };
    };

    const handleRevisionMouse = (event: any, action: any) => {
        if (action === "over") {
            const revisionTitle = event.target.innerText;
            const revision = projectForm.revision_result_json.revisions.find( (revision: any) => revision.title === revisionTitle);
            setState({
                ...state,
                highlightedPhrases: revision.phrases
            });
        } else {
            setState({
                ...state,
                highlightedPhrases: []
            })
        }
    };

    const handleToggleIsEdit = () => {
        if (state.isEdit) {
            setProjectForm({
                ...projectForm,
                result_json: toJson(form.result_json),
                revision_result_json: toJson(form.revision_result_json)
            });
            setState({...state, isEdit: !state.isEdit, canSave: true});
        } else {
            setState({...state, isEdit: !state.isEdit, canSave: false});
        }
    };
    
    const handleSwitchTab = (event: any) => {
      setState({
        ...state,
        tab: event.target.textContent
      })
    };

    const handleCreateConceptRevision = async (event: any) => {
        if (isLoading) return;

        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        setIsLoading(true);
        setConceptForm({
            ...conceptForm,
            gtp_response: "generating...",
            llm_response: "generating..."
        });

        const conceptRevisionData = await createConceptRevision(projectForm, conceptForm, accessToken);

        if (conceptRevisionData.data) {
            setConceptForm({
                ...conceptForm,
                gtp_response: conceptRevisionData.data.openai_gpt_4_output.revised_asset,
                llm_response: conceptRevisionData.data.llm_cohere_output.revised_asset,
            });
        };

        setIsLoading(false);
    };

    const handleAssetLoad = ( {numPages}: any ) => {
        setAssetPagination({
          ...assetPagination,
          numPages: numPages,
          currentPage: 1
        });
      };
      
      const handleAssetChange = (event: any, offset: any) => {
        setAssetPagination({
          ...assetPagination,
          currentPage: assetPagination.currentPage + offset
        });
      };

    return (
       <>   
            { isLoading ? <Loading /> : '' }
            <div className={state.isEdit ? "project-content editable" : "project-content"}>
                <CreateEditSaveButtons state={state} handleToggleIsEdit={handleToggleIsEdit} handleSubmit={handleSubmit} />
                <div style={{width:"100%"}}><CreateProjectHeader form={projectForm} setForm={setProjectForm} isDisabled={!state.isEdit}/></div>
                <CreateProjectHeaderStatusBar projectForm={projectForm} handleStatusChange={handleStatusChange} />
                <ProjectsContainer state={state} projectForm={projectForm} setProjectForm={setProjectForm} signedAssetFileId={signedAssetFileId} handleChange={handleChange} handleGoalChange={handleGoalChange} assetPagination={assetPagination} handleAssetLoad={handleAssetLoad} handleAssetChange={handleAssetChange} />
                <AdminResultsContainer state={state} projectForm={projectForm} form={form} handleSwitchTab={handleSwitchTab} handleChange={handleChange} handleFormChange={handleFormChange} />
                <RevisionContainer state={state} projectForm={projectForm} form={form} signedRevisionUrl={signedRevisionUrl} handleChange={handleChange} handleFormChange={handleFormChange} handleRevisionMouse={handleRevisionMouse} />
                <Separator />
                <AdminConceptRevisionContainer form={conceptForm} handleChange={handleConceptChange} handleSubmit={handleCreateConceptRevision} />
            </div>
       </>
    );
};

export default AdminProjectEdit;