import { EditorProvider, FloatingMenu, BubbleMenu, useEditor, EditorContent } from '@tiptap/react'
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import { useCallback } from 'react';

type TTextEditor = {
    content: string,
    id: string,
    form: any,
    setForm: any
};

const extensions = [
    StarterKit,
    Link.configure({
        openOnClick: false
    })
];

const TextEditor = ({ content, id, form, setForm }: TTextEditor) => {

    const editor = useEditor({
        extensions: extensions,
        editorProps: {
            attributes: {
                class: "Editor"
            }
        },
        content: content,
        onUpdate: ({ editor }) => {
            setForm({
                ...form,
                [id]: editor.getHTML()
            })
        }
    });

    const setLink = useCallback(() => {
        if (editor) {
        const previousUrl = editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)
    
        // cancelled
        if (url === null) {
          return
        }
    
        // empty
        if (url === '') {
          editor.chain().focus().extendMarkRange('link').unsetLink()
            .run()
    
          return
        }
    
        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: url })
          .run()
    }
      }, [editor])

      if(!editor) {
        return null
    }

    return (
        <div className="text-editor-container">
            {editor && (
                <EditorContent editor={editor}>
                    <FloatingMenu className="floating-menu"
                        editor={editor}
                        tippyOptions={{ duration: 100 }}
                    >
                        <button
                            className={editor.isActive('heading', {level: 1}) ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleHeading({ level: 1}).run()}
                        >
                            H1
                        </button>
                        <button
                            className={editor.isActive('heading', {level: 2}) ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleHeading({ level: 2}).run()}
                        >
                            H2
                        </button>
                                                <button
                            className={editor.isActive('bold') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleBold().run()}
                        >
                            B
                        </button>
                        <button
                            className={editor.isActive('italic') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleItalic().run()}
                        >
                            <span className="italic">I</span>
                        </button>
                        <button
                            className={editor.isActive('bulletList') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleBulletList().run()}
                        >
                            <FontAwesomeIcon icon="list" />
                        </button>
                        <button
                            className={editor.isActive('orderedList') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        >
                            <FontAwesomeIcon icon="list-ol" />
                        </button>
                        <button
                            className={editor.isActive('link') ? 'is-active' : ''}
                            onClick={setLink}
                        >
                            <FontAwesomeIcon icon="link" />
                        </button>
                    </FloatingMenu>
                    <BubbleMenu
                        className="bubble-menu"
                        editor={editor}
                        tippyOptions={{ duration: 100 }}
                    >
                        <button
                            className={editor.isActive('heading', {level: 1}) ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleHeading({ level: 1}).run()}
                        >
                            H1
                        </button>
                        <button
                            className={editor.isActive('heading', {level: 2}) ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleHeading({ level: 2}).run()}
                        >
                            H2
                        </button>
                                                <button
                            className={editor.isActive('bold') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleBold().run()}
                        >
                            B
                        </button>
                        <button
                            className={editor.isActive('italic') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleItalic().run()}
                        >
                            <span className="italic">I</span>
                        </button>
                        <button
                            className={editor.isActive('bulletList') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleBulletList().run()}
                        >
                            <FormatListBulletedIcon />
                        </button>
                        <button
                            className={editor.isActive('orderedList') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        >
                            <FormatListNumberedIcon />
                        </button>
                        <button
                            className={editor.isActive('link') ? 'is-active' : ''}
                            onClick={setLink}
                        >
                            <FontAwesomeIcon icon="link" />
                        </button>
                    </BubbleMenu>
                </EditorContent>
            )}
        </div>
    )
}

export default TextEditor;