import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import NotFound from "./NotFound";

type TAuthentication = {
    component: any,
    role?: string
}

const checkUserRole = (user: any, role: string): React.SetStateAction<boolean> => {
    const userRoles = user ? user['https://gutcheck.svpvc.com/roles'] : null;

    if (userRoles?.includes("admin")) return true;

    return role && userRoles ? userRoles?.includes(role) : true;
};

export const AuthenticationGuard = ({ component, role = "user" }: TAuthentication) => {

    const { user, isLoading } = useAuth0();
    const [roleCheck, setRoleCheck] = useState({
        hasRequiredRole: checkUserRole(user, role),
        checkComplete: false
    });

    useEffect(() => {
        if (!isLoading && user) {
            setRoleCheck({
                ...roleCheck,
                hasRequiredRole: checkUserRole(user, role),
                checkComplete: true
            });
        } else if (!isLoading && !user) {
            setRoleCheck({
                ...roleCheck,
                hasRequiredRole: false,
                checkComplete: true
            });
        }
    }, [isLoading, user, role]);

    if (isLoading) {
        return <div className="page-layout"><Loading /></div>;
    }

    if (user && roleCheck.checkComplete && !roleCheck.hasRequiredRole && role) {
        return <NotFound />;
    } else if (user && roleCheck.checkComplete && roleCheck.hasRequiredRole) {
        const Component = withAuthenticationRequired(component, {
            onRedirecting: () => (
                <div className="page-layout">
                    <Loading />
                </div>
            ),
        });

        return <Component />;
    } else if (roleCheck.checkComplete && !user) {
        const Component = withAuthenticationRequired(component, {
            onRedirecting: () => (
                <div className="page-layout">
                    <Loading />
                </div>
            ),
        });
        return <Component />;

    }

    return <Loading />;


};