import { DocumentTitle } from "../../../components/DocumentTitle";
import { Box } from "@mui/material";

export const DealTitle = ({project, section, subheading}: any) => {
    return (
        <Box sx={{ marginBottom: "40px"}}>
            <DocumentTitle pageTitle={`${project?.name} - ${section}`} />
            <h2>{section}</h2>
            {subheading && <div>{subheading}</div>}
        </Box>
    );
}