import { useAuth0 } from "@auth0/auth0-react";
import { createDealUpdate, sendDealNotification, updateDealUpdate } from "../../services/gutcheck-admin-api.service";
import { useState } from "react";
import { Modal, Box, TextField, Stack, FormControlLabel, Switch } from "@mui/material";
import type { DialogProps } from "@mui/material";
import { NotificationTemplate } from "./NotificationTemplate";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 900,
    maxHeight: "100%",
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '80%',
    display: 'flex',
    flexDirection: 'column'
};

const initialDealUpdate = {
    title: "",
    body: "",
    published_at: new Date().toISOString,
    is_published: false
}

export const DealUpdateNotification = ({ project, dealUpdate, reloadDealUpdates, sendMode, setSendMode }: any) => {

    const { getAccessTokenSilently } = useAuth0();
    const [dealUpdateForm, setDealUpdateForm] = useState(dealUpdate);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        if (dealUpdate?.id) {
            const notificationForm = {
                subject: dealUpdate.subject,
                headline: dealUpdate.title,
                body: dealUpdate.body,
            }

            const { data, error } = await sendDealNotification(project.id, "INVESTMENT_UPDATE", notificationForm, dealUpdate?.id ?? null, accessToken);

        }
        await reloadDealUpdates();
        setSendMode(false);
    }


    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick") 
            return;
        setSendMode(false);
    }

    const handleCancel = (event: any) => {
        event.preventDefault();
        setSendMode(false);
    }

    return (
        <Modal
            open={sendMode} onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <h5>Send Deal Update to Experts</h5>
                <p>This will send this update out to all experts who have been approved to invest but have not yet received this update as a notification.</p>

                <div className="deal-update-notification-preview">
                    <NotificationTemplate subject={dealUpdate.subject} title={dealUpdate.title} body={dealUpdate.body}/>
                </div>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <button type="submit">Send Now</button>
                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                    </Stack>
                </form>
            </Box>
        </Modal>
    );

}