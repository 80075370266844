import { Box, Checkbox, FormLabel, Input, styled, Table, TableBody, TableCell, TableHead, TableProps, TableRow, Typography } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { DealExecutive } from "./DealExecutive";
import { TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core";
import zIndex from "@mui/material/styles/zIndex";


const ViewpointTable = styled(Table)<TableProps>(({ theme }) => ({
    "& tr": {
        "&:hover": {
            backgroundColor: "#f8f9fa"
        },
        "&:nth-of-type(odd)": {
            backgroundColor: "#ffffff"
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#f8f9fa"
        },
        "&:last-child": {
            "& td": {
                borderBottom: "none"
            }
        },
        "& td, th": {
            borderBottom: "1px solid #c9c9c9",
            padding: "0.5rem 0.75rem",
            fontSize: "0.9rem",
            color: "#000000",
            "&:first-child": {
                width: "40px",
                textAlign: "center"
            },
            "&:nth-child(2)": {
                paddingTop: "1rem",
                paddingBottom: "1rem",
            },
            "&:nth-child(3)": {
                width: "100px",
            },
            "&:nth-child(4)": {
                width: "75px",
                textAlign: "center",
            },
            "&:nth-child(5), &:nth-child(6), &:nth-child(7)": {
                width: "65px",
                textAlign: "center",
                padding: 0,
                "& .green": {
                    color: theme.palette.success.main
                },
                "& .gray": {
                    color: "#c9c9c9",
                }
            },
            "&:last-child": {
                width: "250px",
                "& p": {
                    fontStyle: "italic",
                    fontWeight: "300"
                }
            },
        },
        "& td .summary-pill": {
            display: "inline-block",
            padding: "0.5rem 1rem",
            backgroundColor: "#c9c9c9",
            borderRadius: "20px",
            color: "#000000",
            fontSize: "0.8rem",
            marginRight: "5px",
            marginBottom: "5px",
            "&.green": {
                backgroundColor: theme.palette.success.main,
                color: "#ffffff"
            },
        },
        "& th": {
            position: "sticky",
            top: "-40px",
            backgroundColor: "#ffffff",
            zIndex: 100,
            fontWeight: 700,
            "&.sortable": {
                cursor: "pointer",
                "&:hover": {
                    color: "#000000"
                }
            }
        }
    }
}));

const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gap: "1rem",
    padding: "0"
};

const filterLabelStyle = {
    border: "1px solid #c9c9c9",
    borderRadius: "5px",
    padding: "5px 10px",
    display: "inline-block",
    marginRight: "5px",
    fontSize: "0.9rem",
    "&:hover": {
        cursor: "pointer"
    }
};

const filterLabelCheckedStyle = {
    border: "1px solid #1A5DBD",
    backgroundColor: "#F0F6FF",
    borderRadius: "5px",
    padding: "5px 10px",
    display: "inline-block",
    marginRight: "5px",
    fontSize: "0.9rem",
    color: "#000000",
    "&:hover": {
        cursor: "pointer"
    }
};

const bubbleBlackStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
    backgroundColor: "#ffffff",
    color: "#000000",
    borderRadius: "20px",
    height: "35px",
    width: "35px",
    border: "1px solid #c9c9c9",
    cursor: "pointer"
};

const displayTypeStyle = {
    fontSize: "1.5rem",
    color: "#c9c9c9",
    paddingBottom: "7px",
    "&:hover": {
        color: "#000000",
        cursor: "pointer"
    }
};

const displayTypeActiveStyle = {
    fontSize: "1.5rem",
    color: "#000000",
    paddingBottom: "7px",
    "&:hover": {
        color: "#000000",
        cursor: "pointer"
    }
};

type TViewpointsState = {
    viewpoints: any[];
    filteredViewpoints: any[];
};

const interestOptions = [
    { value: "interest_beta", label: "Purchase", icon: "chart-line" as FontAwesomeIconProps["icon"] },
    { value: "interest_investor", label: "Invest", icon: "hand-holding-dollar" as FontAwesomeIconProps["icon"] },
    { value: "interest_advisor", label: "Advise", icon: "handshake-angle" as FontAwesomeIconProps["icon"] }
]



const demandOptions = [
    {
        value: "Yes, definitely",
        label: "Very High"
    },
    {
        value: "Yes, probably",
        label: "High"
    },
    {
        value: "Maybe",
        label: "Medium"
    },
    {
        value: "No, probably not",
        label: "Low"
    },
    {
        value: "No, definitely not",
        label: "Very Low"
    }
]

const TextIcon = styled("span")(({ theme }) => ({
    fontFamily: "Courier, monospace",
    fontSize: "1.2rem",
    lineHeight: "1rem",
    fontWeight: 700,
}));

const formatOptions = [
    {
        value: "has_video", label: "", icon: <FontAwesomeIcon icon="film"/>
    },
    {
        value: "has_audio", label: "", icon: <FontAwesomeIcon icon="volume-high"/>
    },
    {
        value: "has_text", label: "", icon: <TextIcon>T</TextIcon>
    }
]


const viewpointsReducer = (state: TViewpointsState, action: any) => {
    switch (action.type) {
        case 'FILTER':
            const { searchText, filterCriteria, sortCriteria } = action.payload;
            const filteredViewpoints = state.viewpoints.filter((viewpoint: any) => {
                const matchesSearchText = viewpoint.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                const matchesDemand = filterCriteria.demand.length === 0 || filterCriteria.demand.includes(viewpoint.demand);
                const matchesInterest = filterCriteria.interest.length === 0 || filterCriteria.interest.some((interest: string) => {
                    if (interest === "interest_beta") {
                        return viewpoint.interest_demo || viewpoint.interest_beta;
                    } else {
                        return viewpoint[interest]}
                    }
                );
                const matchesFormat = filterCriteria.format.length === 0 || filterCriteria.format.some((format: string) => {
                    if (format === "has_text") {
                        return !(viewpoint.has_video || viewpoint.has_audio);
                    } else if (format === "has_audio") {
                        return viewpoint.has_audio && !viewpoint.has_video;
                    } else {
                        return viewpoint[format]
                    }
                });
                return matchesSearchText && matchesDemand && matchesInterest && matchesFormat;
            })

            return {
                ...state,
                filteredViewpoints: filteredViewpoints.sort((a: any, b: any) => {
                    let aVal;
                    let bVal;

                    switch(sortCriteria.field) {
                        case 'demand':
                            aVal = demandOptions.findIndex((option) => option.value === a[sortCriteria.field]);
                            bVal = demandOptions.findIndex((option) => option.value === b[sortCriteria.field]);
                            break;
                        case 'name':
                            aVal = !a[sortCriteria.field] || a[sortCriteria.field] === "" ? 'zzzzzzz' : a[sortCriteria.field].toLowerCase();
                            bVal = !b[sortCriteria.field] || b[sortCriteria.field] === "" ? 'zzzzzzz' : b[sortCriteria.field].toLowerCase();
                            break;
                        case 'company':
                            aVal = !a[sortCriteria.field] || /^[^a-z]/i.test(a[sortCriteria.field]) ? 'zzzzzzz' : a[sortCriteria.field].toLowerCase();
                            bVal = !b[sortCriteria.field] || /^[^a-z]/i.test(b[sortCriteria.field]) ? 'zzzzzzz' : b[sortCriteria.field].toLowerCase();
                            break;
                        case 'format':
                            aVal = a.has_video ? 1 : a.has_audio ? 2 : 3;
                            bVal = b.has_video ? 1 : b.has_audio ? 2 : 3;
                            break;
                        case 'interest_purchase':
                            aVal = a.interest_demo || a.interest_beta ? 0 : 1;
                            bVal = b.interest_demo || b.interest_beta ? 0 : 1;
                            break;
                        case 'interest_investor':
                            aVal = a.interest_investor ? 0 : 1;
                            bVal = b.interest_investor ? 0 : 1;
                            break;
                        case 'interest_advisor':
                            aVal = a.interest_advisor ? 0 : 1;
                            bVal = b.interest_advisor ? 0 : 1;
                            break;
                        default:
                            aVal = a[sortCriteria.field];
                            bVal = b[sortCriteria.field];
                    }

                    if (sortCriteria.direction === 'asc') {
                        return aVal > bVal ? 1 : -1;
                    } else {
                        return aVal < bVal ? 1 : -1;
                    }
                })
            };
            break;
        default:
            return state;
    }
}

export const DealViewpointsList = ({ project, viewpoints, user, viewpointId }: any) => {
    const [state, dispatch] = useReducer(viewpointsReducer, { viewpoints, filteredViewpoints: viewpoints });
    const [filterCriteria, setFilterCriteria] = useState<{ interest: any[], demand: any[], format: any[] }>({
        interest: [],
        demand: [], // Initialize 'demand' as an empty array
        format: [] // Initialize 'format' as an empty array
    });
    const [sortCriteria, setSortCriteria] = useState<{ field: string, direction: string }>({ field: 'demand', direction: 'asc' });
    const [searchText, setSearchText] = useState('');
    const [displayType, setDisplayType] = useState('grid');

    const handleFilter = (event: any) => {
        setSearchText(event.target.value);
    }

    const handleCriteriaChange = (event: any) => {
        const { name, value, checked } = event.target;

        switch(name) {
            case 'demand':
                const newDemands = checked ? [...filterCriteria.demand, value] : filterCriteria.demand.filter((d: string) => d !== value);
                setFilterCriteria(prev => ({ ...prev, demand: newDemands }));
                return;
            case 'interest':
                if (checked) {
                    setFilterCriteria(prev => ({ ...prev, interest: [...prev.interest, value] }));
                } else {
                    setFilterCriteria(prev => ({ ...prev, interest: prev.interest.filter((i: string) => i !== value) }));
                }
                return;
            case 'format':
                if (checked) {
                    setFilterCriteria(prev => ({ ...prev, format: [...prev.format, value] }));
                } else {
                    setFilterCriteria(prev => ({ ...prev, format: prev.format.filter((i: string) => i !== value) }));
                }
                return;
            default:
                setFilterCriteria(prev => ({ ...prev, [name]: checked }));
        }
    }

    const handleSortChange = (event: any) => {
        const { name, value } = event.target;
        setSortCriteria({ field: name, direction: value });
    }

    useEffect(() => {
        dispatch({
            type: 'FILTER',
            payload: {
                searchText: searchText,
                filterCriteria,
                sortCriteria
            }
        });
    }, [filterCriteria, sortCriteria, searchText]);

    const viewpointParam = window.location.hash.replace('#', '');

    return (
        <div>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                alignItems: "end",
                borderBottom: "1px solid #c9c9c9",
                paddingBottom: "10px",
            }}>
                <Box>
                    {false &&
                        <div>
                            <fieldset>
                                <legend>Search</legend>
                                <input type="text" onChange={handleFilter} placeholder="Search..." />
                            </fieldset>
                        </div>
                    }
                    <Box sx={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                        <Typography sx={{ fontSize: "1.5rem", color: "#000000", paddingTop: "10px" }}>
                            <FontAwesomeIcon icon="filter" />
                        </Typography>
                        {/* Multiselect for 'interest' */}
                        <fieldset>
                            <Typography sx={{ fontSize: "0.9rem", fontWeight: 700 }}>INTEREST</Typography>
                            {interestOptions.map((option) => (
                                <FormLabel key={option.value} sx={filterCriteria.interest.includes(option.value) ? filterLabelCheckedStyle : filterLabelStyle}>
                                    <Checkbox
                                        name="interest"
                                        value={option.value}
                                        onChange={handleCriteriaChange}
                                        checked={filterCriteria.interest.includes(option.value)}
                                        sx={{ display: "none" }}
                                    /> {option.label}
                                </FormLabel>
                            ))}
                        </fieldset>

                        {/* Multiselect for 'demand' */}
                        <fieldset>
                            <Typography sx={{ fontSize: "0.9rem", fontWeight: 700 }}>DEMAND</Typography>
                            {demandOptions.map((option) => (
                                <FormLabel key={option.value} sx={filterCriteria.demand.includes(option.value) ? filterLabelCheckedStyle : filterLabelStyle}>
                                    <Checkbox
                                        name="demand"
                                        value={option.value}
                                        onChange={handleCriteriaChange}
                                        checked={filterCriteria.demand.includes(option.value)}
                                        sx={{ display: "none" }}
                                    /> {option.label}
                                </FormLabel>
                            ))}
                        </fieldset>

                        {/* Multiselect for 'format' */}
                        <fieldset>
                            <Typography sx={{ fontSize: "0.9rem", fontWeight: 700 }}>FORMAT</Typography>
                            {formatOptions.map((option) => (
                                <FormLabel key={option.value} sx={filterCriteria.format.includes(option.value) ? filterLabelCheckedStyle : filterLabelStyle}>
                                    <Checkbox
                                        name="format"
                                        value={option.value}
                                        onChange={handleCriteriaChange}
                                        checked={filterCriteria.format.includes(option.value)}
                                        sx={{ display: "none" }}
                                    /> {option.icon}
                                </FormLabel>
                            ))}
                        </fieldset>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "end",
                    gap: "10px",
                    fontSize: "1.5rem"
                }}>
                    <Typography sx={displayType === "grid" ? displayTypeActiveStyle : displayTypeStyle} onClick={() => { setDisplayType("grid") }}><FontAwesomeIcon icon="border-all" /></Typography>
                    <Typography sx={displayType === "list" ? displayTypeActiveStyle : displayTypeStyle} onClick={() => { setDisplayType("list") }}><FontAwesomeIcon icon="list" /></Typography>
                </Box>
            </Box>

            {displayType === 'grid' &&
                <Box sx={gridStyle}>
                    {state.filteredViewpoints?.map((viewpoint: any) => (
                        <DealExecutive project={project} viewpoint={viewpoint} user={user} style="viewpoint-grid" initialOpen={viewpointParam === viewpoint.uuid} />
                    ))}
                </Box>
            }
            {displayType === 'list' &&
                <ViewpointTable>
                    <TableHead>
                        <TableRow>
                            <TableCell className="sortable" onClick={() => handleSortChange({ target: { name: 'format', value: sortCriteria.field === "format" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}><FontAwesomeIcon icon="film" /></TableCell>
                            <TableCell className="sortable" onClick={() => handleSortChange({ target: { name: 'name', value: sortCriteria.field === "name" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Executive</TableCell>
                            <TableCell className="sortable" onClick={() => handleSortChange({ target: { name: 'company', value: sortCriteria.field === "company" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Company</TableCell>
                            <TableCell className="sortable" onClick={() => handleSortChange({ target: { name: 'demand', value: sortCriteria.field === "demand" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Demand</TableCell>
                            <TableCell className="sortable" onClick={() => handleSortChange({ target: { name: 'interest_purchase', value: sortCriteria.field === "interest_purchase" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Purchase</TableCell>
                            <TableCell className="sortable" onClick={() => handleSortChange({ target: { name: 'interest_investor', value: sortCriteria.field === "interest_investor" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Invest</TableCell>
                            <TableCell className="sortable" onClick={() => handleSortChange({ target: { name: 'interest_advisor', value: sortCriteria.field === "interest_advisor" && sortCriteria.direction === 'asc' ? 'desc' : 'asc' } })}>Advise</TableCell>
                            <TableCell>Summary Quote</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.filteredViewpoints?.map((viewpoint: any) => (
                            <DealExecutive project={project} viewpoint={viewpoint} style="viewpoint-list" user={user} initialOpen={viewpointParam === viewpoint.uuid}/>
                        ))}
                    </TableBody>
                </ViewpointTable>
            }
        </div>
    );
}