import { Fade, List, ListItem, Step, StepConnector, StepIconProps, StepLabel, Stepper, stepConnectorClasses, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

const existingStatus = [
  { value: "draft", label: "Setup & Launch" },
  { value: "evaluation", label: "Collecting Viewpoints" },
  { value: "analysis", label: "Analysis & Results" },
  { value: "firm_investment", label: "Firm Investment" },
  { value: "spv", label: "BrightLoop Expert SPV" },
  { value: "completed", label: "Completed" }
];

const DealStageConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50%)',
    right: 'calc(50%)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#28a745',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#28a745',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#d9d9d9',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const DealStageIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .DealStageStepIcon-completed': {
      width: 22,
      height: 22,
      borderRadius: '50%',
      border: '3px solid #28a745',
      backgroundColor: '#28a745',
      zIndex: 1,
    },
    '& .DealStageStepIcon-circle': {
      width: 22,
      height: 22,
      zIndex: 1,
      borderRadius: '50%',
      border: '3px solid #d9d9d9',
      backgroundColor: '#f1f1f1',
      ...(ownerState.active && {
        border: '3px solid #28a745',
      }
      )
    },
  }),
);

function DealStageStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <DealStageIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="DealStageStepIcon-completed" />
      ) : (
        <div className="DealStageStepIcon-circle" />
      )}
    </DealStageIconRoot>
  );
}

const DealListItem = ({ project, filteredProjects, onClick }: any) => {
  const investmentRange = project.statsInvestmentAmountHigh > 0 ?
    `${Intl.NumberFormat('en', { notation: 'compact' }).format(project.statsInvestmentAmountLow)}-${Intl.NumberFormat('en', { notation: 'compact' }).format(project.statsInvestmentAmountHigh)}`
    : "--";
  
  const isVisible = filteredProjects.map( (fproject: any) => fproject.id).includes(project.id);

  const itemClassName = `project-list-item ${project.isClosed ? "closed" : ""} ${isVisible ? "" : "hidden"}`;

  const demand = project.demandOverride ?? project.statsDemand;

  return (
    <ListItem className={itemClassName} onClick={onClick}>
      <div className="project-list-item-info">
        <div className="left">
          <strong className="bold link">{project.name}</strong>
        </div>
      </div>
      <div className="project-list-item-status">
        <Stepper sx={{ width: '100%' }} connector={<DealStageConnector />} activeStep={existingStatus.findIndex(status => status.value === project.status)} alternativeLabel>
          <Step className="status-step">
            <StepLabel StepIconComponent={DealStageStepIcon}>{project.status === "draft" ? "In Progress" : "Done"}</StepLabel>
          </Step>
          <Step className="status-step">
            <StepLabel StepIconComponent={DealStageStepIcon}>{project.statsViewpoints ?? "--"} viewpoints</StepLabel>
          </Step>
          <Step className="status-step">
            <StepLabel StepIconComponent={DealStageStepIcon}>{demand ? (demand * 100).toFixed(0) : '--'}% demand</StepLabel>
          </Step>
          <Step className="status-step">
            <StepLabel StepIconComponent={DealStageStepIcon}>{["spv", "completed"].includes(project.status) ? "Yes" : project.status === "firm_investment" && project.isClosed ? "No" : "Pending"}</StepLabel>
          </Step>
          <Step className="status-step">
            <StepLabel StepIconComponent={DealStageStepIcon}>${investmentRange}</StepLabel>
          </Step>
        </Stepper>
      </div>
    </ListItem>
  );
}

export const DealList = ({ organization, filteredProjects, allProjects }: any) => {
  const navigate = useNavigate();

  const _createProjectLink = (project: any) => {
    if (project.experience === "winning") {
      return navigate(`/o/${organization.tag}/d/${project.tag}`, { state: { organization: organization, project: project } });
    } else if (project.status !== "draft") {
      return navigate(`/o/${organization.tag}/project/${project.tag ? project.tag : "no-name"}`, { state: { project: project } });
    }
    return navigate(`/o/${organization.tag}/project/${project.tag}/edit`, { state: { organization: organization, project: project } });
  };

  const _projectStatus = (statusKey: string) => {
    const status = existingStatus.find(v => v.value == statusKey);
    return status ? status.label : statusKey;
  };

  const sortedProjects = allProjects?.sort((a: any, b: any) => existingStatus.findIndex(status => status.value === b.status) - existingStatus.findIndex(status => status.value === a.status));

  return (
    <List className="deal-list-container">
      <ListItem key={v4()} className="project-list-item">
        <div className="project-list-item-info">
          <div className="left">
            <h4 className="bold">Deals ({filteredProjects?.length} of {sortedProjects?.length})</h4>
          </div>
        </div>
        <div className="project-list-headers">
          <div className="project-list-header">Setup +<br />Launch</div>
          <div className="project-list-header">Collect<br />Viewpoints</div>
          <div className="project-list-header">Analysis +<br />Results</div>
          <div className="project-list-header">Firm<br />Investment</div>
          <div className="project-list-header">BrightLoop<br />Expert SPV</div>
        </div>
      </ListItem>

      {sortedProjects?.filter((project: any) => !project.isSample)?.map((project: any) => {
        return <DealListItem key={v4()} project={project} filteredProjects={filteredProjects} onClick={() => _createProjectLink(project)} />
      })}

      {sortedProjects?.filter((project: any) => project.isSample)?.map((project: any) => {
        return <DealListItem key={v4()} project={project} filteredProjects={filteredProjects} onClick={() => _createProjectLink(project)} />
      })}


    </List>
  );
}

