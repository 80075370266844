import { useAuth0 } from "@auth0/auth0-react"
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { v4 } from "uuid";
import { getNotifications, sendDealNotification } from "../services/gutcheck-admin-api.service";

export const AdminProjectNotifications = () => {

    const { getAccessTokenSilently } = useAuth0();
    const { project } = useOutletContext<any>();
    const [notifications, setNotifications] = useState<any[]>([]);

    const [triggerResults, setTriggerResults] = useState({
        notificationType: "",
        message: ""
    });

    const [notificationForm, setNotificationForm] = useState({
        subject: "",
        headline: "",
        body: ""
    });

    const loadNotifications = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getNotifications(project.id, accessToken);

        if (error) {
            console.error(error);
            return;
        } else {
            setNotifications(data.notifications);
        }
    }

    useEffect(() => {
        loadNotifications();
    }, []);

    const handleClick = async (notificationType: string) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await sendDealNotification(project.id, notificationType, notificationForm, null, accessToken);

        if (error) {
            setTriggerResults({
                notificationType: notificationType,
                message: error.message
            });
            return;
        } else {
            setTriggerResults({
                notificationType: notificationType,
                message: data.message
            });
            await loadNotifications();
        }
    }

    const handleChange = async (e: any) => {
        setNotificationForm({
            ...notificationForm,
            [e.target.name]: e.target.value
        });
    }

    if (!project) return;

    return (
        <div className="admin-screen notification-triggers">
            <div className="admin-table-header">
                <h5>Notifications</h5>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="td-medium">Name</TableCell>
                        <TableCell className="td-large">Description</TableCell>
                        <TableCell className="td-large">Trigger</TableCell>
                        <TableCell className="td-small">Results</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className="strong">Viewpoint Completeed</TableCell>
                        <TableCell>
                            <p>Triggered when an expert completes a viewpoint</p>
                            <p>Sends immediately to experts who:</p>
                            <ul>
                                <li id={v4()}>Completed their evaluation</li>
                            </ul>
                        </TableCell>
                        <TableCell>

                        </TableCell>
                        <TableCell>

                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="strong">Viewpoint Approved</TableCell>
                        <TableCell>
                            <p>Triggered when an expert is pushed to production</p>
                            <p>Sends immediately to experts who:</p>
                            <ul>
                                <li id={v4()}>Had their viewpoint approved, even if not displayed to firm</li>
                            </ul>
                        </TableCell>
                        <TableCell>

                        </TableCell>
                        <TableCell>

                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="strong">Deal Greenlit</TableCell>
                        <TableCell>
                            <p>Only runs when deal is in GREENLIT stage.</p>
                            <p>Sends to experts who:</p>
                            <ul>
                                <li id={v4()}>Completed their evaluation over {project.evaluation_delay_hours} hours ago</li>
                                <li id={v4()}>Had their viewpoint approved</li>
                                <li id={v4()}>Have not already received this notification</li>
                            </ul>
                        </TableCell>
                        <TableCell>
                            {project.spv_stage === "approved" ?
                                <div className="btn btn-primary" onClick={() => handleClick("DEAL_GREENLIT")}>Send Now</div>
                                :
                                <div className="btn btn-primary btn-disabled disabled">Send Now</div>
                            }
                        </TableCell>
                        <TableCell>
                            {triggerResults.notificationType === "DEAL_GREENLIT" &&
                                <p>{triggerResults.message}</p>
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>SPV Opened</TableCell>
                        <TableCell>
                            <p>Only runs when deal is in OPEN stage.</p>
                            <p>Sends to experts who:</p>
                            <ul>
                                <li id={v4()}>Completed their evaluation over {project.evaluation_delay_hours} hours ago</li>
                                <li id={v4()}>Had their viewpoint approved</li>
                                <li id={v4()}>Have not already received this notification</li>
                            </ul>
                        </TableCell>
                        <TableCell>
                            {project.spv_stage === "open" ?
                                <div className="btn btn-primary" onClick={() => handleClick("SPV_OPENED")}>Send Now</div>
                                :
                                <div className="btn btn-primary btn-disabled disabled">Send Now</div>
                            }
                        </TableCell>
                        <TableCell>
                            {triggerResults.notificationType === "SPV_OPENED" &&
                                <p>{triggerResults.message}</p>
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Co-Investment Update</TableCell>
                        <TableCell>
                            <p>Only runs when deal is in OPEN stage.</p>
                            <p>Sends to experts who:</p>
                            <ul>
                                <li id={v4()}>Completed their evaluation over {project.evaluation_delay_hours} hours ago</li>
                                <li id={v4()}>Had their viewpoint approved</li>
                                <li id={v4()}>Have alread received the SPV OPENED notificaiton</li>
                            </ul>
                            <p>Note: This currently doesn't check to see if the user already got this particular update.</p>
                        </TableCell>
                        <TableCell>
                            <Stack direction="column" spacing={2}>
                                <TextField type="text" name="subject" value={notificationForm.subject} label="Subject" onChange={handleChange}></TextField>
                                <TextField type="text" name="headline" value={notificationForm.headline} label="Headline" onChange={handleChange}></TextField>
                                <TextField type="text" multiline minRows={4} name="body" value={notificationForm.body} label="Body" onChange={handleChange}></TextField>
                                {project.spv_stage === "open" ?
                                    <div className="btn btn-primary" onClick={() => handleClick("INVESTMENT_UPDATE")}>Send Now</div>
                                    :
                                    <div className="btn btn-primary btn-disabled disabled">Send Now</div>
                                }
                            </Stack>
                        </TableCell>
                        <TableCell>
                            {triggerResults.notificationType === "INVESTMENT_UPDATE" &&
                                <p>{triggerResults.message}</p>
                            }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <div className="notification-history">
                <h5>Notification History</h5>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Timestamp</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notifications.map((notification: any) => {
                            return (
                            <TableRow>
                                <TableCell>{(new Date(notification.updated_at)).toLocaleString(undefined, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })}</TableCell>                                <TableCell>{notification.type}</TableCell>
                                <TableCell>{notification.email}</TableCell>
                                <TableCell>{notification.status}</TableCell>
                            </TableRow>
                        )})}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}