import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

type TAssetUpload = {
  fileTypes: any,
  queuedFiles: any,
  setQueuedFiles: any,
  initialFile: string
};

const baseStyle = {
  //flex: 1,
  //display: 'flex',
  alignItems: 'center',
  padding: '25% 0',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#1a5dbd',
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  color: '#969696',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  aspectRatio: '16 / 9'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const imgExtension = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
const videoExtension = ['.mp4', '.avi', '.mov', '.wmv', '.flv', '.mkv', '.webm', '.m4v', '.3gp', '.3g2'];
const pdfExtension = ['.pdf'];
const audioExtension = ['.mp3', '.wav', '.ogg', '.m4a', '.flac', '.aac', '.wma', '.aiff', '.alac', '.dsd', '.pcm', '.ape', '.dts', '.ac3', '.mp2', '.mp1', '.mka', '.mks', '.mpa', '.mpc', '.mp+', '.mp4', '.m4b', '.m4p', '.m4r'];

const _humanFileSize = (bytes: number, si=false, dp=1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}


const StyledDropzone =({fileTypes, queuedFiles, setQueuedFiles, initialFile}: TAssetUpload) => {

  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    setQueuedFiles(acceptedFiles);
    const file = new FileReader;

    file.onload = function () {
      setPreview({
        ...preview,
        name: acceptedFiles[0].name,
        url: file.result as string,
        type: acceptedFiles[0]?.type === "application/pdf" ? "pdf" : /^video/.test(acceptedFiles[0]?.type) ? "video" : "image",
        size: acceptedFiles[0]?.size,
        initialized: true
      });
    } 

    file.readAsDataURL(acceptedFiles[0])
  }, []);


  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop, accept: fileTypes });

  const [preview, setPreview] = useState({
    name: "",
    url: initialFile,
    type: /^https:.*\.pdf[?]?.*/.test(initialFile) ? "pdf" : /^https:.*\.(mp4|avi|mov|wmv|flv|mkv|webm|m4v|3gp|3g2)[?].*/.test(initialFile) ? "video" : initialFile !== "" ? "image" : "",
    size: 0,
    initialized: false
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const handleReset = () => {
    setQueuedFiles([]);
    setPreview({
      ...preview,
      name: "",
      url: "",
      type: "",
      size: 0,
      initialized: true
    }); 
  };

  if (initialFile !== "" && preview.url === "" && !preview.initialized) {
    setPreview({
      ...preview,
      name: initialFile.replace(/^.*[\\\/]/, ''),
      url: initialFile,
      type: /^https:.*\.pdf[?].*/.test(initialFile) ? "pdf" : /^https:.*\.(mp4|avi|mov|wmv|flv|mkv|webm|m4v|3gp|3g2)[?].*/.test(initialFile) ? "video" : "image",
      size: 0,
      initialized: true
    })
  }

  //const previewFileType = (queuedFiles[0]?.type === "application/pdf" || /^https:.*\.pdf?.*/.test(initialFile)) ? "pdf" : "video";
  const previewFileType = (queuedFiles[0]?.type === "application/pdf" || /^https:.*\.pdf[?].*/.test(initialFile)) ? "pdf" : (queuedFiles[0]?.type === "video/mp4" || /^https:.*\.(mp4|avi|mov|wmv|flv|mkv|webm|m4v|3gp|3g2)[?].*/.test(initialFile)) ? "video" : "image";

  return (
    <div className="dropzone-container">
      {preview.url === "" && (
        <div {...getRootProps({ style: style })}>
          <input {...getInputProps()} />
          <p>Drag and drop a file here, or click to select one</p>
        </div>
      )}
      {(preview.url !== "" && preview.type === "pdf") && (

        <div className="pdf-preview">
          <object data={preview.url} type="application/pdf">
          </object>
          <p>{preview.name !== "" ? preview.name + " - " : ""} {preview.size > 0 && `(${_humanFileSize(preview.size)}) - `} <span onClick={handleReset}>Replace</span></p>
        </div>
      )}
      {(preview.url !== "" && preview.type === "image") && (

        <div className="pdf-preview">
          <div className="asset-url-container"><img src={preview.url} alt={preview.name} /></div>
          <p>{preview.name !== "" ? preview.name + " - " : ""} {preview.size > 0 && `(${_humanFileSize(preview.size)}) - `} <span onClick={handleReset}>Replace</span></p>
        </div>
      )}
      {(preview.url !== "" && preview.type === "video") && (

        <div className="pdf-preview">
          <video className="asset-url-container" src={preview.url} controls >
            {preview.name}
          </video>
          <p>{preview.name !== "" ? preview.name + " - " : ""} {preview.size > 0 && `(${_humanFileSize(preview.size)}) - `} <span onClick={handleReset}>Replace</span></p>
        </div>
      )}
    </div>
  );
}

export default StyledDropzone;