import { FormControl, FormControlLabel, Radio, RadioGroup, Tooltip, FormGroup, Checkbox } from "@mui/material";
// import ProjectFormData from "../../features/project/ProjectFormData";

type TForm = {
    form: any
};

type TProjectForm = TForm & {
    setForm: any
};

type TProjectHeaderForm = TProjectForm & {
    isDisabled?: boolean
};

type TProjectSideForm = TForm & {
    handleStart: any,
    handleSave: any
};

type TProjectButton = {
    text: string,
    action: any,
    type: string
}

const _contentTypes = [
    { value: "Product description", title: "Product description", subtitle: "One or two pages", description: "A multi paragraph description of a product, how it works, and what its benefits are.", id: 0},
    { value: "Product positioning", title: "Product positioning", subtitle: "One or two paragraphs", description: "A 1-2 paragraph high level overview of a product, including target audience, value prop, and key differentiator.", id: 1},
    { value: "Marketing message", title: "Marketing message", subtitle: "One or two sentences", description: "A short 1-2 sentence marketing copy typically used for ads and landing pages.", id: 2},
];

const _metricTypes = [
    { value: "Demand", title: "Maximize Demand", subtitle: "Maximize the percentage of buyers who would purchase or use the product", id: 0 },
    { value: "Differentiation", title: "Improve Differentiation", subtitle: "Maximize the percentage of buyers who see the product as unique vs. other products", id: 1 },
    { value: "Perceived Value", title: "Increase Perceived Value", subtitle: "Increase the price that buyers will pay for the product", id: 2 },
    { value: "Comprehension", title: "Increase Comprehension", subtitle: "Improve how quickly buyers understand what the product does", id: 3 },
    { value: "Objections", title: "Minimize Objections", subtitle: "Identify and address reasons buyers might not purchase or use the product", id: 4 },
    { value: "Positive Sentiment", title: "Increase Positive Sentiment", subtitle: "Increase the positive feelings and associations around the product", id: 5 },
    { value: "Negative Sentiment", title: "Decrease Negative Sentiment", subtitle: "Identify and minimize negative  associations with the product", id: 6 },
    { value: "Potential Virality", title: "Increase Potential Virality", subtitle: "Increase the likelihood a buyer will tell a friend or colleague about the product", id: 7 },
    { value: "Action", title: "Improve Call to Action", subtitle: "Increase the likelihood that a buyer will sign up, download, purchase the product", id: 8 }
];

const _handleRadioClick = async (event: any) => {
    const typeDiv = event.currentTarget;
    typeDiv.querySelector("input[type=radio]").click();
};

const _handleCheckboxClick = async (event: any) => {
    const typeDiv = event.currentTarget;
    typeDiv.querySelector("input[type=checkbox]").click();
};

const _stopPropagation = async (event: any) => {
    event.stopPropagation();
};

const handleChange = (event: any, form: any, setForm: any) => {
    setForm({
        ...form,
        [event.target.id]: event.target.value,
        form_step: 1
    });
};

const handleTypeChange = (event: any, form: any,  setForm: any) => {
    setForm({
        ...form,
        type: event.target.value
    });
};

const handleMetricChange = (event: any, form: any,  setForm: any) => {
    const eventValue = event.target.value;
    // if event is there, remove it
    if(form.goals.includes(eventValue)) {
        setForm({
            ...form,
            goals: form.goals.filter((v: string) => v !== eventValue)
        });
        return;
    }
    // if is not, add it
    const clicked = [...form.goals];
    clicked[clicked.length] = eventValue;
    setForm({
        ...form,
        goals: clicked
    });
};

export const CreateProjectHero = () => {
    return (
        <div className="splash-hero">
            <div className="hero-title">Make Everything Better.</div>
            <div className="hero-text">BrightLoop combines generative AI and customer feedback to help teams test and improve products, concepts, content, and copy - speeding time to market and product market fit.</div>
        </div>
    );
}

export const CreateProjectHeader = ({form, setForm, isDisabled = false}: TProjectHeaderForm) => {
    return (
        <div className="splash-header"> 
            <div className="oboarding-form-input">
            <input
                id="name"
                type="text"
                value={form.name ?? ''}
                onChange={e => handleChange(e, form, setForm)}
                placeholder={form.user_type === "investor" ? "What company are you evaluating?" : "Give your project a name..."}
                disabled={isDisabled}
            />
            </div>
            <div className="separator"></div>
        </div>
    );
};

export const CreateForm = ({form, setForm}: TProjectForm) => {
    return (
        <div className="splash-view">
             <h4 className="bold">Select Content to Test & Improve</h4>
            <ContentTypeCheckboxes form={form} setForm={setForm}/>

            <br></br>

            {<MetricCheckboxes form={form} setForm={setForm} />}

            <br></br>

            <Tooltip className="splash-tooltip-wrapper" title={<span style={{ fontSize: "14px" }}>Type or paste in the text you want to test and improve.</span>} placement="right">
               <>
                <h4 className="bold">Enter your Content:</h4>

                    <div className="oboarding-form-input">
                    <textarea
                        id="asset_text"
                        value={form.asset_text ? form.asset_text : ""}
                        onChange={e => handleChange(e, form, setForm)}
                    />
                    </div>
               </>
            </Tooltip>

            <br></br>

            <Tooltip className="splash-tooltip-wrapper" title={<span style={{ fontSize: "14px" }}>Provide details on who your target audience is. If you sell to consumers, include things like age, gender, income and geography. If you sell to businesses, include things like the title, department and industry of your ideal customer.</span>} placement="right">
                <>
                    <h4 className="bold">Describe your target audience:</h4>
                    <div className="oboarding-form-input">
                    <textarea
                        id="target_audience"
                        value={form.target_audience ? form.target_audience : ""}
                        onChange={e => handleChange(e, form, setForm)}
                    />
                    </div>
                </>
            </Tooltip>
        </div>
    );
};

export const CreateSideForm = ({form, handleSave, handleStart}: TProjectSideForm) => {
    return (
        <div className="splash-side">
          <CreateProjectButton text={"Save Project"} action={handleSave} type={"secondary"}/>
          <CreateProjectButton text={"Start Project"} action={handleStart} type={"primary"}/>
          <div className="form-error">{form.error_message}</div>
        </div>
    );
};

const ContentTypeCheckboxes = ({form, setForm}: TProjectForm) => {
    const options = _contentTypes.map(type => {
        const className = "splash-input " + (form.type === type.value ? "selected" : form.type !== "" ? "deselected" : "")
        return (
            <Tooltip key={type.id} title={<span style={{ fontSize: "14px" }}>{type.description}</span>} placement="right">
                <div className={className} onClick={_handleRadioClick}>
                    <FormControlLabel label control={<Radio checked={form.type === type.value} />} value={type.value} className="splash-check" onClick={_stopPropagation} />
                    <div className="splash-checkbox-content">
                    <p className="bold">{type.title}</p>
                    <p className="subtitle">{type.subtitle}</p>
                    </div>
                </div>
            </Tooltip>
        );
    });

    return (
        <FormControl className="width-full">
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={e => handleTypeChange(e, form, setForm)}
                // required
            >
                {options}
            </RadioGroup>
        </FormControl>
    );
};

const MetricCheckboxes = ({form, setForm}: TProjectForm) => {
    const options = _metricTypes.map(type => {
        const isChecked = form.goals?.some((v: any) => v === type.value);
        const className = "splash-input " + (isChecked ? "selected" : "")
        return (
            <div key={type.id} className={className} onClick={_handleCheckboxClick}>
                <FormControlLabel label control={<Checkbox checked={isChecked} />} value={type.value} className="splash-check" onClick={_stopPropagation} />
                <div className="splash-checkbox-content">
                    <p className="bold text-start">{type.title}</p>
                    <p className="subtitle text-md">{type.subtitle}</p>
                </div>
            </div>
        );
    });

    return (
        <Tooltip className="splash-tooltip-wrapper" title={<span style={{ fontSize: "14px" }}>These are the goals you want to optimize your content for.</span>} placement="right">
            <>
                <h4 className="bold">Select your Goals (up to two):</h4>
                <p>I want to test and improve my text in order to:</p>
                <FormControl className="width-full">
                    <FormGroup 
                        onChange={e => handleMetricChange(e, form, setForm)}
                        className="metric-container"
                    >
                    {options}
                    </FormGroup>
                </FormControl>
            </>
        </Tooltip>
    );
};

const CreateProjectButton = ({text, action, type="secondary"}: TProjectButton) => {
    return (
        <div className={`side-button button ${type}`} onClick={action}>
            <p className="bold">{text}</p>
        </div>
    );
};