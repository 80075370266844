import { useEffect, useState } from "react";
import useGetProjectData from "./useGetProjectData";
import ProjectFormData from "../features/projects/types/ProjectFormData";

const emptyState: ProjectFormData = {
  name: "",
  user_type: "",
  feedback_type: "",
  type: "",
  asset_text: "",
  asset_url: "",
  asset_file_id: "",
  file: null,
  goals: [],
  target_audience: "",
  key_questions: "",
  iteration_number: 0,
  error_message: "",
  form_step: 0,
  focus_section: '',
  completion_date: null,
  lead_list: null,
  distinction: null,
  market_size: null,
  is_closed: false,
  is_archived: false,
  graph_url: "",
  public_title: "",
  spv_platform_url: "",
  round: "",
  amount_raising: 0,
  amount_premoney_valuation: 0,
  share_class: "",
  pro_rata_rights: false,
  spv_allocation: 0,
  spv_estimated_fees: 0,
  spv_leads_investment: 0,
  spv_total_carry: 0,
  spv_management_fee: "",
  spv_minimum_investment: 0,
  investor_deck: "",
  deal_terms_url: "",
  auto_publish: true,
  distinction_question: "",
  distinction_options: "",
  evaluation_delay_hours: 48,
  spv_platform_name: ""
};

const useCreateProjectFormData = (projectTag: string, isEdit: boolean, iterationNumber: number) => {
    const [form, setForm] = useState(emptyState);

    const projectData = useGetProjectData(projectTag);

    const asset_text = isEdit ? (projectData.project?.iterations.at(-1)?.asset_text ?? "") : (projectData.project?.iterations.at(-1)?.revision_text ?? "");
    const asset_url = isEdit ? (projectData.project?.iterations.at(-1)?.asset_url ?? "") : "";
    const asset_file_id = isEdit ? (projectData.project?.iterations.at(-1)?.asset_file_id ?? "") : "";

    useEffect(() => {
        const initState: ProjectFormData = {
          name: projectData.project?.name ?? "",
          user_type: projectData.project?.user_type ?? "",
          feedback_type: projectData.project?.iterations.at(-1)?.feedback_type ?? "",
          type: projectData.project?.iterations.at(-1)?.type.title ?? "",
          asset_text: asset_text,
          asset_url: asset_url,
          asset_file_id: asset_file_id,
          file: null,
          goals: projectData.project?.iterations.at(-1)?.goals.map((g) :any => g.value) ?? [],
          target_audience: projectData.project?.iterations.at(-1)?.target_audience ?? "",
          key_questions: projectData.project?.iterations.at(-1)?.key_questions ?? "",
          iteration_number: isEdit ? iterationNumber : (iterationNumber + 1),
          error_message: '',
          form_step: asset_text !== "" || asset_url !== "" || asset_file_id !== "" ? 2 : projectData.project?.iterations.at(-1)?.target_audience !== "" ? 3 : 4,
          focus_section: '',
          completion_date: projectData.project?.completion_date ?? null,
          lead_list: projectData.project?.lead_list ?? null,
          distinction: projectData.project?.distinction ?? null,
          market_size: projectData.project?.market_size ?? null,
          is_closed: projectData.project?.is_closed ?? false,
          is_archived: projectData.project?.is_archived ?? false,
          graph_url: projectData.project?.graph_url ?? "",
          public_title: projectData.project?.public_title ?? "",
          spv_platform_url: projectData.project?.spv_platform_url ?? "",
          round: projectData.project?.round ?? "",
          amount_raising: projectData.project?.amount_raising ?? 0,
          amount_premoney_valuation: projectData.project?.amount_premoney_valuation ?? 0,
          share_class: projectData.project?.share_class ?? "",
          pro_rata_rights: projectData.project?.pro_rata_rights ?? false,
          spv_allocation: projectData.project?.spv_allocation ?? 0,
          spv_estimated_fees: projectData.project?.spv_estimated_fees ?? 0,
          spv_leads_investment: projectData.project?.spv_leads_investment ?? 0,
          spv_total_carry: projectData.project?.spv_total_carry ?? 0,
          spv_management_fee: projectData.project?.spv_management_fee ?? "",
          spv_minimum_investment: projectData.project?.spv_minimum_investment ?? 0,
          investor_deck: projectData.project?.investor_deck ?? "",
          deal_terms_url: projectData.project?.deal_terms_url ?? "",
          auto_publish: projectData.project?.auto_publish ?? false,
          distinction_question: projectData.project?.distinction_question ?? "",
          distinction_options: projectData.project?.distinction_options ?? "",
          evaluation_delay_hours: projectData.project?.evaluation_delay_hours ?? 48,
          spv_platform_name: projectData.project?.spv_platform_name ?? ""
        };

        setForm(initState);
    
        return;
    }, [projectData]);

    return [form, setForm] as const;
};

export default useCreateProjectFormData;