import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Table } from "reactstrap";
import { AddOrganizationForm } from "../components/form-add-organization";
import { getOrganizations } from "../services/gutcheck-admin-api.service";

type TAdminOrganizations = {
  organizations: any | null,
  filteredOrganizations: any | null,
  highlightedOrganizations: any | null,
  addOrganizationMessage: any | null
}

const initState: TAdminOrganizations = {
  organizations: null,
  filteredOrganizations: null,
  highlightedOrganizations: null,
  addOrganizationMessage: ""
}

export const AdminOrganizations = () => {

  const [ state, setState ] = useState(initState)
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();

      const [resOrganizations] = await Promise.all([
        getOrganizations(accessToken)
      ]);

      if (resOrganizations) {
        setState({
          ...state,
          organizations: resOrganizations.data.organizations,
          filteredOrganizations: resOrganizations.data.organizations
        });
      }

    };

    fetchData();

    return () => {};
  }, []);

  if (!state.organizations) {
    return <></>
  }

  const handleAddOrganizationClick = async ( event: any ) => {
    event.target.parentElement.parentElement.classList.add("show-form");
    event.preventDefault();
    setState({
      ...state,
      addOrganizationMessage: ""
    })
  }

  const handleCallback = async ( organization: any ) => {

    const accessToken = await getAccessTokenSilently();
    const { data, error } = await getOrganizations(accessToken);

    if(data) {
      setState({
        ...state,
        highlightedOrganizations: organization.id,
        organizations: data.organizations,
        filteredOrganizations: data.organizations,
        addOrganizationMessage: "Organization added successfully."
      });
    } else {
      setState({
        ...state,
        addOrganizationMessage: "There was an error adding the organization"
      })
    }

    document.getElementsByClassName("add-object-wrapper")[0].classList.remove("show-form");
  }

  const handleSearch = (event: any) => {

    const queryString = event.target.value;
    const filteredOrganizations = state.organizations.filter((organization: any) => {
      return organization.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
    });

    setState({
      ...state,
      filteredOrganizations: filteredOrganizations
    });
    
  };

  return (
    <Fragment>
      <div className="flex-box-wrapper">
        <div className="add-object-wrapper">
          <div className="add-object-toggle">
            <div className="add-object-message">{state.addOrganizationMessage}</div>

            <button
              onClick={handleAddOrganizationClick}
            >
              Add Organization
            </button>
          </div>
          <div className="add-object-form">
            <AddOrganizationForm parentCallback={handleCallback} />
          </div>
        </div>
        <div className="admin-list-filter">
          Search: <input type="text" id="search-box" onChange={handleSearch} />
        </div>
      </div>
      <Table responsive hover className="gc-respondent-table"> 
        <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Projects</th>
          <th>Created At</th>       
          <th>Last Updated</th>       
        </tr>
        </thead>
        <tbody>

      {state.filteredOrganizations.map((organization: any) => {
        const url = `/admin/organization/${organization.id}/edit`;
        return (
          <tr key={organization.id} className={state.highlightedOrganizations && organization.id === state.highlightedOrganizations ? "highlighted" : ""}>
            <td>{organization.id}</td>
            <td><Link to={url}>{organization.name ? organization.name : "no name"}</Link></td>
            <td>{organization.projects.length}</td>
            <td>{organization.created_at}</td>
            <td>{organization.updated_at}</td>
          </tr>
        );
      })}
      </tbody>
      </Table>
    </Fragment>
  );

}

export default AdminOrganizations;