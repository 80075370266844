import { useState } from "react";
import { DealExecutive } from "./DealExecutive";
import { Box, Fade, styled, Tab, Tabs, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
    gap: "1rem",
    padding: "1rem"
};

const ExecTabs = styled(Tabs)({
    width: "100%",
    display: "flex",
    "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
        borderBottom: "1px solid #c9c9c9",
        color: "#000000"
    }
});

const ExecTab = styled(Tab)({
    borderLeft: "1px solid #ffffff",
    borderTop: "1px solid #ffffff",
    borderRight: "1px solid #ffffff",
    borderBottom: "1px solid #c9c9c9",
    fontWeight: "500",
    fontSize: "1rem",
    "&.Mui-selected": {
        color: "#000000",
        backgroundColor: "#f8f9fa",
        borderLeft: "1px solid #c9c9c9",
        borderTop: "1px solid #c9c9c9",
        borderRight: "1px solid #c9c9c9",
        borderBottom: "1px solid #c9c9c9",
        "&:hover": {
            backgroundColor: "#f8f9fa",
            color: "#000000"
        }
    },
    "&:hover": {
        backgroundColor: "#ffffff",
        color: "#000000"
    }
});

export const DealExecutivesList = ({project, viewpoints, user}: any) => {

    const [ displayViewpoints, setDisplayViewpoints ] = useState(viewpoints);
    const [ value, setValue ] = useState(0);

    const handleTabSwitch = (event: any, newValue: any) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                setDisplayViewpoints(viewpoints);
                break;
            case 1:
                setDisplayViewpoints(viewpoints.filter((viewpoint: any) => viewpoint?.interest_demo || viewpoint?.interest_beta));
                break;
            case 2:
                setDisplayViewpoints(viewpoints.filter((viewpoint: any) => viewpoint?.interest_investor));
                break;
            case 3:
                setDisplayViewpoints(viewpoints.filter((viewpoint: any) => viewpoint?.interest_advisor));
                break;
            default:
                setDisplayViewpoints(viewpoints);
        }
    }

    const numOffersToAdvise = viewpoints?.filter((viewpoint: any) => viewpoint?.interest_advisor)?.length ?? 0;
    const numDemoOrTrial = viewpoints?.filter((viewpoint: any) => viewpoint?.interest_demo || viewpoint?.interest_beta)?.length ?? 0;
    const numInvestors = viewpoints?.filter((viewpoint: any) => viewpoint?.interest_investor)?.length ?? 0;

    return (
        <>
        <Box>
            <ExecTabs variant="fullWidth" value={value} onChange={handleTabSwitch}>
                <ExecTab label={<span><FontAwesomeIcon icon="users"></FontAwesomeIcon> Gave Viewpoints ({viewpoints.length})</span>}></ExecTab>
                <ExecTab label={<span><FontAwesomeIcon icon="chart-line"></FontAwesomeIcon> Want to Demo/Trial ({numDemoOrTrial})</span>}></ExecTab>
                <ExecTab label={<span><FontAwesomeIcon icon="hand-holding-dollar"></FontAwesomeIcon> Want to Invest ({numInvestors})</span>}></ExecTab>
                <ExecTab label={<span><FontAwesomeIcon icon="handshake-angle"></FontAwesomeIcon> Want to Advise ({numOffersToAdvise})</span>}></ExecTab>
            </ExecTabs>
        </Box>

        { displayViewpoints.length === 0 && 
            <Fade in={true}>
                <Box className="display-box" sx={{marginTop: "40px", maxWidth: "400px", margin: "40px auto"}}>
                    <Typography sx={{textAlign: "center"}}>We’ll notify you when an executive provides a viewpoint or generates an opportunity.</Typography>
                </Box>
            </Fade>
        }
        <Box className="viewpoints-facegrid" sx={gridStyle}>
            { displayViewpoints?.map((viewpoint: any) => (
                <DealExecutive project={project} viewpoint={viewpoint} user={user} style="face-grid" />
            ))}
        </Box>
        </>
    );
}