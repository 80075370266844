import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, BoxProps, styled } from "@mui/material";
import { NavLink } from "react-router-dom";

const DealSidebarBox = styled(Box)<BoxProps>(({ theme }) => ({
    width: "210px",
    position: "fixed",
    top: 0,
    left: 0,
    overflow:"hidden",
    backgroundColor: "#e1e6eb",
    color: "#000000",
    height: "100%",
    padding: "110px 0 20px",
    zIndex: "999",
    transition: "width 0.5s",
    flexGrow: 1,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    "& p, a": {
        display: "block",
        padding: "10px 20px 10px 40px",
        fontSize: "1rem",
        margin: 0,
        textDecoration: "none",
        color: "#000000",
        "&:hover": {
            backgroundColor: "#d3d8dd",
        },
        "&.disabled": {
            color: "#667788",
            cursor: "not-allowed",
            "&:hover": {
                backgroundColor: "#e1e6eb",
            },
        },
        "&.active": {
            backgroundColor: "#667788",
            color: "#ffffff",
            "& span": {
                color: "#ffffff",
            }
        },
        "& .link-stats": {
            marginLeft: "5px",
            color: "#667788",
        }
    },
    "& .tab-section-header": {
        padding: "10px 20px 10px 40px",
        marginTop: "20px",
        color: "#667788",
        fontWeight: 500,
        display: "flex",
        flexDirection: "row",
        "&::after": {
            borderBottom: "1px solid #667788",
            content: '""',
            flex: "1 1",
            margin: "auto",
            marginLeft: "10px",
        },
    },
    [theme.breakpoints.down('md')]: {
        width: "60px",
        "& .link-label": {
            display: "none"
        },
        "& .tab-section-header": {
            padding: "10px 0",
            margin: "0 15px",
            "& .header-label": {
                display: "none"
            },
            "&::after": {
                margin: 0,
            }
        },
        "& p, a": {
            padding: "10px 0",
            textAlign: "center",
            "& span": {
                display: "none"
            }
        }
    },
}));


const SectionLink = ({to, icon, text, stats, enabled}: any) => {

    if (!enabled) {
        return (
            <p className="disabled">
                <FontAwesomeIcon icon={icon}/><span className="link-label">&nbsp;&nbsp;{text}</span>
            </p>
        );
    }

    return (
        <NavLink to={to} end>
            <FontAwesomeIcon icon={icon}/><span className="link-label">&nbsp;&nbsp;{text} {stats && <span className="link-stats">({stats})</span>}</span>
        </NavLink>
    );
}

export const DealSidebar = ({project}: any) => {

    const disabled = (project.viewpoints.length === 0);
    const hasViewpoints = project.viewpoints.length > 0;
    const isDraft = project.status === "draft";

    const numOffersToAdvise = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_advisor)?.length ?? 0;
    const numDemoOrTrial = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_demo || viewpoint?.interest_beta)?.length ?? 0;
    const numInvestors = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_investor)?.length ?? 0;
    const numCandidates = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_work)?.length ?? 0;  
    const totalProspectiveSales = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_demo || viewpoint?.interest_beta)?.reduce((acc: number, viewpoint: any) => acc + viewpoint?.max_spend, 0) ?? 0;

    const totalPotentialInvestment = project?.viewpoints?.filter((viewpoint: any) => (viewpoint?.interest_investor || viewpoint.survey_response?.funded || viewpoint.survey_response?.reserved_spot))?.reduce((acc: number, viewpoint: any) => {
            if (viewpoint?.survey_response?.funded) return acc + viewpoint?.survey_response?.funded_amount;
            if (viewpoint?.survey_response?.reserved_spot) return acc + viewpoint?.survey_response?.reserved_amount;  
            return acc + (viewpoint?.investment_amount_high + viewpoint?.investment_amount_low)/2;
        }, 0) ?? 0;


    return (
        <DealSidebarBox>
            <div className="deal-tabs">
                <SectionLink to={`/o/${project.organization.tag}/d/${project.tag}`} icon="home" text="Executives" enabled={!isDraft}/>
                
                <div className="tab-section-header"><span className="header-label">Insights</span></div>
                <SectionLink to={`/o/${project.organization.tag}/d/${project.tag}/viewpoints`} icon="users" text="Viewpoints" stats={project?.viewpoints.length} enabled={hasViewpoints}/>
                <SectionLink to={`/o/${project.organization.tag}/d/${project.tag}/analysis`} icon="lightbulb" text="Analysis" enabled={project.iterations?.at(-1)?.analysis_url && project.iterations?.at(-1)?.analysis_url !== ""}/>

                <div className="tab-section-header"><span className="header-label">Opportunities</span></div>
                <SectionLink to={`/o/${project.organization.tag}/d/${project.tag}/prospects`} icon="chart-line" text="Prospects" stats={`\$${Intl.NumberFormat('en', { notation: 'compact' }).format(totalProspectiveSales)}`} enabled={hasViewpoints}/>
                <SectionLink to={`/o/${project.organization.tag}/d/${project.tag}/industry-angels`} icon="hand-holding-dollar" text="Angels" stats={`\$${Intl.NumberFormat('en', { notation: 'compact' }).format(totalPotentialInvestment)}`} enabled={hasViewpoints}/>
                <SectionLink to={`/o/${project.organization.tag}/d/${project.tag}/advisors`} icon="handshake-angle" text="Advisors" stats={numOffersToAdvise} enabled={false}/>
                <SectionLink to={`/o/${project.organization.tag}/d/${project.tag}/candidates`} icon="building-user" text="Candidates" stats={numCandidates} enabled={false}/>

                <div className="tab-section-header"><span className="header-label">Management</span></div>
                <SectionLink to={`/o/${project.organization.tag}/d/${project.tag}/setup`} icon="gear" text="Company Setup" enabled={true}/>
                <SectionLink to={`/o/${project.organization.tag}/d/${project.tag}/collaboration`} icon="user-plus" text="Collaboration" enabled={false}/>
            </div>
        </DealSidebarBox>

    );
}