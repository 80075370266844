import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Hero from "../components/Hero";
import { useAuth0 } from "@auth0/auth0-react";

export const HomeComponent = () => {

  const navigate = useNavigate();

  const {
    isLoading,
    isAuthenticated
  } = useAuth0();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      return navigate("./dashboard");
    }
  }, [isLoading, isAuthenticated])

  return (
    <main className="page-main">
      <div className="gc_view">
        <Hero />
      </div>
    </main>
  );


}

export default HomeComponent;
