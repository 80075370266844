import { Route, Routes } from "react-router-dom";
import { LegalTerms } from "./legal-terms";
import { LegalPrivacy } from "./legal-privacy";
import { Legal } from "./Legal";

export const LegalRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Legal />}>
                <Route index element={<LegalTerms />} />
                <Route path="privacy" element={<LegalPrivacy />} />
            </Route>
        </Routes>
    );
}