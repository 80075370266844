const NotFound = () => (
  <div className="text-center hero my-5">
    <h3>Page not found</h3>

    <p className="lead">
      The page you're looking for does not exist or you are not authorized to view it.      
    </p>
  </div>
);

export default NotFound;