import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TableContainer, Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 } from "uuid";
import { Viewpoint } from "./Viewpoint";
import { useEffect, useState } from "react";

type TProjectResponses = {
  viewerRole: string,
  project: any,
  respondent: any,
  handleRespondant: any
}

export const Viewpoints = ({ viewerRole, project, respondent, handleRespondant }: TProjectResponses) => {

  const [ respondents, setRespondents ] = useState(viewerRole === "guest" ? project.viewpoints.filter((viewpoint: any) => !viewpoint.hidden_from_guest) : project.viewpoints);

  const interestFields = [
    {
      key: "interest_demo",
      text: "Demo",
      count: 0,
      countText: "want a"
    },
    {
      key: "interest_beta",
      text: "Beta",
      count: 0,
      countText: "want to"
    },
    {
      key: "interest_investor",
      text: "Co-Invest",
      count: 0,
      countText: "want to"
    },
    {
      key: "interest_advisor",
      text: "Advise",
      count: 0,
      countText: "want to"
    },
    {
      key: "interest_employment",
      text: "Job",
      count: 0,
      countText: "want a"
    },
    {
      key: "interest_followup",
      text: "Follow Up",
      count: 0,
      countText: 'open to'
    },
  ];

  if (!project.viewpoints) return <></>;

  useEffect(() => {
    setRespondents(viewerRole === "guest" ? project.viewpoints.filter((viewpoint: any) => !viewpoint.hidden_from_guest) : project.viewpoints);
  }
  , [project, viewerRole]);

  //const respondents = viewerRole === "guest" ? project.viewpoints.filter((viewpoint: any) => !viewpoint.hidden_from_guest) : project.viewpoints;

  //const validInterestFields = respondents[0] && respondents[0].interests ? Object.entries(respondents[0].interests).map((interest) => { return interest[0]; }) : [];
  let lowerInvestmentAmount = 0;
  let upperInvestmentAmount = 0;
  let investorCount = 0;

  respondents.map((viewpoint: any) => {
    const liClass = viewpoint.linkedin ? "" : "hidden";
    if (viewpoint.interest_investor) investorCount++;
    if (viewpoint.investment_amount_low && viewpoint.investment_amount_low !== "") lowerInvestmentAmount += viewpoint.investment_amount_low;
    if (viewpoint.investment_amount_high && viewpoint.investment_amount_high !== "") upperInvestmentAmount += viewpoint.investment_amount_high;

    interestFields.map((interestField) => {
      //if (validInterestFields.includes(interestField.key)) {
        if (viewpoint[interestField.key]) interestField.count++;
      //}
    });
  });

  const validInterestFields = interestFields.map((interestField) => { if (interestField.count) return interestField.key; });

  const numCols = 6 + validInterestFields.length;

  let borderCount = 0;

  return (
    <>
      <Viewpoint key={respondent.uuid} project={project} respondent={respondent} handleRespondant={handleRespondant} />
      <TableContainer className="gc-respondent-table-container" key={v4()}>
      <Table stickyHeader className="gc-respondent-table" key={v4()}>
        <TableHead key={v4()}>
          <TableRow key={v4()}>
            <TableCell key={v4()} className="center"><FontAwesomeIcon icon="film" /></TableCell>
            <TableCell key={v4()}>Name</TableCell>
            <TableCell className="center" key={v4()}>Links</TableCell>
            <TableCell key={v4()}>Title</TableCell>
            <TableCell key={v4()}>Company</TableCell>
            { viewerRole !== "guest" && false && <TableCell key={v4()}>ICP</TableCell> }
            <TableCell className="pill pill-lg" key={v4()}>Demand</TableCell>

            {interestFields.map((interestField) => {
              if (validInterestFields.includes(interestField.key)) {
                return (
                  <TableCell className="pill pill-sm" key={v4()}>
                    <div className="pill-summary">
                      <span className="pill-summary-number">{interestField.count}</span><br />
                      {interestField.countText}<br />
                      {interestField.text.toLowerCase()}
                    </div>
                    <div className="pill-detail">
                      {interestField.text}?
                    </div>
                  </TableCell>
                )
              }
            })}

          </TableRow>
        </TableHead>
        <TableBody key={v4()}>
          {respondents.map((viewpoint: any) => {
            const liClass = viewpoint.linkedin ? "" : "hidden";
            const respondentId = parseInt(viewpoint.id);
            //if (respondent.interests.investorInterest === "Y") investorCount++;
            //if (respondent.investmentAmountLower && respondent.investmentAmountLower !== "") lowerInvestmentAmount += parseInt(respondent.investmentAmountLower.replace(/[\$\,]/g, ""));
            //if (respondent.investmentAmountLower && respondent.investmentAmountUpper !== "") upperInvestmentAmount += parseInt(respondent.investmentAmountUpper.replace(/[\$\,]/g, ""));
            return (
<>
              { viewerRole !== "guest" && !viewpoint.included_in_analysis && borderCount++ === 0 &&
              <TableRow key={v4()}>
                <TableCell key={v4()} colSpan={numCols} className="border-row">The Viewpoints below are not included in the Analysis, because they were added after the Analysis was generated or they are not in the ICP.</TableCell>
              </TableRow> }
              <TableRow hover key={v4()}>
                <TableCell key={v4()} className="center">{viewpoint.has_video ? <FontAwesomeIcon icon="film" /> : viewpoint.has_audio ? <FontAwesomeIcon icon="volume-high" /> : <></>}</TableCell>
                <TableCell key={v4()} onClick={handleRespondant} id={viewpoint.uuid} className="link bold">{viewpoint.name ? viewpoint.name : "Anonymous"}</TableCell>
                {/* <td onClick={handleRespondant} id={respondent.uuid} className="link bold">{respondent.name ? respondent.name : "Anonymous"}</td> */}
                <TableCell key={v4()} className="center"><a href={viewpoint.linkedin} target={"_new"} className={liClass}><img className="li-icon" src={'/images/linkedin.png'} /></a></TableCell>
                <TableCell key={v4()}>{viewpoint.title}</TableCell>
                <TableCell key={v4()}>{viewpoint.company_name}</TableCell>
                { viewerRole !== "guest" && false && <TableCell key={v4()} className="center">{!viewpoint.exclude_from_stats && <FontAwesomeIcon className="analysis-dot" icon="check"/>}</TableCell> }
                <TableCell key={v4()} className="pill pill-lg">
                  <div className={viewpoint.demand.replace(/(Yes|No), /g, "").replace(/ /g, "").toLowerCase()}>{viewpoint.demand.replace(/(Yes|No), /g, "")}</div>
                </TableCell>

                {interestFields.map((interestField) => {
                  if (validInterestFields.includes(interestField.key)) {
                    //if (respondent.interests[interestField.key] === "Y") interestField.count++;
                    return (
                      <TableCell key={v4()} className="pill pill-sm">
                        <div className={viewpoint[interestField.key] ? "y" : "n"}>{viewpoint[interestField.key] ? "Y" : "N"}</div>
                      </TableCell>
                    )
                  }
                })}
              </TableRow>
              </>
            );
          })}
        </TableBody>
        <TableFooter key={v4()}>
          <TableRow key={v4()}>
            <TableCell key={v4()}></TableCell>
            <TableCell key={v4()}></TableCell>
            <TableCell key={v4()}></TableCell>
            <TableCell key={v4()}></TableCell>
            <TableCell key={v4()}></TableCell>
            { viewerRole !== "guest" && false && <TableCell key={v4()}></TableCell> }
            <TableCell key={v4()} className="interest-total-label">Total</TableCell>
            {interestFields.map((interestField) => {
              if (validInterestFields.includes(interestField.key)) {
                return (
                  <TableCell key={v4()} className="">
                    <div className="pill-summary">
                    <span className="pill-summary-number">{interestField.count}</span><br />
                      {interestField.countText}<br />
                      {interestField.text.toLowerCase()}
                    </div>
                  </TableCell>
                )
              }
            })}
          </TableRow>
        </TableFooter>
      </Table>
      </TableContainer>
      {(investorCount > 0) && (
        <div className="investment-footer">
          Potential Investment: ${(Math.round(lowerInvestmentAmount / 1000) * 1000).toLocaleString("en-US")} - ${(Math.round(upperInvestmentAmount / 1000) * 1000).toLocaleString("en-US")} from {investorCount} investors
        </div>
      )
      }
    </>
  );
};