import { Box, Dialog, MenuItem, Stack, TextField, Typography } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react";
import { addOrganizationMember, getUserByEmailAddress } from "../../services/gutcheck-admin-api.service";
import { useState } from "react";

const roles = [
    { value: "admin", label: "Admin" },
    { value: "contributor", label: "Contributor" },
    { value: "guest", label: "Guest" },
];



export const AddOrganizationMember = ({ organization, reloadOrganization, open, setOpen}: any) => {

    const { getAccessTokenSilently } = useAuth0();
    const [ memberForm, setMemberForm ] = useState<{ email_address: string; name: string; role: string; }>({
        email_address: "",
        name: "",
        role: ""
    })
    const [ lookupMessage, setLookupMessage ] = useState("");
    

    const handleChange = (e: any) => {
        setMemberForm({
            ...memberForm,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const accessToken = await getAccessTokenSilently();
         const { data, error } = await addOrganizationMember(organization.id, memberForm, accessToken);

         if (data) {
             reloadOrganization();
             setOpen(false);
         }
    }

    const handleClose = () => {
        setMemberForm({
            email_address: "",
            name: "",
            role: "",
        });
        setOpen(false);
    }

    const handleLookup = async (e: any) => {
        e.preventDefault();
        const accessToken = await getAccessTokenSilently();
        const {data, error} = await getUserByEmailAddress(memberForm.email_address, accessToken);
        if (data?.user) {
            setMemberForm({
                ...memberForm,
                name: data.user.name
            });
            setLookupMessage("User found");
        } else {
            setLookupMessage("User not found");
        }
    }

    return (
        <Dialog open={open}>
            <h5>Add Member</h5>
            <form>

            <Stack spacing={2}>
                <TextField id="email_address" name="email_address" label="Email" value={memberForm.email_address} onChange={handleChange}/>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <Typography>{lookupMessage}</Typography>
                    <Typography className="link" onClick={handleLookup}>Lookup</Typography>
                </Box>
                <TextField id="name" name="name" label="Name" value={memberForm.name} onChange={handleChange}/>
                <TextField select id="role" name="role" label="Role" value={memberForm.role} onChange={handleChange}>
                    {roles.map((role) => (
                        <MenuItem key={role.value} value={role.value}>
                            {role.label}
                        </MenuItem>
                    ))}
                </TextField>
                <button type="button" onClick={handleSubmit}>Add Member</button>
                <button type="button" className="btn-secondary" onClick={handleClose}>Cancel</button>
            </Stack>
            </form>
        </Dialog>
    )

}