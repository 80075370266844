import TextEditor from '../../../components/TextEditor';
import { FormControlLabel, FormGroup, FormLabel, Input, Stack, Switch, TextField, ToggleButton } from '@mui/material';
import React, { useState, useEffect } from 'react';

interface SurveyLinkFormProps {
    initialData?: any; // Replace with your actual data type
    onSubmit: (data: any) => void; // Replace with your actual data type
}

export const SurveyLinkForm = ({ initialData = {}, onSubmit }: any) => {
    const [data, setData] = useState(initialData);

    useEffect(() => {
        setData(initialData);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        setData({
            ...data,
            [event.target.name]: newValue,
        });
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSubmit(data);
    };

    return (
        <Stack
            sx={{
                width: '50ch',
            }}
            spacing={2}
            
        >
            
            <TextField name="name" label="Name" value={data.name} onChange={handleChange} />
            <FormControlLabel name="is_active" control={<Switch checked={data.is_active} onChange={handleChange} />} label="Is Active" />
            <FormControlLabel name="enable_email" control={<Switch checked={data.enable_email} onChange={handleChange} />} label="Enable Email" />
            <br/>
            <TextField name="reward_description" label="Reward Description" value={data.reward_description} onChange={handleChange} />
            <TextField name="duration_description" label="Duration Description" value={data.duration_description} onChange={handleChange} />
            <br/>
            <TextField name="partner_name" label="Partner Name" value={data.partner_name} onChange={handleChange} />
            <TextField name="survey_url" label="Survey URL" value={data.survey_url} onChange={handleChange} />
            <TextField name="link_params" label="Survey URL Params" value={data.link_params} onChange={handleChange} />
            <br/>
            <FormControlLabel name="passthrough" control={<Switch checked={data.passthrough} onChange={handleChange} />} label="Passthrough" />
            <TextField name="complete_redirect_url" label="Redirect URL: Completed" value={data.complete_redirect_url} onChange={handleChange} />
            <TextField name="screen_redirect_url" label="Redirect URL: Screen Out" value={data.screen_redirect_url} onChange={handleChange} />
            <TextField name="quota_redirect_url" label="Redirect URL: Quote Reached" value={data.quota_redirect_url} onChange={handleChange} />
            <TextField name="quality_redirect_url" label="Redirect URL: Quality" value={data.quality_redirect_url} onChange={handleChange} />
            <br/>
            <p className="bold">Custom Overview Text</p>
            <div className="display-box white">
            <TextEditor content={data.overview_text} id="overview_text" form={data} setForm={setData} />
            </div>

            <button type="submit" onClick={handleSubmit}>Submit</button>
        </Stack>
    );
};