export const ControlBox = ({ form, setForm, stage, handleChange, handleSubmit, handleReset, handleSave, active }: any) => {
    return (
        <div className={`controls ${stage}`}>
            <p>
                <label>Temperature</label><br/>
                <input type="text" id="temperature" value={form.temperature} onChange={e => handleChange(e, form, setForm)}
/>
            </p>
            <p>
                <label>Prompt {(form.promptActive === form.promptOriginal) ? `- v${form.promptVersion}` : "- unsaved"}</label><br/>
                <textarea
                    id="promptActive"
                    name={`prompt-${stage}`}
                    onChange={e => handleChange(e, form, setForm)}
                    value={form.promptActive}></textarea>
            </p>
            <p className={(form.promptActive === form.promptOriginal) ? "btn btn-secondary disabled" : "btn btn-secondary"} onClick={e => handleReset(e, form, setForm)}>Reset</p>
            <p className={(form.promptActive === form.promptOriginal) ? "btn btn-secondary disabled" : "btn btn-secondary"} onClick={e => handleSave(e, form, setForm)}>Save</p>
            <p></p>
            <p>
                <label>Formatted Prompt used by LLM</label><br/>
                <textarea disabled value={form.formattedPrompt}>
                </textarea>
            </p>
        </div>
    );
}