import { useAuth0 } from "@auth0/auth0-react";
import Project from "../../../features/projects/types/Project";
import { useDealUpdates } from "../hooks/useDealUpdates";
import { createDealUpdate, updateDealUpdate } from "../services/gutcheck-admin-api.service";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { DealUpdateForm } from "./components/DealUpdateForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DealUpdateNotification } from "./components/DealUpdateNotification";
import { faClock as faClockRegular } from '@fortawesome/free-regular-svg-icons/faClock';
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { useInvestmentMaterials } from "../hooks/useInvestmentMaterials";
import { InvestmentMaterialForm } from "./components/InvestmentMaterialForm";

const docsendModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    width: '90%',
    aspectRatio: '4/3'
};

export const AdminInvestmentMaterials = () => {

    const { project } = useOutletContext<any>();
    const { fetched, investmentMaterials, error, reloadInvestmentMaterials } = useInvestmentMaterials(project.id ?? "");
    const [ investmentMaterial, setInvestmentMaterial ] = useState(null);
    const [ editMode, setEditMode ] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalDocument, setModalDocument] = useState("");


    const handleEdit = (investmentMaterial: any) => {
        setInvestmentMaterial(investmentMaterial);
        setEditMode(true);
    }

    const handleOpen = (doc: string) => {
        setModalDocument(doc);
        setOpenModal(true);
    }

    const handleClose = () => {
        setOpenModal(false);
    }

    if (!fetched) { return <></>; }

    let systemNotificationCount = 0;

    return (
        <div className="admin-screen">
            <div className="admin-table-header">
                <h5>Investment Materials</h5>
                <div className="btn btn-primary" onClick={handleEdit}>Add New Materials</div>
            </div>

            <TableContainer>
                <Table className="admin-investment-materials">
                    <TableBody>
                        {investmentMaterials.map((investmentMaterial: any) => (
                            <TableRow key={investmentMaterial.uuid} className={investmentMaterial.is_published ? "published" : "not-published"}>
                                <TableCell>{investmentMaterial.index}</TableCell>
                                <TableCell><div className="document-placeholder" onClick={() => handleOpen(investmentMaterial.asset_url)}><img className="document-preview" src={investmentMaterial.thumbnail_url} /></div></TableCell>
                                <TableCell>
                                    <h5>{investmentMaterial.title}</h5>
                                    <p>{investmentMaterial.description}<br />
                                        <span className="link" onClick={() => handleOpen(investmentMaterial.asset_url)}>View</span></p>
                                </TableCell>
                                <TableCell className="center">{investmentMaterial.is_published ? "Published" : "Not Published"}</TableCell>
                                <TableCell className="admin-action link" onClick={() => handleEdit(investmentMaterial)}><FontAwesomeIcon icon="edit"/></TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <Modal open={openModal} onClose={handleClose}>
                <Box sx={docsendModalStyle}>
                    <iframe className="investor-deck" src={modalDocument} allow="fullscreen"></iframe>
                </Box>
            </Modal>
            { editMode &&
                <InvestmentMaterialForm project={project} investmentMaterial={investmentMaterial} investmentMaterials={investmentMaterials} reloadInvestmentMaterials={reloadInvestmentMaterials} editMode={editMode} setEditMode={setEditMode}/>
            }

        </div>
    );
}