import { useState } from "react";
import { Modal, Box, Typography, FormControl, InputLabel, TextareaAutosize, Input, TextField, Tabs, Tab } from "@mui/material";
import { Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { createInvitations } from "../../../core/services/gutcheck-api.service";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAnalytics from "../../../hooks/useAnalytics";

type TOrganization = {
    organization: any
}

type TOrganizationInviteSuccess = TOrganization & {
    invitationForm: any
}

type TOrganizationInvite = TOrganization & {
    handleInvitationChange: any,
    handleInvitationSubmit: any
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 900,
    bgcolor: 'background.paper',
    border: '1px solid #c9c9c9',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};

const OrganizationInviteForm = ({ organization, handleInvitationChange, handleInvitationSubmit }: TOrganizationInvite) => {
    return (
        <Box sx={style}>
            <Tabs className="modal-modal-tabs" value="contributor" variant="fullWidth">
                <Tab label="Invite Team Members" value="contributor"/>
            </Tabs>
            <div>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                    <b>Invite Team Members</b>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Invite your <strong>{organization.name}</strong> team members to view results and collaborate on your deals.
                </Typography>
                <Typography id="modal-modal-warning" className="not-safe" sx={{ mt: 2}}>
                    <FontAwesomeIcon icon="triangle-exclamation" />&nbsp;&nbsp;&nbsp;Team members have <strong>full access</strong> to <b>all</b> deals in your workspace.
                </Typography>
            </div>
            <FormControl fullWidth>
                <TextField
                    multiline
                    type="text"
                    id="invitee_emails"
                    minRows={5}
                    maxRows={5}
                    placeholder="Email addresses (seperated by space, comma, or new line)"
                    className="invitation-email-list"
                    onChange={handleInvitationChange}
                />
                <Button
                    color="primary"
                    onClick={handleInvitationSubmit}>Invite Team Members</Button>

            </FormControl>
        </Box>
    )
}

const OrganizationInviteSuccess = ({organization, invitationForm}: TOrganizationInviteSuccess) => {

    const numInvites = invitationForm.processedInvitations?.length;
    return (
        <Box sx={style}>
            <Tabs className="modal-modal-tabs" value="contributor" variant="fullWidth">
                <Tab label="Invite Team Members" value="contributor"/>
            </Tabs>
            <div>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                    <b>Invite Team Members</b>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Invite your <strong>{organization.name}</strong> team members to view results and collaborate on your deals.
                </Typography>
                <Typography id="modal-modal-warning" className="not-safe" sx={{ mt: 2}}>
                    <FontAwesomeIcon icon="triangle-exclamation" />&nbsp;&nbsp;&nbsp;Team members have <strong>full access</strong> to <b>all</b> deals in your workspace.
                </Typography>
            </div>
            <Typography id="modal-modal-success" sx={{ mt: 2 }}>
                Success! You sent {numInvites > 1 ? `${numInvites} invitations` : `${numInvites} invitation`} to:
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <ul>
                    {invitationForm.processedInvitations.map( (invitee: any) => {
                        return <li>{invitee}</li>
                    })}
                </ul>
            </Typography>
        </Box>
    )
}

const OrganizationWelcome = ({organization}: TOrganization) => {

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
                Welcome to the {organization.name} team on BrightLoop!
            </Typography>
        </Box>
    )
}

export const OrganizationInviteControl = ({ organization }: TOrganization) => {

    const { state } = useLocation();
    const { trackEvent } = useAnalytics();

    const [invitationForm, setInvitiationForm] = useState({
        inviteeEmails: "",
        submitted: false,
        showWelcome: state?.invitationAccepted ?? false,
        processedInvitations: [],
        inviteeRole: "contributor"
    });

    const [open, setOpen] = useState(state?.invitationAccepted ?? false);

    const { getAccessTokenSilently } = useAuth0();

    const handleOpen = () => {
        setInvitiationForm({
            ...invitationForm,
            inviteeEmails: "",
            submitted: false
        });
        setOpen(true);
    }

    const handleClose = () => {
        setInvitiationForm({
            ...invitationForm,
            showWelcome: false
        });
        setOpen(false);
    }

    const handleInvitationSubmit = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await createInvitations(invitationForm.inviteeEmails, organization.id, null, "contributor", accessToken);

        if (!error) {
            setInvitiationForm({
                ...invitationForm,
                inviteeEmails: "",
                submitted: true,
                processedInvitations: data.invitees.map((invitee: any) => invitee.invitee_email)
            });

            data.invitees.map((invitee: any) => {
                trackEvent("Sent team invitation", { organization: organization.tag, inviteeEmail: invitee.invitee_email, inviteeRole: invitee.invitee_role, approvalStatus: invitee.approval_status });
            });
        }
    }

    const handleInvitationChange = (event: any) => {
        setInvitiationForm({
            ...invitationForm,
            inviteeEmails: event.target.value,
            submitted: false
        });
    };

    return (
        <div>
            <Button
                onClick={handleOpen}
                color="primary"
            ><FontAwesomeIcon icon="user-plus"/>&nbsp;&nbsp;&nbsp;Invite team members</Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="invitation-container"
            >
                <div>
                {invitationForm.submitted ? 
                    <OrganizationInviteSuccess organization={organization} invitationForm={invitationForm}/>
                : invitationForm.showWelcome ?
                    <OrganizationWelcome organization={organization}/>
                :
                    <OrganizationInviteForm organization={organization} handleInvitationChange={handleInvitationChange} handleInvitationSubmit={handleInvitationSubmit} />
                }
                </div>
            </Modal>
        </div>
    )
}