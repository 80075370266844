import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import ClientProvider from '../../../modules/ClientProvider';

export interface iDealContext {
    dealContext: {
        project: any;
        role: any;
        fetched: boolean;
    };
    setDealContext: React.Dispatch<React.SetStateAction<any>>;
    loadDealContext: (projectTag: string) => void;
}

export const DealContext = createContext<iDealContext>({
    dealContext: {
        project: {},
        role: "",
        fetched: false
    },
    setDealContext: () => { },
    loadDealContext: () => { }
});


const loadDealContext = (projectTag: string) => {
    const { getAccessTokenSilently } = useAuth0();
    const { dealContext, setDealContext } = useContext(DealContext);

    useEffect(() => {
        const getDealContext = async () => {
            const accessToken = await getAccessTokenSilently();
            const projectResponse = await ClientProvider.provideProjectClient().getProjectData(projectTag, accessToken);
            if (projectResponse.data) {
                setDealContext({
                    ...dealContext,
                    project: projectResponse.data.project,
                    fetched: true
                });
            }
        }
        getDealContext();
    }, []);
}

export const useDealContext = () => {
    const context = useContext(DealContext);
    if (!context) {
        throw new Error('useDealContext must be used within a DealProvider');
    }
    return context;
};

export const DealProvider = ({ children }: { children: ReactNode }) => {
    const [dealContext, setDealContext] = useState<any>({
        project: {},
        role: "",
        fetched: false
    });

    return (
        <DealContext.Provider value={{ dealContext, setDealContext, loadDealContext }}>
            {children}
        </DealContext.Provider>
    );
}