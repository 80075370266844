import { EditorProvider, FloatingMenu, BubbleMenu, useEditor, EditorContent } from '@tiptap/react'
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import { useCallback, useEffect, useState } from 'react';
import Collaboration from '@tiptap/extension-collaboration'
import * as Y from 'yjs'
import { TiptapCollabProvider } from '@hocuspocus/provider'
import ClientProvider from "../modules/ClientProvider";
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import { WebrtcProvider } from 'y-webrtc'
import { useAuth0 } from '@auth0/auth0-react';

type TCollaborativeEditor = {
    content: string,
    documentName: string,
    form: any,
    setForm: any
};

type CollaboratorProps = {
    email: string,
    color: string
}

type EditorProps = {
    content: string,
    doc: Y.Doc,
    provider: any,
    collaborator: CollaboratorProps, 
    form: any,
    setForm: any
}

const randomPastelColor = () => {
    const colors = ['#FFB3BA', '#FFDFBA', '#FFFFBA', '#BAFFC9', '#BAE1FF', '#D5BAFF'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
};

const TipTapEditor = ({ content, doc, provider, collaborator, form, setForm}: EditorProps) => {

    const extensions = [
        StarterKit.configure({
            history: false,
        }),
        Collaboration.configure({
            document: doc,
        }),
        CollaborationCursor.configure({
            provider: provider,
            user: {
                name: collaborator.email,
                color: collaborator.color
            }
        }),
        Link.configure({
            openOnClick: false
        })
    ];
    const editor = useEditor({
        extensions: extensions,
        editorProps: {
            attributes: {
                class: "Editor"
            }
        },
        content: content,
        onUpdate: ({ editor }) => {
            setForm( editor.getHTML());
        }
    });


    const setLink = useCallback(() => {
        if (editor) {
            const previousUrl = editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                editor.chain().focus().extendMarkRange('link').unsetLink()
                    .run()

                return
            }

            // update link
            editor.chain().focus().extendMarkRange('link').setLink({ href: url })
                .run()
        }
    }, [editor])

    return (
        <div className="text-editor-container">
            {editor && (
                <EditorContent editor={editor}>
                    <FloatingMenu className="floating-menu"
                        editor={editor}
                        tippyOptions={{ duration: 100 }}
                    >
                        <button
                            type="button"
                            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                        >
                            H1
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                        >
                            H2
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('bold') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleBold().run()}
                        >
                            B
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('italic') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleItalic().run()}
                        >
                            <span className="italic">I</span>
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('bulletList') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleBulletList().run()}
                        >
                            <FontAwesomeIcon icon="list" />
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('orderedList') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        >
                            <FontAwesomeIcon icon="list-ol" />
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('link') ? 'is-active' : ''}
                            onClick={setLink}
                        >
                            <FontAwesomeIcon icon="link" />
                        </button>
                    </FloatingMenu>
                    <BubbleMenu
                        className="bubble-menu"
                        editor={editor}
                        tippyOptions={{ duration: 100 }}
                    >
                        <button
                            type="button"
                            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                        >
                            H1
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                        >
                            H2
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('bold') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleBold().run()}
                        >
                            B
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('italic') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleItalic().run()}
                        >
                            <span className="italic">I</span>
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('bulletList') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleBulletList().run()}
                        >
                            <FormatListBulletedIcon />
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('orderedList') ? 'is-active' : ''}
                            onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        >
                            <FormatListNumberedIcon />
                        </button>
                        <button
                            type="button"
                            className={editor.isActive('link') ? 'is-active' : ''}
                            onClick={setLink}
                        >
                            <FontAwesomeIcon icon="link" />
                        </button>
                    </BubbleMenu>
                </EditorContent>
            )}
        </div>
    )

}
const CollaborativeEditor = ({ documentName, content, form, setForm }: TCollaborativeEditor) => {

    const { getAccessTokenSilently } = useAuth0();
    const [ provider, setProvider ] = useState<any>(null);
    const [ doc, setDoc] = useState<Y.Doc>();
    const [ collaborator, setCollaborator ] = useState({
        email: "",
        color: ""
    });

    useEffect(() => {

        const connectToProvider = async () => {

            const accessToken = await getAccessTokenSilently();
            const { data, error } = await ClientProvider.provideProjectClient().getCollaborationToken(accessToken);
            const token = data.token;
            const color = randomPastelColor();

            const yDoc = new Y.Doc();
            const provider = new TiptapCollabProvider({
                name: documentName, // Unique document identifier for syncing. This is your document name.
                appId: '0k3q6n95', // Your Cloud Dashboard AppID or `baseURL` for on-premises
                token: token, // The token you generated on the server
                document: yDoc,

                // The onSynced callback ensures initial content is set only once using editor.setContent(), preventing repetitive content loading on editor syncs.
                //onSynced() {
                //    if (!yDoc.getMap('config').get('initialContentLoaded') && editor) {
                //        yDoc.getMap('config').set('initialContentLoaded', true)
                //    }
                //},
            });

            provider.setAwarenessField('user', {
                name: data.email,
                color: color
            });

            provider.on('awarenessChange', ({ states }: any) => {
                //console.log(states);
            });

            setCollaborator({
                ...collaborator,
                email: data.email,
                color: color
            });
            setProvider(provider);
            setDoc(yDoc);
        
            //document.addEventListener('mousemove', (event) => {
            //    // Share any information you like
            //    provider.setAwarenessField('user', {
            //      name: data.email,
            //      color: color,
            //      mouseX: event.clientX,
            //      mouseY: event.clientY,
            //    })
            //  })
        }
    
        connectToProvider();
    }, [])


    if (!doc || !provider || !collaborator) {
        return null
    }

    return <TipTapEditor doc={doc} provider={provider} collaborator={collaborator} form={form} setForm={setForm} content={content} />

}

export default CollaborativeEditor;