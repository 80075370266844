import { SignupButton } from "./buttons/signup-button";

const Hero = () => (
  <div className="text-center hero my-5">
    <h1>Expert Capital</h1>

    <p className="lead">
    BrightLoop helps investors win deals, get better returns<br/>and build more successful startups.
    </p>
  </div>
);

export default Hero;