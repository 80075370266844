import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import UserNotFound from "../../../components/UserNotFound";
import { useUserContext } from "../../users";


export const WorkspaceTeam = () => {

    const { organizationTag } = useParams();
    const { userContext, setUserContext } = useUserContext();

    const [ invitations, setInvitations ] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    if (!userContext.fetched) return <Loading />
    if (userContext.fetched && !userContext.dbUser) return <UserNotFound />

    return (
        <div>
            <div></div>
            <div></div>
        </div>
    )
}
