import { useState } from "react";

const messages: { [key: string]: string } = {
    "closed_not_greenlit": "Thank you for adding your viewpoint. This deal did not result in an opportunity for experts to co-invest.",
    "closed_canceled": "Thank you for adding your viewpoint. This deal is no longer accepting co-investments.",
    "closed_funded": "Thank you for adding your viewpoint. This deal is no longer accepting co-investments."
}

export const DealStatusMessage = ({ surveyResponse }: any) => {

    const [ expanded, setExpanded ] = useState(false);

    const handleExpand = () => {
        setExpanded(!expanded);
    }

    const spvStage = surveyResponse?.survey_link?.project?.spv_stage;
    if (surveyResponse.status === "complete" && Object.keys(messages).includes(spvStage)) {
        return (
            <div className="alert-banner white deal-status-message">
                <div>{messages[spvStage]}</div>
            </div>
        )
    }
}

