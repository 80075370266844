import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import ClientProvider from "../modules/ClientProvider";

class LeadData {
    leads: any | null;
    error: {message: any} | null;

    constructor(leads: any = null, error: {message: any} | null = null) {
        this.leads = leads;
        this.error = error;
    }
}

const useGetLeadsData = (projectId: number) => {
    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState(new LeadData());

    useEffect(() => {
        
        let isMounted = true;

        const fetchProjectData = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await ClientProvider.provideProjectClient().getLeadsData(projectId, accessToken);
    
            if (!isMounted) return;
    
            if (data) setState(new LeadData(data?.leads, error));
        };
    
        fetchProjectData(); 
    
        return () => { isMounted = false; };
    }, []);

    return [state.leads, state.error];
};

export default useGetLeadsData;